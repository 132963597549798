// import React, { useState, useEffect } from 'react';
// import { Form, Button, Alert, Row, Col,Table} from 'react-bootstrap';
// import { useSearchParams,useNavigate  } from 'react-router-dom';
// import api from '../services/api'; 

// const ConnectorRegistration = () => {
//   const [connectorData, setConnectorData] = useState({
//     name: '',
//     connector_code: '',
//     connector_type: 'Entity',
//     entity_type: 'individual',
//     addl1: '',
//     addl2: '',
//     addl3: '',
//     city: '',
//     state: '',
//     pin: '',
//     base_location: '',
//     mobile_no: '',
//     email: '',
//     date_of_incorporation: '',
//     pan: '',
//     gst: '',
//     aadhar: '',
//     account_type: '',
//     partner1_type: '',
//     partner1_name: '',
//     partner1_addl1: '',
//     partner1_addl2: '',
//     partner1_addl3: '',
//     partner1_city: '',
//     partner1_state: '',
//     partner1_pin: '',
//     partner1_mobile_number: '',
//     partner1_email_id: '',
//     partner1_pan: '',
//     partner1_aadhar: '',
//     partner1_dob: '',
//     partner2_type: '',
//     partner2_name: '',
//     partner2_addl1: '',
//     partner2_addl2: '',
//     partner2_addl3: '',
//     partner2_city: '',
//     partner2_state: '',
//     partner2_pin: '',
//     partner2_mobile_number: '',
//     partner2_email_id: '',
//     partner2_pan: '',
//     partner2_aadhar: '',
//     partner2_dob: '',
//     verified_status: 'Pending',
//     created_by: '',
//     verified_by: '',
//     date_of_creation: '',
//     last_modification_date: '',
//     status: 'Entered',
//     payees: [
//       // {
//       //   payeeCode: '',
//       //   payeeName: '',
//       //   payeePan: '',
//       //   payeeIfsc: '',
//       //   payeeBank: '',
//       //   payeeBranch: '',
//       //   payeeAccountNo: '',
//       //   accountType: '',
//       //   createdBy: '',
//       //   verifiedBy: '',
//       //   status: 'Entered',
//       // },
//     ],
//     // documents: [
//     //   {
//     //     srNo: '',
//     //     documentCode: '',
//     //     documentDescription: '',
//     //     documentNumber: '',
//     //     typeOfFile: '',
//     //     fileName: '',
//     //     authority: '',
//     //     remarks: '',
//     //     storagePath: '',
//     //     createdBy: '',
//     //     verifiedBy: '',
//     //     status: '',
//     //   },
//     // ],
//   });
  
   


  
//   const [editingPayeeIndex, setEditingPayeeIndex] = useState(-1);
//   const [editingIndex, setEditingIndex] = useState(-1); 
//   const [searchParams, setSearchParams] = useSearchParams();
//   const [selectedState, setSelectedState] = useState(""); 
//   const [selectedLocations, setSelectedLocations] = useState([]); // State for locations based on selected state

//   // const [documents, setDocuments] = useState([]);
//   const [selectedLocation, setSelectedLocation] = useState('');

//   const [states, setStates] = useState([]);
//   const [locations, setLocations] = useState([]);
//   const [payees, setPayees] = useState([]);
//   // const [editingPayeeIndex, setEditingPayeeIndex] = useState(-1);
//   const [newPayee, setNewPayee] = useState({
//     payee_code: '',
//     payee_name: '',
//     payee_pan: '',
//     payee_ifsc: '',
//     payee_bank: '',
//     payee_branch: '',
//     payee_account_no: '',
//     account_type: '',
//     status: 'Entered'
//   });
//   // const [newDocument, setNewDocument] = useState({
//   //   srNo: '',
//   //   documentCode: '',
//   //   documentDescription: '',
//   //   documentNumber: '',
//   //   typeOfFile: '',
//   //   fileName: '',
//   //   authority: '',
//   //   remarks: '',
//   //   storagePath: '',
//   //   createdBy: '',
//   //   verifiedBy: '',
//   //   status: '',
//   // });

//   const stateOptions = [
//     { value: 'Delhi', label: 'Delhi' },
//     { value: 'Mumbai', label: 'Mumbai' },
//     { value: 'Punjab', label: 'Punjab' },
//     { value: 'Karnataka', label: 'Karnataka' },
//     { value: 'Kerala', label: 'Kerala' },
//     { value: 'Chhattisgarh', label: 'Chhattisgarh' },
//     { value: 'Maharashtra', label: 'Maharashtra' },
//     { value: 'Telangana', label: 'Telangana' },
//     { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
//     { value: 'Assam', label: 'Assam' },
//     { value: 'Tamil Nadu', label: 'Tamil Nadu' },
//     { value: 'Gujarat', label: 'Gujarat' },
//     { value: 'Goa', label: 'Goa' },
//     { value: 'Rajasthan', label: 'Rajasthan' },
//     { value: 'Haryana', label: 'Haryana' },
//     { value: 'Jharkhand', label: 'Jharkhand' },
//     { value: 'West Bengal', label: 'West Bengal' },
//     { value: 'Chandigarh', label: 'Chandigarh' },
//     { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
//     { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
//     { value: 'Bihar', label: 'Bihar' },
//     { value: 'Puducherry', label: 'Puducherry' },
//     { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
//     { value: 'Uttarakhand', label: 'Uttarakhand' },
//     { value: 'Orissa', label: 'Orissa' },
//     { value: 'Pondicherry', label: 'Pondicherry' },
//     { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'Dadra and Nagar Haveli and Daman and Diu' },
//     { value: 'Tamilnadu', label: 'Tamilnadu' },
//     { value: 'Uttaranchal', label: 'Uttaranchal' },
//     { value: 'Agra', label: 'Agra' },
//     { value: 'NCR', label: 'NCR' },
//     { value: 'Sikkim', label: 'Sikkim' },
//     { value: 'Odisha', label: 'Odisha' },
//     { value: 'Tripura', label: 'Tripura' },
//     { value: 'Nct Of Delhi', label: 'Nct Of Delhi' },
//     { value: 'Kolhapur', label: 'Kolhapur' },
//     { value: 'Central UP', label: 'Central UP' }
//   ];
  
//   // useEffect(() => {
//   //   const fetchConnectorData = async () => {
//   //     try {

//   //       const locationResponse = await api.get('/api/locations/');
//   //       // setConnectorData({
//   //       //   ...response.data,
//   //       //   payees: response.data.payees || []
//   //       // });
//   //       setSelectedLocations(locationResponse.data);

//   //       const response = await api.get('/api/connectors/');
//   //       // setConnectorData(prev => ({
//   //       //   ...prev,       // Retain existing state
//   //       //   ...response.data, // Update with API data
//   //       //   payees: response.data.payees || prev.payees // Ensure payees is an array
//   //       // }));
//   //       // setConnectorData(response.data);
//   //     } catch (error) {
//   //       console.error('Error fetching connector data:', error);
//   //     }
//   //   };

//   //   fetchConnectorData();
//   // }, []);

//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchStates = async () => {
//       try {
//         const response = await api.get('/api/states-by-location/');
//         setStates(response.data);
//       } catch (error) {
//         console.error('Error fetching states:', error);
//       }
//     };
//     fetchStates();
//   }, []);

//   const handleStateLocationChange = async (state) => {
//     setSelectedLocations([]);  // Reset locations on state change
  
//     if (state) {
//       try {
//         console.log("Selected state:", state);
  
//         const response = await api.get(`/api/states-by-location/${state}/`);
  

//         if (response.status === 200) {
//           console.log("Fetched Locations:", response.data);
//           setSelectedLocations(response.data);  // Update locations list
//         } else {
//           throw new Error(`Unexpected response status: ${response.status}`);
//         }
//       } catch (error) {
//         console.error("Error fetching products:", error.response?.data || error.message);
//       }
//     } else {
//       setSelectedLocations([]);  // Clear products if no state selected
//     }
//   };  


  



// // const handleStateChange = (selectedOption) => {
// //   setSearchParams({
// //     ...searchParams,
// //     state: selectedOption ? selectedOption.label : '',
// //   });
// //   handleStateLocationChange(selectedOption ? selectedOption.label : '');
// // };


// // const handleStateChange = (selectedOption) => {
// //   setSelectedState(selectedOption ? selectedOption.label : '');
// //   handleStateLocationChange(selectedOption ? selectedOption.label : '');
// //   setConnectorData((prevData) => ({
// //     ...prevData,
// //     state: selectedOption ? selectedOption.label : '',
// //   }));
// // };



// const handleStateChange = async (e) => {
//   const selectedState = e.target.value;
//   setConnectorData({ ...connectorData, state: selectedState });
  
//   try {
//     const response = await api.get(`/api/states-by-location/${selectedState}/`);
//     setLocations(response.data);
//   } catch (error) {
//     console.error('Error fetching locations:', error);
//   }
// };


// const handleInputChange = (e) => {
//   const { name, value } = e.target;
//   setConnectorData({ ...connectorData, [name]: value });
// };

// const handlePayeeChange = (e) => {
//   const { name, value } = e.target;
//   setNewPayee({ ...newPayee, [name]: value });
// };

// const handleLocationChange = (event) => {
//   setSelectedLocation(event.target.value);
//   setConnectorData((prevData) => ({
//     ...prevData,
//     base_location: event.target.value,
//   }));
// };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setConnectorData({
//       ...connectorData,
//       [name]: value
//     });
//   };

  

//   const handleSearch = () => {
//     alert("Search button clicked!");
//   };
  


//   const handleNew = () => {
//     // Reset the form for a new connector
//     setConnectorData({
//       name: '',
//       connectorCode: '',
//       connectorType: '',
//       entityType: '',
//       addl1: '',
//       addl2: '',
//       addl3: '',
//       city: '',
//       state: '',
//       pin: '',
//       base_location:'',
//       mobileNumber: '',
//       email: '',
//       dateOfIncorporation: '',
//       pan: '',
//       gst: '',
//       aadhar: '',
//       accountType: '',
//       partner1Type: '',
//       partner1Name: '',
//       partner1Addl1: '',
//       partner1Addl2: '',
//       partner1Addl3: '',
//       partner1City: '',
//       partner1State: '',
//       partner1Pin: '',
//       partner1MobileNumber: '',
//       partner1EmailId: '',
//       partner1Pan: '',
//       partner1Aadhar: '',
//       partner1Dob: '',
//       partner2Type: '',
//       partner2Name: '',
//       partner2Addl1: '',
//       partner2Addl2: '',
//       partner2Addl3: '',
//       partner2City: '',
//       partner2State: '',
//       partner2Pin: '',
//       partner2MobileNumber: '',
//       partner2EmailId: '',
//       partner2Pan: '',
//       partner2Aadhar: '',
//       partner2Dob: '',
//       verifiedStatus: '',
//       createdBy: '',
//       verifiedBy: '',
//       dateOfCreation: '',
//       lastModificationDate: '',
//       status: '',
//       payees: [
//         {
//           payeeCode: '',
//           payeeName: '',
//           payeePan: '',
//           payeeIfsc: '',
//           payeeBank: '',
//           payeeBranch: '',
//           payeeAccountNo: '',
//           createdBy: '',
//           verifiedBy: '',
//           status: '',
//         },
//       ],
//       // documents: [
//       //   {
//       //     srNo: '',
//       //     documentCode: '',
//       //     documentDescription: '',
//       //     documentNumber: '',
//       //     typeOfFile: '',
//       //     fileName: '',
//       //     authority: '',
//       //     remarks: '',
//       //     storagePath: '',
//       //     createdBy: '',
//       //     verifiedBy: '',
//       //     status: '',
//       //   },
//       // ],
//     });
//   };



//   // const handleAddPayee = () => {
//   //   const requiredFields = [
//   //     newPayee.payeeName,
//   //     newPayee.payeeAccountNo, // Added missing required field
//   //     newPayee.accountType,
//   //     newPayee.payeeIfsc,
//   //     newPayee.payeeBank,
//   //     newPayee.payeeBranch
//   //   ];

    


//   //   if (requiredFields.some(field => !field)) {
//   //     alert('Please fill all required fields (marked with *)');
//   //     return;
//   //   }

//   //   if (editingPayeeIndex > -1) {
//   //     const updatedPayees = [...connectorData.payees];
//   //     updatedPayees[editingPayeeIndex] = newPayee;
//   //     setConnectorData(prev => ({
//   //       ...prev,
//   //       payees: updatedPayees
//   //     }));
//   //     setEditingPayeeIndex(-1);
//   //   } else {
  
//   //   //     setConnectorData(prev => ({
//   //   //     ...prev,
//   //   //     payees: [...prev.payees, newPayee]
//   //   //   }));
//   //   // }

//   //   setConnectorData(prev => ({
//   //     ...prev,
//   //     payees: prev.payees ? [...prev.payees, newPayee] : [newPayee]  // Ensure payees is always an array
//   //   }));
//   // }

//   //   setNewPayee({
//   //     payeeCode: '',
//   //     payeeName: '',
//   //     payeePan: '',
//   //     payeeIfsc: '',
//   //     payeeBank: '',
//   //     payeeBranch: '',
//   //     payeeAccountNo: '',
//   //     createdBy: '',
//   //     verifiedBy: '',
//   //     status: '',
//   //   });
//   // };


//   // const handleEditPayee = (index) => {
//   //   const payeeToEdit = connectorData.payees[index];
//   //   setNewPayee(payeeToEdit);
//   //   setEditingPayeeIndex(index);
//   // };

//   // const handleDeletePayee = (index) => {
//   //   setConnectorData(prev => ({
//   //     ...prev,
//   //     payees: prev.payees.filter((_, i) => i !== index)
//   //   }));
//   // };



//   const handleAddPayee = () => {
//     if (editingPayeeIndex > -1) {
//       const updatedPayees = [...connectorData.payees];
//       updatedPayees[editingPayeeIndex] = newPayee;
//       setConnectorData({ ...connectorData, payees: updatedPayees });
//       setEditingPayeeIndex(-1);
//     } else {
//       setConnectorData({
//         ...connectorData,
//         payees: [...connectorData.payees, newPayee]
//       });
//     }
//     setNewPayee({
//       payee_name: '',
//       payee_pan: '',
//       payee_ifsc: '',
//       payee_bank: '',
//       payee_branch: '',
//       payee_account_no: '',
//       account_type: '',
//     });
//   };

//   const handleEditPayee = (index) => {
//     setNewPayee(connectorData.payees[index]);
//     setEditingPayeeIndex(index);
//   };

//   const handleDeletePayee = (index) => {
//     const updatedPayees = connectorData.payees.filter((_, i) => i !== index);
//     setConnectorData({ ...connectorData, payees: updatedPayees });
//   };









//   // const handlePayeeChange = (e, index) => {
//   //   const { name, value } = e.target;
//   //   const updatedPayees = [...connectorData.payees];
//   //   updatedPayees[index][name] = value;
//   //   setConnectorData({
//   //     ...connectorData,
//   //     payees: updatedPayees
//   //   });
//   // };



//   // const handleDocumentChange = (e) => {
//   //   const { name, value } = e.target;
//   //   setNewDocument({
//   //     ...newDocument,
//   //     [name]: value,
//   //   });
//   // };



//   // const handleInputChange = (index, event) => {
//   //   const { name, value } = event.target;

//   //   if (index !== undefined) {
//   //     const updatedPayees = [...connectorData.payees];
//   //     updatedPayees[index][name] = value;
//   //     setConnectorData({
//   //       ...connectorData,
//   //       payees: updatedPayees,
//   //     });
//   //   } else {
//   //     // Update new payee form
//   //     setNewPayee({
//   //       ...newPayee,
//   //       [name]: value,
//   //     });
//   //   }
//   // };



//   // const handleAddDocument = () => {
//   //   const newDocument = {
//   //     srNo: '',
//   //     documentCode: '',
//   //     documentDescription: '',
//   //     documentNumber: '',
//   //     typeOfFile: 'PDF',
//   //     fileName: '',
//   //     authority: '',
//   //     remarks: '',
//   //     storagePath: '',
//   //     createdBy: '',
//   //     verifiedBy: '',
//   //     status: 'Entered',
//   //   };
//   //   setConnectorData({
//   //     ...connectorData,
//   //     documents: [...connectorData.documents, newDocument],
//   //   });
//   // };


//   //   const handleFileUpload = (index, file) => {
//   //   const newDocuments = [...documents];
//   //   newDocuments[index].uploadedDoc = file;
//   //   setDocuments(newDocuments);
//   // };

//   // // View uploaded document
//   // const viewDocument = (file) => {
//   //   const fileURL = URL.createObjectURL(file);
//   //   window.open(fileURL, '_blank');
//   // };



//   // const handleDeleteDocument = (index) => {
//   //   const updatedDocuments = documents.filter((_, i) => i !== index);
//   //   setDocuments(updatedDocuments);
//   // };



//   // const handleSubmit = async (event) => {
//   //   event.preventDefault();
//   //   console.log('Base Location:', connectorData.base_location);
//   //   console.log('State:', connectorData.state);

//   //   console.log("Connector Data:", connectorData);


//   //   try {
//   //     console.log("Submitting connector data:", connectorData);
//   //     const response = await api.post("/api/connectors/", connectorData);
//   //     console.log("Response received:", response);
//   //     alert("Connector data submitted successfully");

//   //   } catch (error) {
//   //     console.error("Error occurred:", error.response ? error.response.data : error.message);
//   //     alert('Error submitting connector data' + (error.response ? error.response.data : error.message));
//   //   }
//   // };





//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await api.post('/api/connectors/', connectorData);
//       if (response.status === 201) {
//         alert('Connector created successfully!');
//         navigate('/connectors');
//       }
//     } catch (error) {
//       console.error('Error creating connector:', error);
//       alert('Error creating connector: ' + (error.response?.data || error.message));
//     }
//   };

//   const handleVerify = () => {
//      alert('Verify button clicked');

//   };

//   const handleModify = () => {
//     alert('Modify button clicked');
//     // Add your modify logic here
//   };

//   const handleDelete = () => {
//     alert('Delete button clicked');
//     // Add your delete logic here
//   };

//   const handleCancel = () => {
//     alert('Cancel button clicked');
//     // Add your cancel logic here
//   };

  
//   return (
//             <div className="Connector Registration Form">
//               <h3>Connector Registration</h3>
//             <Form onSubmit={handleSubmit}>
//             <Row className="mb-3">
//             <Col sm={6}>
//             <Form.Group>
//               <Form.Label>Connector Name</Form.Label>
//               <Form.Control
//                   as="select"
//                   name="name"
//                   value={connectorData.name}
//                   onChange={handleChange}
                  
//                 >
//                   <option value="">Select Connector Name</option>
//                   <option value="Connector1">Connector 1</option>
//                   <option value="Connector2">Connector 2</option>

//               </Form.Control>
//             </Form.Group>
//           </Col>
//           <Col sm={3} className="d-flex align-items-end">
//             <Button variant="secondary" onClick={handleSearch} className="w-100">
//               Search
//             </Button>
//           </Col>
//           <Col sm={3} className="d-flex align-items-end">
//             <Button variant="primary" onClick={handleNew} className="w-100">
//               New
//             </Button>
//           </Col>
//         </Row>
        
        
        
//         <Row className="mb-3">


//         <Col>
//             <Form.Group>
//               <Form.Label>Connector Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="connectorName"
//                 value={connectorData.connectorName}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//         <Col>
//             <Form.Group>
//               <Form.Label>Connector Code</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="connectorCode"
//                 value={connectorData.connectorCode}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//           <Form.Group>
//               <Form.Label>Connector Type</Form.Label>
//               <div className="d-flex gap-5">
//                 <Form.Check
//                   type="radio"
//                   label="Entity"
//                   name="connector_type"  // Changed from "connectorType"
//                   value="Entity"
//                   checked={connectorData.connector_type === 'Entity'}
//                   onChange={handleChange}
//                   required
//                 />
//                 <Form.Check
//                   type="radio"
//                   label="Individual"
//                   name="connector_type"  // Changed from "connectorType"
//                   value="Individual"
//                   checked={connectorData.connector_type === 'Individual'}
//                   onChange={handleChange}
//                 />
//               </div>
//             </Form.Group>
//           </Col>
  
          
//           <Col>
//           <Form.Group>
//               <Form.Label>Entity Type</Form.Label>
//               <Form.Control
//                 as="select"
//                 name="entity_type"  // Changed from "entityType"
//                 value={connectorData.entity_type}
//                 onChange={handleChange}
//                 required
//               >
//                 <option value="">Select Entity Type</option>
//                 <option value="individual">Individual</option>
//                 <option value="company">Company</option>
//               </Form.Control>
//             </Form.Group>
//           </Col>
//         </Row>

//         <Row className="mb-3">
//           <Col>
//             <Form.Group>
//               <Form.Label>Address Line 1</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="addl1"
//                 value={connectorData.addl1}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col>
//             <Form.Group>
//               <Form.Label>Address Line 2</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="addl2"
//                 value={connectorData.addl2}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col>
//             <Form.Group>
//               <Form.Label>Address Line 3</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="addl3"
//                 value={connectorData.addl3}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>

//         <Row className="mb-3">
//           <Col>
//             <Form.Group>
//               <Form.Label>City</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="city"
//                 value={connectorData.city}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
          
          
//           {/* <Col>
//       <Form.Group>
//         <Form.Label>State</Form.Label>
//         <Form.Select
//           name="State_locations"
//           value={selectedState}
//           placeholder="Select State"
//           onChange={(e) => {
//             const selectedState = e.target.value;
//             setSelectedState(selectedState);
//             handleStateLocationChange(selectedState);
//           }}
//         >
//           <option value="">Select State</option>
//           {stateOptions.map((state) => (
//             <option key={state.value} value={state.value}>
//               {state.label}
//             </option>
//           ))}
//         </Form.Select>
//       </Form.Group>
//           </Col> */}





          
                  
         
//           <Col>
//             <Form.Group>
//             <Form.Label>Base Location</Form.Label>
//             <Form.Select name="Location" disabled={!selectedState}>
//               <option value="">Select Location</option>
//               {selectedLocations.map((location) => (
//                 <option key={location.id} value={location.id}>
//                   {location.name}
//                 </option>
//               ))}
//             </Form.Select>
//         </Form.Group>
//           </Col>
//         </Row>

//         <Row className="mb-3">
//           <Col>
//           <Form.Group>
//               <Form.Label>Pin</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="pin"
//                 value={connectorData.pin}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Mobile Number</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="mobile_no"  // Match serializer field name
//                 value={connectorData.mobile_no}
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Email</Form.Label>
//               <Form.Control
//                 type="email"
//                 name="email"
//                 value={connectorData.email}
//                 onChange={handleChange}
//                 required
//               />
//             </Form.Group>
//           </Col>
//         </Row>

//         < Row className="mb-3">
//           <Col>
//             <Form.Group>
//               <Form.Label>Date of Incorporation</Form.Label>
//               <Form.Control
//                 type="date"
//                 name="dateOfIncorporation"
//                 value={connectorData.dateOfIncorporation}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>PAN</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="pan"
//                 value={connectorData.pan}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>GST</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="gst"
//                 value={connectorData.gst}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>

//         < Row className="mb-3">
//           <Col>
//             <Form.Group>
//               <Form.Label>Aadhar</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="aadhar"
//                 value={connectorData.aadhar}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Account Type</Form.Label>
//               <Form.Control
//                 as="select"
//                 name="account_type"  // Was "accountType"
//                 value={connectorData.account_type}
//                 onChange={handleChange}
//               >
//                 <option value="">Select Account Type</option>
//                 <option value="Saving">Saving</option>
//                 <option value="Current">Current</option>
//               </Form.Control>
//             </Form.Group>
//           </Col>
//         </Row>

//         {/* Partner 1 Details */}
//         <h5>Partner 1 Details</h5>
//         <Row className="mb-3">
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 1 Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner1Name"
//                 value={connectorData.partner1Name}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 1 Type</Form.Label>
//               <Form.Control
//                 as="select"
//                 name="partner1Type"
//                 value={connectorData.partner1Type}
//                 onChange={handleChange}
//               >
//                 <option value="">Select</option>
//                 <option value="Individual">Individual</option>
//                 <option value="Company">Company</option>
//               </Form.Control>
//             </Form.Group>
//           </Col>
//         </Row>

//         <Row Row className="mb-3">
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 1 Address Line 1</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner1Addl1"
//                 value={connectorData.partner1Addl1}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 1 Address Line 2</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner1Addl2"
//                 value={connectorData.partner1Addl2}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 3 Address Line 3</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner1Addl3"
//                 value={connectorData.partner1Addl3}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>

//         <Row className="mb-3">
//         <Col>
//             <Form.Group>
//               <Form.Label>Partner 1 City</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner1City"
//                 value={connectorData.partner1City}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 1 State</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner1State"
//                 value={connectorData.partner1State}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
      
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 1 Pin</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner1Pin"
//                 value={connectorData.partner1Pin}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//       </Row>
      
//       <Row className="mb-3">
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 1 Mobile Number</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner1MobileNumber"
//                 value={connectorData.partner1MobileNumber}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 1 Email</Form.Label>
//               <Form.Control
//                 type="email"
//                 name="partner1EmailId"
//                 value={connectorData.partner1EmailId}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 1 PAN</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner1Pan"
//                 value={connectorData.partner1Pan}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//       </Row>

          
          
//       <Row className="mb-3">          
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 1 Aadhar</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner1Aadhar"
//                 value={connectorData.partner1Aadhar}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 1 Date of Birth</Form.Label>
//               <Form.Control
//                 type="date"
//                 name="partner1Dob"
//                 value={connectorData.partner1Dob}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>

    
//     <h5>Partner 2 Details</h5>
//     < Row className="mb-3">
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 2 Type</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner2Type"
//                 value={connectorData.partner2Type}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 2 Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner2Name"
//                 value={connectorData.partner2Name}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
        

        
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 2 Address Line 1</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner2Addl1"
//                 value={connectorData.partner2Addl1}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>

//         < Row className="mb-3">
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 2 Address Line 2</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner2Addl2"
//                 value={connectorData.partner2Addl2}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
        
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 2 Address Line 3</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner2Addl3"
//                 value={connectorData.partner2Addl3}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 2 City</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner2City"
//                 value={connectorData.partner2City}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>

//         < Row className="mb-3">
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 2 State</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner2State"
//                 value={connectorData.partner2State}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
      
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 2 Pin</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner2Pin"
//                 value={connectorData.partner2Pin}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 2 Mobile Number</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner2MobileNumber"
//                 value={connectorData.partner2MobileNumber}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>

//         < Row className="mb-3">
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 2 Email ID</Form.Label>
//               <Form.Control
//                 type="email"
//                 name="partner2EmailId"
//                 value={connectorData.partner2EmailId}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 2 PAN</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner2Pan"
//                 value={connectorData.partner2Pan}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 2 Aadhar</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="partner2Aadhar"
//                 value={connectorData.partner2Aadhar}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>

//         < Row className="mb-3">
//           <Col>
//             <Form.Group>
//               <Form.Label>Partner 2 Date of Birth</Form.Label>
//               <Form.Control
//                 type="date"
//                 name="partner2Dob"
//                 value={connectorData.partner2Dob}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Verified Status</Form.Label>
//               {/* <Form.Control
//                 type="text"
//                 name="verifiedStatus"
//                 value={connectorData.verifiedStatus}
//                 onChange={handleChange}
//               /> */}

//                 <Form.Control
//                   name="verified_status"
//                   value={connectorData.verified_status}
//                   as="select"
//                 >
//                   <option value="Pending">Pending</option>
//                   <option value="Verified">Verified</option>
//                 </Form.Control>
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Created By</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="createdBy"
//                 value={connectorData.createdBy}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>
//         </Row>

//         < Row className="mb-3">
//           <Col>
//             <Form.Group>
//               <Form.Label>Verified By</Form.Label>
//               <Form.Control
//                 type="text"
//                 name="verifiedBy"
//                 value={connectorData.verifiedBy}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Date of Creation</Form.Label>
//               <Form.Control
//                 type="date"
//                 name="dateOfCreation"
//                 value={connectorData.dateOfCreation}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//             <Form.Group>
//               <Form.Label>Last Modification Date</Form.Label>
//               <Form.Control
//                 type="date"
//                 name="lastModificationDate"
//                 value={connectorData.lastModificationDate}
//                 onChange={handleChange}
//               />
//             </Form.Group>
//           </Col>

//           <Col>
//           <Form.Group>
//             <Form.Label>Status</Form.Label>
//             <Form.Control
//               as="select"
//               name="status"
//               value={connectorData.status}
//               onChange={handleChange}
//             >
//               <option value="Entered">Entered</option>
//               <option value="Modified">Modified</option>
//               <option value="Verified">Verified</option>
//               <option value="Deleted">Deleted</option>
//             </Form.Control>
//           </Form.Group>

//           </Col>
//     </Row>

//       <h3>Payee Bank Account Details</h3>
//       <Row className="mb-3">
//         <Col>
//           <Form.Group>
//             <Form.Label>Payee Name*</Form.Label>
//             <Form.Control
//               type="text"
//               name="payee_name"
//               value={newPayee.payee_name}
//               onChange={handleInputChange}
//             />
//           </Form.Group>
//         </Col>
//         <Col>
//           <Form.Group>
//             <Form.Label>Type of A/c</Form.Label>
//             <Form.Control
//               as="select"
//               name="account_type"
//               value={newPayee.account_type}
//               onChange={handleInputChange}
//             >
//               <option value="">Select Account Type</option>
//               <option value="Saving">Saving</option>
//               <option value="Current">Current</option>
//             </Form.Control>
//           </Form.Group>
//         </Col>
//       </Row>

//       <Row className="mb-3">
//         <Col>
//           <Form.Group>
//             <Form.Label>IFSC Code</Form.Label>
//             <Form.Control
//               type="text"
//               name="payee_ifsc"
//               value={newPayee.payee_ifsc}
//               onChange={handleInputChange}
//             />
//           </Form.Group>
//         </Col>
//         <Col>
//           <Form.Group>
//             <Form.Label>Bank Name</Form.Label>
//             <Form.Control
//               type="text"
//               name="payee_bank"
//               value={newPayee.payee_bank}
//               onChange={handleInputChange}
//             />
//           </Form.Group>
//         </Col>
//         <Col>
//           <Form.Group>
//             <Form.Label>Branch Name</Form.Label>
//             <Form.Control
//               type="text"
//               name="payee_branch"
//               value={newPayee.payee_branch}
//               onChange={handleInputChange}
//             />
//           </Form.Group>
//         </Col>
//       </Row>

//       <Row className="mb-3">
//         <Col>
//           <Form.Group>
//             <Form.Label>Account No</Form.Label>
//             <Form.Control
//               type="text"
//               name="payee_account_no"
//               value={newPayee.payee_account_no}
//               onChange={handleInputChange}
//             />
//           </Form.Group>
//         </Col>
//         <Col>
//           <Form.Group>
//             <Form.Label>Payee PAN No</Form.Label>
//             <Form.Control
//               type="text"
//               name="payee_pan"
//               value={newPayee.payee_pan}
//               onChange={handleInputChange}
//             />
//           </Form.Group>
//         </Col>
//         <Col md={3} className="d-flex align-items-end">
//           <Button variant="primary" onClick={handleAddPayee}>
//             {editingIndex > -1 ? "Update Payee" : "Add Payee"}
//           </Button>
//         </Col>
//       </Row>

//       <h5>Payee Details</h5>
//       {payees.length > 0 ? (
//         <Table striped bordered hover>
//           <thead>
//             <tr>
//               <th>Payee Name</th>
//               <th>Payee PAN</th>
//               <th>IFSC Code</th>
//               <th>Bank Name</th>
//               <th>Branch Name</th>
//               <th>Account No</th>
//               <th>Account Type</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {payees.map((payee, index) => (
//               <tr key={index}>
//                 <td>{payee.payee_name}</td>
//                 <td>{payee.payee_pan}</td>
//                 <td>{payee.payee_ifsc}</td>
//                 <td>{payee.payee_bank}</td>
//                 <td>{payee.payee_branch}</td>
//                 <td>{payee.payee_account_no}</td>
//                 <td>{payee.account_type}</td>
//                 <td>
//                 <Button
//                           variant="warning"
//                           size="sm"
//                           onClick={() => handleEditPayee(index)}
//                           className="me-2"
//                         >
//                           Edit
//                         </Button>
//                         <Button
//                           variant="danger"
//                           size="sm"
//                           onClick={() => handleDeletePayee(index)}
//                         >
//                           Delete
//                         </Button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//         ) : (
//           <p>No payees added yet.</p>
//         )}

// <h3>Add Documents</h3>
//       {/* Table to display the form */}
//       {/*  */}
        
//         <div className="button-group">
//         <Button type="submit" variant="primary" className="me-2"> Save Details</Button>
//         <Button variant="warning" onClick={handleModify} className="me-2">
//             Modify
//         </Button>

//         <Button variant="primary" onClick={handleVerify} className="me-2">
//             Verify
//         </Button>

//         <Button variant="danger" onClick={handleDelete} className="me-2">
//             Delete
//         </Button>

//         <Button variant="secondary" onClick={handleCancel} className="me-2">
//             Cancel
//         </Button>
//         </div>


 
//       </Form>
//     </div>
//   );
// };

// export default ConnectorRegistration;






import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Row, Col, Table, Card,ListGroup,Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';

const ConnectorRegistration = () => {
  const initialState = {
    name: '',
    // code: '',
    connector_type: 'Entity',
    entity_type: 'individual',
    addl1: '',
    addl2: '',
    addl3: '',
    city: '',
    state: '',
    pin: '',
    base_location: '',
    mobile_no: '',
    email: '',
    // date_of_incorporation: '',
    pan: '',
    gst: '',
    aadhar: '',
    partner1_type: '',
    partner1_name: '',
    partner1_addl1: '',
    partner1_addl2: '',
    partner1_addl3: '',
    partner1_city: '',
    partner1_state: '',
    partner1_pin: '',
    partner1_mobile_number: '',
    partner1_email_id: '',
    partner1_pan: '',
    partner1_aadhar: '',
    partner1_dob: '',
    partner2_type: '',
    partner2_name: '',
    partner2_addl1: '',
    partner2_addl2: '',
    partner2_addl3: '',
    partner2_city: '',
    partner2_state: '',
    partner2_pin: '',
    partner2_mobile_number: '',
    partner2_email_id: '',
    partner2_pan: '',
    partner2_aadhar: '',
    partner2_dob: '',
    verified_status: 'Pending',
    created_by: 'admin',
    verified_by: '',
    status: 'Entered',
    payees: [],
    documents: []
  };




  const stateOptions = [
    // States
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },

    // Union Territories
    { value: "Andaman and Nicobar Islands", label: "Andaman and Nicobar Islands" },
    { value: "Chandigarh", label: "Chandigarh" },
    { value: "Dadra and Nagar Haveli and Daman and Diu", label: "Dadra and Nagar Haveli and Daman and Diu" },
    { value: "Lakshadweep", label: "Lakshadweep" },
    { value: "Delhi", label: "Delhi" },
    { value: "Puducherry", label: "Puducherry" },
    { value: "Jammu and Kashmir", label: "Jammu and Kashmir" },
    { value: "Ladakh", label: "Ladakh" }
];



  const [formData, setFormData] = useState(initialState);
  const [states, setStates] = useState([]);
  const [locations, setLocations] = useState([]);
  const [payees, setPayees] = useState([]);
  const [editingPayeeIndex, setEditingPayeeIndex] = useState(-1);
  const [newPayee, setNewPayee] = useState({
    payee_code:'',
    payee_name: '',
    payee_pan: '',
    payee_ifsc: '',
    payee_bank: '',
    payee_branch: '',
    payee_account_no: '',
    account_type: 'Saving',
    status:'Entered',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [disableSearch, setDisableSearch] = useState(false);
  const [gstEnabled, setGstEnabled] = useState(false);
  const [documents, setDocuments] = useState([
    { sr_no: 1, document_description: 'Entity PAN Card', document_number: '', remarks: '', file: null },
    { sr_no: 2, document_description: 'PAN Card', document_number: '', remarks: '', file: null },
    { sr_no: 3, document_description: 'Aadhar Card', document_number: '', remarks: '', file: null },
    { sr_no: 4, document_description: 'GSTN Registration Cert.', document_number: '', remarks: '', file: null },
    { sr_no: 5, document_description: 'Agreement', document_number: '', remarks: '', file: null },
    { sr_no: 6, document_description: 'Blank Cheque', document_number: '', remarks: '', file: null },
  ]);




  const navigate = useNavigate();

  const handleSearch = async () => {
    if (!searchTerm.trim() || disableSearch) {
      setError('Please enter a search term');
      return;
    }

    try {
      setLoading(true);
      const response = await api.get(`/api/connectors/search/?name=${encodeURIComponent(searchTerm.trim())}`);
      setSearchResults(response.data);
      setError('');
    } catch (err) {
      setError('Failed to search connectors');
      console.error('Search error:', err);
    } finally {
      setLoading(false);
    }
  };


  const loadConnectorData = async (connectorId) => {
    try {
      // Fetch connector
      const connectorResponse = await api.get(`/api/connectors/${connectorId}/`);
      
      // Fetch payees separately
      const payeesResponse = await api.get(`/api/connectors/${connectorId}/payees/`);
  
      setFormData({
        ...connectorResponse.data,
        payees: payeesResponse.data,
      });
      
    } catch (error) {
      console.error('Error loading data:', error);
      alert('Error loading connector data');
    }
  };

  // Handle key press for search input
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !disableSearch) {
      handleSearch();
    }
  };


  // const handleNew = () => {
  //   setFormData(initialState);
  //   setSearchTerm('');
  // };



  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await api.get('/api/states-by-location/');
        setStates(response.data);
      } catch (error) {
        console.error('Error fetching states:', error);
      }
    };
    fetchStates();
  }, []);
  
  // Fetch locations when editing existing data
  useEffect(() => {
    if (formData.state) {
      fetchLocations(formData.state);
    }
  }, [formData.state]); 
  
  const fetchLocations = async (selectedState) => {
    try {
      const response = await api.get(`/api/states-by-location/${selectedState}/`);
      setLocations(response.data);
  
      // Ensure base_location exists in new locations list
      const locationExists = response.data.some(
        (location) => location.name === formData.base_location
      );
  
      if (!locationExists) {
        console.warn("Base location not found in updated locations!");
      }
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };
  
  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    setFormData({ ...formData, state: selectedState, base_location: "" }); 
    fetchLocations(selectedState);
  };
  


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
    
  };

  // const handlePartnerChange = (partnerNumber, e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [`partner${partnerNumber}_${name}`]: value
  //   });
  // };


  const handlePartnerChange = (partnerNumber, e) => {
    const { name, value } = e.target;
    const fieldName = `partner${partnerNumber}_${name}`;
    setFormData(prev => ({ ...prev, [fieldName]: value }));
    validatePartnerField(partnerNumber, name, value);
  };

  const handlePayeeChange = (e) => {
    const { name, value } = e.target;
    setNewPayee({ ...newPayee, [name]: value });

    setErrors({ ...errors, [name]: '' });
    console.log(value);

  };

  const handleFileUpload = (index, e) => {
    const file = e.target.files[0];
    const updatedDocuments = [...documents];
    updatedDocuments[index].file = file;
    setDocuments(updatedDocuments);
  };


    // Handle document input changes
  const handleDocumentChange = (index, e) => {
      const { name, value } = e.target;
      const updatedDocuments = [...documents];
      updatedDocuments[index][name] = value;
      setDocuments(updatedDocuments);
    };



  const validatePayee = () => {
    const trimmedPayee = {
      // payee_code: newPayee.payee_code.trim(),
      payee_name: newPayee.payee_name.trim(),
      payee_account_no: newPayee.payee_account_no.trim(),
      payee_ifsc: newPayee.payee_ifsc.trim(),
      payee_bank: newPayee.payee_bank.trim(),
      payee_branch: newPayee.payee_branch.trim(),
      payee_pan: newPayee.payee_pan.trim(),
      account_type: newPayee.account_type.trim(),
    };

    const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    const accountNoPattern = /^\d+$/;
    const newErrors = {};

    // if (!trimmedPayee.payee_code) {
    //   newErrors.payee_code = 'Payee Code is required';
    // }

    if (!trimmedPayee.payee_name) {
      newErrors.payee_name = 'Payee Name is required';
    }

    if (!trimmedPayee.payee_account_no) {
      newErrors.payee_account_no = 'Account Number is required';
    } else if (!accountNoPattern.test(trimmedPayee.payee_account_no)) {
      newErrors.payee_account_no = 'Account Number must contain only numbers';
    } else if (trimmedPayee.payee_account_no.length < 9 || trimmedPayee.payee_account_no.length > 18) {
      newErrors.payee_account_no = 'Account Number must be between 9-18 digits';
    }

    if (!trimmedPayee.payee_ifsc) {
      newErrors.payee_ifsc = 'IFSC Code is required';
    } else if (!ifscPattern.test(trimmedPayee.payee_ifsc)) {
      newErrors.payee_ifsc = 'Invalid IFSC Code format. Valid format: ABCD0123456';
    }

    if (!trimmedPayee.payee_bank) {
      newErrors.payee_bank = 'Bank Name is required';
    }

    if (!trimmedPayee.payee_branch) {
      newErrors.payee_branch = 'Branch Name is required';
    }

    if (!trimmedPayee.payee_pan) {
      newErrors.payee_pan = 'PAN Number is required';
    } else if (!panPattern.test(trimmedPayee.payee_pan)) {
      newErrors.payee_pan = 'Invalid PAN format. Valid format: ABCDE1234F';
    }

    if (!trimmedPayee.account_type) {
      newErrors.account_type = 'Account Type is required';
    }
    const duplicatePan = formData.payees.some((payee, index) => 
      payee.payee_pan === trimmedPayee.payee_pan && index !== editingPayeeIndex
    );
    const duplicateIfsc = formData.payees.some((payee, index) => 
      payee.payee_ifsc === trimmedPayee.payee_ifsc && index !== editingPayeeIndex
    );
    const duplicateAccountNo = formData.payees.some((payee, index) => 
      payee.payee_account_no === trimmedPayee.payee_account_no && index !== editingPayeeIndex
    );
  
    if (duplicatePan) {
      newErrors.payee_pan = 'Duplicate PAN Number found';
    }
  
    if (duplicateIfsc) {
      newErrors.payee_ifsc = 'Duplicate IFSC Code found';
    }
  
    if (duplicateAccountNo) {
      newErrors.payee_account_no = 'Duplicate Account Number found';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };




  // const handlePayeeChange = (index, e) => {
  //   const { name, value } = e.target;
  //   const updatedPayees = [...formData.payees];
  //   updatedPayees[index] = { ...updatedPayees[index], [name]: value };
  //   setFormData({ ...formData, payees: updatedPayees });
  // };



  // const validatePayee = () => {
  //   const requiredFields = [
  //     'payee_name',
  //     'payee_account_no',
  //     'payee_ifsc',
  //     'payee_bank',
  //     'payee_branch',
  //     'payee_pan',
  //     'status'
  //   ];

  //   const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  //   if (!panPattern.test(newPayee.payee_pan)) {
  //     alert('Invalid PAN format. Please enter a valid PAN (e.g., ABCDE1234F).');
  //     return false;
  //   }
  
  //   return requiredFields.every(field => newPayee[field]);
  // };

  const handleAddPayee = () => {
    console.log("Handle Add Payee Triggered");
    console.log("Payee Data Before Adding:", newPayee);
    if (!validatePayee()) {
      alert('Please fill all required payee fields');
      return;
    }

    const updatedPayees = [...formData.payees];

    if (editingPayeeIndex > -1) {
      updatedPayees[editingPayeeIndex] = newPayee;
    } else {
      updatedPayees.push(newPayee);
    }

    setFormData({ ...formData, payees: updatedPayees });
    setNewPayee({
      payee_code: '',
      payee_name: '',
      payee_pan: '',
      payee_ifsc: '',
      payee_bank: '',
      payee_branch: '',
      payee_account_no: '',
      account_type: 'Saving',
      status:'Entered',
    });
    setEditingPayeeIndex(-1);
  };

  const handleEditPayee = (index) => {
    setNewPayee(formData.payees[index]);
    setEditingPayeeIndex(index);
  };

  const handleDeletePayee = (index) => {
    const updatedPayees = formData.payees.filter((_, i) => i !== index);
    setFormData({ ...formData, payees: updatedPayees });
  };




  const existingPANs = new Set(); 
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidMobile = (mobile) => /^\d{10}$/.test(mobile);
  const isValidPAN = (pan) => /^[A-Z0-9]{10}$/.test(pan);
  const isValidAadhar = (aadhar) => /^\d{12}$/.test(aadhar);
  const isValidPIN = (pin) => /^\d{6}$/.test(pin);
  const isValidGST = (gst) => /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1}$/.test(gst);


  // New validation function for individual fields
  const validateField = (name, value) => {
    let error = '';
    

    const requiredFields = [
      'name', 'connector_type', 'entity_type', 'city', 'state', 'pin',
      'mobile_no', 'email', 'pan', 'gst','base_location'
    ];

    if (requiredFields.includes(name) && !value) {
      error = 'This field is required';
    } else {
      switch(name) {
        case 'email':
          if (!isValidEmail(value)) error = 'Invalid email format';
          break;
        case 'mobile_no':
          if (!isValidMobile(value)) error = 'Invalid mobile number (10 digits required)';
          break;
        case 'pan':
          if (!isValidPAN(value)) error = 'Invalid PAN format';
          if (isDuplicateValue('pan', value)) error = 'Duplicate PAN';
          break;
        case 'aadhar':
          if (!isValidAadhar(value)) error = 'Invalid Aadhar number (12 digits required)';
          if (isDuplicateValue('aadhar', value)) error = 'Duplicate Aadhar number';
          break;
        case 'pin':
          if (!isValidPIN(value)) error = 'Invalid PIN code (6 digits required)';
          break;
        case 'gst':
          if (!isValidGST(value)) error = 'Invalid GST format';
          break;
      }
    }
    
    setErrors(prev => ({ ...prev, [name]: error }));
  };


  // Partner validation
  const validatePartnerField = (partnerNumber, field, value) => {
    const partnerFields = ['name', 'type', 'mobile_number', 'email_id', 'pan'];
    const fieldName = `partner${partnerNumber}_${field}`;
    let error = '';

    if (formData.connector_type === 'Individual') {
      setErrors(prev => ({ ...prev, [fieldName]: '' }));
      return;
    }

    if (partnerFields.includes(field) && !value) {
      error = 'This field is required';
    } else {
      switch(field) {
        case 'mobile_number':
          if (!isValidMobile(value)) error = 'Invalid mobile number';
          break;
        case 'email_id':
          if (!isValidEmail(value)) error = 'Invalid email format';
          break;
        case 'pan':
          if (!isValidPAN(value)) error = 'Invalid PAN format';
          if (isDuplicateValue('pan', value, partnerNumber)) error = 'Duplicate PAN';
          break;
        case 'aadhar':
          if (!isValidAadhar(value)) error = 'Invalid Aadhar number';
          if (isDuplicateValue('aadhar', value, partnerNumber)) error = 'Duplicate Aadhar number';
          break;
      }
    }

    setErrors(prev => ({ ...prev, [fieldName]: error }));

  };


  const isDuplicateValue = (field, value, partnerNumber = null) => {
    const values = [];
  
    // Add main form field values
    if (field === 'pan' && formData.pan) values.push(formData.pan);
    if (field === 'aadhar' && formData.aadhar) values.push(formData.aadhar);
  
    // Add partner field values
    if (partnerNumber !== 1) {
      if (field === 'pan' && formData.partner1_pan) values.push(formData.partner1_pan);
      if (field === 'aadhar' && formData.partner1_aadhar) values.push(formData.partner1_aadhar);
    }
    if (partnerNumber !== 2) {
      if (field === 'pan' && formData.partner2_pan) values.push(formData.partner2_pan);
      if (field === 'aadhar' && formData.partner2_aadhar) values.push(formData.partner2_aadhar);
    }
  
    return values.filter(v => v === value).length > 1;
  };

  


  const handleVerify = async () => {
    if (!formData.id) {
      alert('Please select a connector to verify');
      return;
    }

    if (!window.confirm('Are you sure you want to verify this connector?')) return;

    try {
      const response = await api.patch(`/api/connectors/${formData.id}/verify/`);
      
      if (response.status === 200) {
        alert('Connector verified successfully!');
        setFormData(prev => ({
          ...prev,
          verified_status: 'Verified',
          verified_by: 'admin',
          status: 'Verified'
        }));
      }
    } catch (error) {
      console.error('Verification error:', error);
      alert('Error: ' + (error.response?.data?.message || error.message));
    }
};



const handleDelete = async () => {
  if (!window.confirm('This action cannot be undone. Confirm deletion?')) return;

  try {
    const response = await api.patch(`/api/connectors/${formData.id}/`, {
      status: 'Deleted'
    });

    if (response.status === 200) {
      alert('Connector status updated to Deleted successfully!');
      setFormData(prev => ({
        ...prev,
        status: 'Deleted'
      }));
    }
  } catch (error) {
    console.error('Deletion error:', error);
    alert('Error: ' + (error.response?.data?.message || error.message));
  }
};



  

  const handleSubmit = async (e) => {
    e.preventDefault();



    // const formErrors = validateForm();
    
    // if (Object.keys(formErrors).length > 0) {
    //   setErrors(formErrors);
    //   return;
    // }
  
  
  console.log("Form Data Payees: ", formData.payees);  // Check the payees in formData
  console.log("Payees State: ", payees);   
    

    // try {
    //   const response = await api.post('/api/connectors/', formData);
    //   if (response.status === 201) {
    //     console.log('Connector created successfully:', response.data);
    //     alert('Connector created successfully!');
    //     navigate('/connectors');
    //   }
    // } catch (error) {
    //   console.error('Error creating connector:', error);
    //   alert('Error: ' + (error.response?.data || error.message));
    // }

  //   try {

  //     // Log the formData before sending to verify it
  //     console.log('FormData before request:', formData);
    
  //     // Check if formData is valid and not empty
  //     if (!formData || Object.keys(formData).length === 0) {
  //       console.error('Form data is empty or invalid!');
  //       return;  // Exit if formData is empty or invalid
  //     }
    
  //     // Make the POST request
  //     console.log('Sending request to /api/connectors/...');
  //     const response = await api.post('/api/connectors/', formData);
      
  //     console.log('formData',formData);
    
  //     // Log the response status and data if the request is successful
  //     if (response.status === 201) {
  //       console.log('Request sent successfully:', response.data);
  //       alert('Connector created successfully!');
  //       navigate('/connectors');
  //     } else {
  //       console.log('Request sent but response was not successful:', response.status);
  //     }
  //   } catch (error) {
  //     // Log detailed error information to understand what went wrong
  //     console.error('Error creating connector:', error);
  //     console.error('Error response:', error.response);  // Log response if available
  //     alert('Error: ' + (error.response?.data || error.message));
  //   }
  // };







  
    // ... (keep existing validations)



    try {
      

          // Create payload with current payees
      const payload = {
        ...formData,
        payees: formData.payees
      };

      if (formData.connector_type === 'Individual') {
        delete payload.partner1_type;
        delete payload.partner1_name;
        delete payload.partner1_addl1;
        delete payload.partner1_addl2;
        delete payload.partner1_addl3;
        delete payload.partner1_city;
        delete payload.partner1_state;
        delete payload.partner1_pin;
        delete payload.partner1_mobile_number;
        delete payload.partner1_email_id;
        delete payload.partner1_pan;
        delete payload.partner1_aadhar;
        delete payload.partner1_dob;
        delete payload.partner2_type;
        delete payload.partner2_name;
        delete payload.partner2_addl1;
        delete payload.partner2_addl2;
        delete payload.partner2_addl3;
        delete payload.partner2_city;
        delete payload.partner2_state;
        delete payload.partner2_pin;
        delete payload.partner2_mobile_number;
        delete payload.partner2_email_id;
        delete payload.partner2_pan;
        delete payload.partner2_aadhar;
        delete payload.partner2_dob;
      }
      

      
      Object.keys(payload).forEach(key => {
        if (payload[key] === '' || payload[key] === null) {
          delete payload[key];  // Remove empty fields
        }
      });
      
      let response;
      if (formData.id) {
        // Update existing connector
        response = await api.put(`/api/connectors/${formData.id}/`,payload);
      } else {
        // Create new connector
        response = await api.post('/api/connectors/',payload);
      }

      if (response.status === 200 || response.status === 201) {
          console.log('Request sent successfully:', response.data);
        alert(`Connector ${formData.id ? 'updated' : 'created'} successfully!`);
        navigate('/connectors');
      }
    } catch (error) {
      // Log detailed error information to understand what went wrong
      console.error('Error creating connector:', error);
      console.error('Error response:', error.response);  // Log response if available
      alert('Error: ' + (error.response?.data || error.message));
    }
  };




  // const validatePayee = () => {


  //   const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  //   if (!panPattern.test(newPayee.payee_pan)) {
  //     alert('Invalid PAN format. Please enter a valid PAN (e.g., ABCDE1234F).');
  //     return false;
  //   }
  //   // Add ID check for modifications
  //   // if (editingPayeeIndex > -1 && !newPayee.id) {
  //   //   alert('Invalid payee data for modification');
  //   //   return false;
  //   // }
  //   return true;
  // };




  // const validatePayee = () => {
  //   let isValid = true;
  //   let newErrors = {};
  
  //   const requiredFields = [
  //     'payee_code', 'payee_name', 'payee_account_no', 'payee_ifsc', 'payee_bank', 'payee_branch', 'payee_pan'
  //   ];
  
  //   requiredFields.forEach(field => {
  //     if (!newPayee[field]?.trim()) {
  //       newErrors[field] = 'This field is required';
  //       isValid = false;
  //     }
  //   });
  
  //   if (newPayee.payee_ifsc && !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(newPayee.payee_ifsc)) {
  //     newErrors.payee_ifsc = 'Invalid IFSC code';
  //     isValid = false;
  //   }
  
  //   if (newPayee.payee_pan && !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(newPayee.payee_pan)) {
  //     newErrors.payee_pan = 'Invalid PAN format';
  //     isValid = false;
  //   }
  
  //   if (newPayee.payee_account_no && !/^[0-9]{9,18}$/.test(newPayee.payee_account_no)) {
  //     newErrors.payee_account_no = 'Invalid account number';
  //     isValid = false;
  //   }
  
  //   console.log("Validation Errors:", newErrors);
  //   setErrors(newErrors); // Update errors state
  //   return isValid;
  // };




  

  const renderPartnerSection = (partnerNumber) => (
    <Card className="mb-4">
      <Card.Header>Partner {partnerNumber} Details/Director Details</Card.Header>
      <Card.Body>
        <Row className="mb-3">
          <Col md={4}>
            <Form.Group>
              <Form.Label>Name*</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData[`partner${partnerNumber}_name`]}
                onChange={(e) => handlePartnerChange(partnerNumber, e)}
                isInvalid={!!errors[`partner${partnerNumber}_name`]}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors[`partner${partnerNumber}_name`]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Type*</Form.Label>
              <Form.Control
                as="select"
                name="type"
                value={formData[`partner${partnerNumber}_type`]}
                onChange={(e) => handlePartnerChange(partnerNumber, e)}
                required
              >
                <option value="">Select Type</option>
                <option value="Individual">Individual</option>
                <option value="Company">Company</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group>
              <Form.Label>Mobile Number*</Form.Label>
              <Form.Control
                type="text"
                name="mobile_number"
                value={formData[`partner${partnerNumber}_mobile_number`]}
                onChange={(e) => handlePartnerChange(partnerNumber, e)}
                isInvalid={!!errors[`partner${partnerNumber}_mobile_number`]}
                required
              />
              <Form.Control.Feedback type="invalid">
                {errors[`partner${partnerNumber}_mobile_number`]}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        {/* Add more partner fields as needed */}
        <Row className="mb-3">
        <Col md={4}>
          <Form.Group>
            <Form.Label>Address Line 1*</Form.Label>
            <Form.Control
              type="text"
              name="addl1"
              value={formData[`partner${partnerNumber}_addl1`]}
              onChange={(e) => handlePartnerChange(partnerNumber, e)}
              isInvalid={!!errors[`partner${partnerNumber}_addl1`]}
              required
            />
            <Form.Control.Feedback type="invalid">
                {errors[`partner${partnerNumber}_addl1`]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control
              type="text"
              name="addl2"
              value={formData[`partner${partnerNumber}_addl2`]}
              onChange={(e) => handlePartnerChange(partnerNumber, e)}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>Address Line 3</Form.Label>
            <Form.Control
              type="text"
              name="addl3"
              value={formData[`partner${partnerNumber}_addl3`]}
              onChange={(e) => handlePartnerChange(partnerNumber, e)}
            />
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}>
          <Form.Group>
            <Form.Label>City*</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={formData[`partner${partnerNumber}_city`]}
              onChange={(e) => handlePartnerChange(partnerNumber, e)}
              isInvalid={!!errors[`partner${partnerNumber}_city`]}
              required
              
            />
            <Form.Control.Feedback type="invalid">
                {errors[`partner${partnerNumber}_city`]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>State*</Form.Label>
            <Form.Control
              type="text"
              name="state"
              value={formData[`partner${partnerNumber}_state`]}
              onChange={(e) => handlePartnerChange(partnerNumber, e)}
              isInvalid={!!errors[`partner${partnerNumber}_state`]}
              required
            />
            <Form.Control.Feedback type="invalid">
                {errors[`partner${partnerNumber}_state`]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>PIN Code*</Form.Label>
            <Form.Control
              type="text"
              name="pin"
              value={formData[`partner${partnerNumber}_pin`]}
              onChange={(e) => handlePartnerChange(partnerNumber, e)}
              isInvalid={!!errors[`partner${partnerNumber}_pin`]}
              required
            />
            <Form.Control.Feedback type="invalid">
                {errors[`partner${partnerNumber}_pin`]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}>
          <Form.Group>
            <Form.Label>Email ID*</Form.Label>
            <Form.Control
              type="email"
              name="email_id"
              value={formData[`partner${partnerNumber}_email_id`]}
              onChange={(e) => handlePartnerChange(partnerNumber, e)}
              isInvalid={!!errors[`partner${partnerNumber}_email_id`]}
              required
            />
            <Form.Control.Feedback type="invalid">
                {errors[`partner${partnerNumber}_email_id`]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>PAN Number*</Form.Label>
            <Form.Control
              type="text"
              name="pan"
              value={formData[`partner${partnerNumber}_pan`]}
              onChange={(e) => handlePartnerChange(partnerNumber, e)}
              isInvalid={!!errors[`partner${partnerNumber}_pan`]}
              required
            />
            <Form.Control.Feedback type="invalid">
                {errors[`partner${partnerNumber}_pan`]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>Aadhar Number</Form.Label>
            <Form.Control
              type="text"
              name="aadhar"
              value={formData[`partner${partnerNumber}_aadhar`]}
              onChange={(e) => handlePartnerChange(partnerNumber, e)}
              isInvalid={!!errors[`partner${partnerNumber}_aadhar`]}
            />
            <Form.Control.Feedback type="invalid">
                {errors[`partner${partnerNumber}_pan`]}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={4}>
          <Form.Group>
            <Form.Label>Date of Birth</Form.Label>
            <Form.Control
              type="date"
              name="dob"
              value={formData[`partner${partnerNumber}_dob`]}
              onChange={(e) => handlePartnerChange(partnerNumber, e)}
            />
          </Form.Group>
        </Col>
      </Row>

      </Card.Body>
    </Card>
  );

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Connector Registration</h2>
      <Form onSubmit={handleSubmit}>
        <Card className="mb-4">
          <Card.Header>Basic Information</Card.Header>
          <Card.Body>


          <Row className="align-items-center">
            <Col md={8}>
              <Form.Control
                type="text"
                placeholder="Search by connector name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={handleKeyPress}
                // disabled={loading}
                disabled={loading || disableSearch}

              />
            </Col>
            <Col md={4} className="d-flex gap-2 mt-2 mt-md-0">
              <Button 
                variant="primary" 
                onClick={handleSearch}
                disabled={loading || !searchTerm.trim() || disableSearch}


              >
                {loading ? 'Searching...' : 'Search'}
              </Button>
              <Button 
                variant="outline-secondary" 
                onClick={() => {
                  setFormData(initialState);
                  setSearchTerm('');
                  setSearchResults([]);
                  setDisableSearch(true); // Disable search completely
                }}
              >
                New
              </Button>
            </Col>

            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}

{searchResults.length > 0 && (
  <ListGroup className="mt-3">
    {searchResults.map(connector => (
      <ListGroup.Item 
        key={connector.id}
        action 
        onClick={() => loadConnectorData(connector.id)}
        className="d-flex justify-content-between align-items-start"
      >
        <div>
          <div className="fw-bold">{connector.name}</div>
          <div className="text-muted">Code: {connector.code}</div>
          <div className="text-muted">Type: {connector.connector_type}</div>
          <div className="text-muted">State: {connector.state}</div>
          <div className="text-muted">Location: {connector.base_location}</div>        
        </div>
        <Badge bg="secondary" pill>
          {connector.status}
        </Badge>
      </ListGroup.Item>
    ))}
  </ListGroup>
)}

{searchResults.length === 0 && searchTerm && !loading && (
  <div className="text-muted mt-3">No results found for "{searchTerm}"</div>
)}
          </Row>


            <Row className="mb-3">
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Name *</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    isInvalid={!!errors.name}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                          {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {/* <Col md={4}>
                <Form.Group>
                  <Form.Label>Code *</Form.Label>
                  <Form.Control
                    type="text"
                    name="code"
                    value={formData.code}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
              </Col> */}
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Type *</Form.Label>
                  <div>
                    <Form.Check
                      inline
                      type="radio"
                      label="Entity"
                      name="connector_type"
                      value="Entity"
                      checked={formData.connector_type === 'Entity'}
                      onChange={handleInputChange}
                    />
                    <Form.Check
                      inline
                      type="radio"
                      label="Individual"
                      name="connector_type"
                      value="Individual"
                      checked={formData.connector_type === 'Individual'}
                      onChange={handleInputChange}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">

            <Col md={4}>
                <Form.Group>
                  <Form.Label>Entity Type *</Form.Label>
                  <Form.Control
                    as="select"
                    name="entity_type"
                    value={formData.entity_type}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="individual">Individual</option>
                    <option value="company">Company</option>
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group>
                  <Form.Label>Addressn Line1*</Form.Label>
                  <Form.Control
                    type="text"
                    name="addl1"
                    value={formData.addl1}
                    onChange={handleInputChange}
                    isInvalid={!!errors.addl1}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.addl1}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group>
                  <Form.Label>Address Line2</Form.Label>
                  <Form.Control
                    type="text"
                    name="addl2"
                    value={formData.addl2}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group>
                  <Form.Label>Address Line3</Form.Label>
                  <Form.Control
                    type="text"
                    name="addl3"
                    value={formData.addl3}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group>
                  <Form.Label>City *</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    isInvalid={!!errors.city}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group>
                  <Form.Label>Pin*</Form.Label>
                  <Form.Control
                      type="text"
                      name="pin"
                      value={formData.pin}
                      onChange={handleInputChange}
                      isInvalid={!!errors.pin}
                      required
                  />
              <Form.Control.Feedback type="invalid">
                      {errors.pin}
              </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={4}>
              <Form.Group>
              <Form.Label>State*</Form.Label>
              <Form.Control
                as="select"
                name="state"
                value={formData.state}
                onChange={handleStateChange}
                isInvalid={!!errors.state}
                required
                
              >
                <option value="">Select State</option>
                {stateOptions.map((state) => (
                  <option key={state.value} value={state.value}>
                    {state.label}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                      {errors.state}
              </Form.Control.Feedback>
            </Form.Group>

              </Col>

              <Col md={4}>
                <Form.Group>
                  <Form.Label>Base Location*</Form.Label>
                  <Form.Control
                    as="select"
                    name="base_location"
                    value={formData.base_location}
                    onChange={handleInputChange}
                    disabled={!formData.state}
                    required

                    
                  >
                    <option value="">Select Location</option>
                    {locations.map(location => (
                      <option key={location.id} value={location.name}>
                        {location.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>


              <Col md={4}>
                <Form.Group>
                  <Form.Label>Mobile *</Form.Label>
                  <Form.Control
                    type="tel"
                    name="mobile_no"
                    value={formData.mobile_no}
                    onChange={handleInputChange}
                    isInvalid={!!errors.mobile_no}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                          {errors.mobile_no}
                </Form.Control.Feedback>
                </Form.Group>
              </Col>
              
            </Row>

            <Row className="mb-3">
              <Col md={4}>
                <Form.Group>
                  <Form.Label>Email *</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    isInvalid={!!errors.email}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                        {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group>
                  <Form.Label>PAN *</Form.Label>
                  <Form.Control
                    type="text"
                    name="pan"
                    value={formData.pan}
                    onChange={handleInputChange}
                    isInvalid={!!errors.pan}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                  {errors.pan}
                </Form.Control.Feedback>
                </Form.Group>
              </Col>

              {/* <Col md={4}>
                  <Form.Group>
                    <Form.Label>GST *</Form.Label> 
                    <Form.Control
                      type="text"
                      name="gst"  
                      value={formData.gst}  
                      onChange={handleInputChange}
                      isInvalid={!!errors.gst}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.gst}
                  </Form.Control.Feedback>
                  </Form.Group>
                </Col> */}

          <Col md={4}>
            <Form.Group>
              <Form.Label>GST *</Form.Label>  
              <div>
                <Form.Check 
                  inline 
                  label="Yes" 
                  type="checkbox" 
                  checked={gstEnabled} 
                  onChange={() => setGstEnabled(true)} 
                />
                <Form.Check 
                  inline 
                  label="No" 
                  type="checkbox" 
                  checked={!gstEnabled} 
                  onChange={() => { 
                    setGstEnabled(false); 
                    setFormData({ ...formData, gst: "" }); 
                  }} 
                />
              </div>
              <Form.Control
                type="text"
                name="gst"  
                value={formData.gst}  
                onChange={handleInputChange}
                isInvalid={!!errors.gst}
                required={gstEnabled}  
                disabled={!gstEnabled}  
              />
              <Form.Control.Feedback type="invalid">
                {errors.gst}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

            </Row>

            <Row className="mb-3">

            <Col md={4}>
                <Form.Group>
                  <Form.Label>Aadhar </Form.Label>
                  <Form.Control
                    type="text"
                    name="aadhar"
                    value={formData.aadhar}
                    onChange={handleInputChange}
                    isInvalid={!!errors.aadhar}
                  />
                  <Form.Control.Feedback type="invalid">
                        {errors.aadhar}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group>
                  <Form.Label>Date of Incorporation</Form.Label>
                  <Form.Control
                    type="date"
                    name="date_of_incorporation"
                    value={formData.date_of_incorporation}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>

            </Row>
          </Card.Body>
        </Card>

        {formData.connector_type === 'Entity' && renderPartnerSection(1)}
        {formData.connector_type === 'Entity' && renderPartnerSection(2)}

        <Card className="mb-4">
          <Card.Header>Payee Information</Card.Header>
          <Card.Body>
            <Row className="mb-3">


            {/* <Col md={3}>
                  <Form.Group>
                    <Form.Label>Payee Code*</Form.Label> 
                    <Form.Control
                      type="text"
                      name="payee_code" 
                      value={newPayee.payee_code} 
                      onChange={handlePayeeChange}
                      isInvalid={!!errors.payee_code}
                    />
                    <Form.Control.Feedback type="invalid">
                    {errors.payee_code}
                  </Form.Control.Feedback>

                  </Form.Group>
            </Col> */}
              
              
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Payee Name *</Form.Label>
                  <Form.Control
                    type="text"
                    name="payee_name"
                    value={newPayee.payee_name}
                    onChange={handlePayeeChange}
                    isInvalid={!!errors.payee_name}
                    
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.payee_name}
                  </Form.Control.Feedback>
                </Form.Group>
                
              </Col>
              
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Account No *</Form.Label>
                  <Form.Control
                    type="text"
                    name="payee_account_no"
                    value={newPayee.payee_account_no}
                    onChange={handlePayeeChange}
                    isInvalid={!!errors.payee_account_no}
                    
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.payee_account_no}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group>
                  <Form.Label>IFSC Code *</Form.Label>
                  <Form.Control
                    type="text"
                    name="payee_ifsc"
                    value={newPayee.payee_ifsc}
                    onChange={handlePayeeChange}
                    isInvalid={!!errors.payee_ifsc}
                    
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.payee_ifsc}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={2}>
                  <Form.Group>
                    <Form.Label>Bank *</Form.Label>
                    <Form.Control
                      type="text"
                      name="payee_bank"
                      value={newPayee.payee_bank}
                      onChange={handlePayeeChange}
                      isInvalid={!!errors.payee_bank}
                      
                    />
                  <Form.Control.Feedback type="invalid">
                    {errors.payee_bank}
                  </Form.Control.Feedback>
                  </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group>
                  <Form.Label>Branch *</Form.Label>
                  <Form.Control
                    type="text"
                    name="payee_branch"
                    value={newPayee.payee_branch}
                    onChange={handlePayeeChange}
                    isInvalid={!!errors.payee_branch}
                    
                    
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.payee_branch}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group>
                  <Form.Label>PAN *</Form.Label>
                  <Form.Control
                    type="text"
                    name="payee_pan"
                    value={newPayee.payee_pan}
                    onChange={handlePayeeChange}
                    isInvalid={!!errors.payee_pan}
                    
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.payee_pan}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col md={3}>
                <Form.Group>
                  <Form.Label>Account Type *</Form.Label>
                  <Form.Control
                    as="select"
                    name="account_type"
                    value={newPayee.account_type}
                    onChange={handlePayeeChange}
                    isInvalid={!!errors.account_type}
                    
                  >
                    <option value="Saving">Saving</option>
                    <option value="Current">Current</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.account_type}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              

              {/* <Col md={3}>
                <Form.Group>
                  <Form.Label>Status*</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    value={newPayee.status}
                    onChange={handlePayeeChange}
                  >
                      <option value="Entered">Entered</option>
                      <option value="Updated">Updated</option>
                      <option value="Deleted">Deleted</option>
                      <option value="Verified">Verified</option>
                  </Form.Control>
                </Form.Group>
              </Col> */}

              <Col md={3} className="d-flex align-items-end">
                <Button variant="primary" onClick={handleAddPayee}>
                  {editingPayeeIndex > -1 ? 'Update' : 'Add'}  Payee
                </Button>
              </Col>
              
            </Row>


            {formData.payees.length > 0 && (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Payee Name</th>
                    <th>Account No</th>
                    <th>IFSC Code</th>
                    <th>Bank</th>
                    <th>Branch</th>
                    <th>PAN</th>
                    <th>Account Type</th>
                    {/* <th>Status</th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.payees.map((payee, index) => (
                    <tr key={index}>
                      <td>{payee.payee_name}</td>
                      <td>{payee.payee_account_no}</td>
                      <td>{payee.payee_ifsc}</td>
                      <td>{payee.payee_bank}</td>
                      <td>{payee.payee_branch}</td>
                      <td>{payee.payee_pan}</td>
                      <td>{payee.account_type}</td>
                      <td>{payee.status}</td>
                      <td>
                        <Button
                          variant="warning"
                          size="sm"
                          onClick={() => handleEditPayee(index)}
                          className="me-2"
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleDeletePayee(index)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>



       {/* Document Section */}
       <Card className="mt-4">
            <Card.Header>
              <h5>Documents</h5>
            </Card.Header>
            <Card.Body>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Document Description</th>
                    <th>Document Number</th>
                    <th>Remarks</th>
                    <th>Upload Doc</th>
                    <th>View Document</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((doc, index) => (
                    <tr key={index}>
                      <td>{doc.sr_no}</td>
                      <td>{doc.document_description}</td>
                      <td>
                        <Form.Control
                          type="text"
                          name="document_number"
                          value={doc.document_number}
                          onChange={(e) => handleDocumentChange(index, e)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="text"
                          name="remarks"
                          value={doc.remarks}
                          onChange={(e) => handleDocumentChange(index, e)}
                        />
                      </td>
                      <td>
                        <Form.Control
                          type="file"
                          onChange={(e) => handleFileUpload(index, e)}
                        />
                      </td>
                      <td>
                        {doc.file && (
                          <Button variant="link" onClick={() => window.open(URL.createObjectURL(doc.file))}>
                            View
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>




        <div className="d-flex justify-content-end mb-4">
        <Button variant="primary" type="submit" className="me-2" disabled={formData.payees.length === 0}>
          {formData.id ? 'Update' : 'Submit'}
        </Button>

          <Button variant="success" className="me-2" onClick={handleVerify} disabled={!formData.id || formData.verified_status === 'Verified'}>
          Verify
        </Button>

        <Button variant="danger" className="me-2" onClick={handleDelete} disabled={!formData.id}>
          Delete
        </Button>

          <Button variant="secondary" onClick={() => navigate('/connectors')}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ConnectorRegistration;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Table,Pagination , Alert } from 'react-bootstrap';
import { FaSearch, FaEdit, FaMapMarkerAlt ,FaSave } from 'react-icons/fa';
import { API_URL } from '../config';
import api from '../services/api';
import Select from 'react-select';

const MultipleLeadMap = () => {
  const [billingMonths, setBillingMonths] = useState([]);
  const [lenders, setLenders] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [searchParams, setSearchParams] = useState({
    customer_name: '',
    los_no: '',
    loan_amount: '',
    product: '',
    lender: '',
    fpr:'',
    state: '', 
    product_category:"",
    source:"",
    payout_sharing:"",
  });
  const [tempPercentage, setTempPercentage] = useState("0.00");
  const [location, setLocation] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [editableData, setEditableData] = useState([]);
  const [editedLeads, setEditedLeads] = useState(new Set()); 
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [connectors, setConnectors] = useState([]);
  const [connectorError, setConnectorError] = useState('');
  const locationOptions = location.map(loc => ({
    value: loc.id,
    label: loc.name,
  }));
  const [connector1, setConnector1] = useState(null);
  const [connector2, setConnector2] = useState(null);

  const pageSizeOptions = [
    { value: 10, label: '10' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 150, label: '150' }
  ];
  const formatPercentageForDisplay = (value) => {
    return value ? (value * 100).toFixed(2) : '';
  };
  const handleLocationChange = (selectedOption) => {
    setSearchParams({
      ...searchParams,
      location: selectedOption ? selectedOption.label : '',
    });
  };
  const handleTempChange = (e) => {
    // Limit input to a number with max two decimal places
    let input = e.target.value;
    const regex = /^\d*\.?\d{0,2}$/; // Allows up to 2 decimal places

    if (regex.test(input)) {
        setTempPercentage(input);
    }
};

const handleBlur = (index) => {
    // Update lead's external_payout_percentage only after input is finalized
    const value = parseFloat(tempPercentage) / 100;
    const updatedLeads = [...editableData];
    updatedLeads[index].external_payout_percentage = value;
    setEditableData(updatedLeads);
    setTempPercentage(value * 100); // Reset temp to formatted display
};
  const handleInputChangeforConnector = (e, index) => {
    const { name, value } = e.target;
    const updatedData = [...editableData];
    updatedData[index][name] = value;
  
    // Check if connectors are the same
    const { connector_1, connector_2 } = updatedData[index];
  
    if (connector_1 && connector_2 && connector_1 === connector_2) {
      // If connectors are the same, set an error and clear connector_2
      setConnectorError(`Connector 1 and Connector 2 cannot be the same for Customer Name: ${updatedData[index].customer_name}`);
      alert(`Connector 1 and Connector 2 cannot be the same for Customer Name: ${updatedData[index].customer_name}`);
  
      // Clear connector_2
      updatedData[index].connector_2 = '';
    } else {
      // Clear the error message if connectors are not the same
      setConnectorError('');
    }
  
    // Update the state with the modified data
    setEditableData(updatedData);
    const leadId = updatedData[index].sr_no;
  setEditedLeads(prev => new Set(prev).add(leadId));
  };
  
  const handleSearch = async (page = 1) => {
    if (!selectedMonth) {
      setError('Please select a billing month');
      return;
    }
  
    try {
      setLoading(true);
      setError('');
  
      const response = await api.get('/api/multiple-lead-map/', {
        params: { 
          billing_month: selectedMonth, 
          ...searchParams,
          page: page,
          page_size: pageSize
        },
      });
  
      if (response.data.status === 'success') {
        setEditableData(response.data.data || []);
        setTotalRecords(response.data.total_records);
        setCurrentPage(page);
        setSuccess(`Found ${response.data.total_records} leads`);
      } else {
        setEditableData([]);
        setError('No leads found');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while searching leads');
    } finally {
      setLoading(false);
    }
  };
  
  const handleInputChangeforFilter = (e) => {
    const target = e.target || e;
    const value = target.value;
  
    setSearchParams((prevParams) => ({
      ...prevParams,
      [target.name]: value
    }));
  };
  // const handleInputChangeforFilter = (key, value) => {
  //   setSearchParams((prevParams) => ({
  //     ...prevParams,
  //     [key]: value, 
  //   }));
  // };
  
  
const handleInputChange = (e, index) => {
  const { name, value } = e.target;
  const updatedData = [...editableData];
  updatedData[index][name] = value;

  // Get net loan and total_po_received_percentage for calculations
  const netLoan = updatedData[index].net_loan;
  let totalPOReceivedPercentage = parseFloat(updatedData[index].total_po_received_percentage) * 100;

  // Calculate External Payout Amount
  if (name === 'external_payout_percentage') {
    const externalPayoutPercentage = parseFloat(value);
    const externalPayoutAmount = (netLoan * (externalPayoutPercentage / 100)).toFixed(2);
    updatedData[index].external_payout_amt = externalPayoutAmount;
  
    totalPOReceivedPercentage = parseFloat(totalPOReceivedPercentage).toFixed(2);
    console.log('total', totalPOReceivedPercentage);
    console.log('exter', externalPayoutPercentage);
    
    const netMargin = (totalPOReceivedPercentage - externalPayoutPercentage).toFixed(2);
    console.log('net', netMargin);
  
    updatedData[index].net_margin = netMargin;
  }

  

  // Allow manual editing of External Payout Amount
  if (name === 'external_payout_amt') {
    updatedData[index].external_payout_amt = value;
  }
  
  const product_category = updatedData[index].product_category;
if (name === 'external_payout_amt' && product_category === 'Credit Card') {
  
  const externalPayoutAmount = parseFloat(value);
  
  let totalPOReceivedAmount = updatedData[index].base_po_received_amt ? parseFloat(updatedData[index].base_po_received_amt).toFixed(2) : '';
  const netAmount = (totalPOReceivedAmount - externalPayoutAmount).toFixed(0)
  updatedData[index].net_amount = netAmount;
  

}

  // Update Source field and related fields
  if (name === 'source') {
    const source = value;
    updatedData[index].connector_1 = source === 'Internal' ? '' : updatedData[index].connector_1;
    updatedData[index].connector_2 = source === 'Internal' ? '' : updatedData[index].connector_2;
    updatedData[index].tme = source === 'Internal' ? '' : updatedData[index].tme;
    updatedData[index].tl = source === 'Internal' ? '' : updatedData[index].tl;
    updatedData[index].tm = source === 'Internal' ? '' : updatedData[index].tm;
    updatedData[index].bm = source === 'Internal' ? '' : updatedData[index].bm;
    updatedData[index].rsm = source === 'Internal' ? '' : updatedData[index].rsm;
    updatedData[index].zsm = source === 'Internal' ? '' : updatedData[index].zsm;
  }

  setEditableData(updatedData);

  // Track which lead was edited
  const leadId = updatedData[index].sr_no;
  setEditedLeads(prev => new Set(prev).add(leadId));
};
const stateOptions = [
    { value: 'Delhi', label: 'Delhi' },
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kerala', label: 'Kerala' },
    { value: 'Chattisgarh', label: 'Chattisgarh' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'West Bengal', label: 'West Bengal' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Puducherry', label: 'Puducherry' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'Orissa', label: 'Orissa' },
    { value: 'Pondicherry', label: 'Pondicherry' },
    { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'Dadra and Nagar Haveli and Daman and Diu' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' },
    { value: 'Tamilnadu', label: 'Tamilnadu' },
    { value: 'Uttaranchal', label: 'Uttaranchal' },
    { value: 'Agra', label: 'Agra' },
    { value: 'NCR', label: 'NCR' },
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Odisha', label: 'Odisha' },
    { value: 'Pondichery', label: 'Pondichery' },
    { value: 'Tripura', label: 'Tripura' },
    { value: 'Nct Of Delhi', label: 'Nct Of Delhi' },
    { value: 'Kolhapur', label: 'Kolhapur' },
    { value: 'Tamil Nadu 1', label: 'Tamil Nadu 1' },
    { value: 'Central UP', label: 'Central UP' }
  ];

const userData = JSON.parse(localStorage.getItem('user')) || {};
// const userType = parseInt(localStorage.getItem('user_type'));
const userType = parseInt(userData.user_type || ""); 
// console.log(userType);


const isDisabled = (lead) => lead.is_mapped && lead.is_submitted;
const handleBatchSubmit = async () => {
  if (editedLeads.size === 0) {
    setError('No changes to submit');
    return;
  }

  try {
    setLoading(true);
    
    // Filter only the edited leads
    const updatedLeads = editableData.filter(lead => 
      editedLeads.has(lead.sr_no)
    ).map(lead => ({
      id: lead.id,
      tl: lead.tl,
      tm: lead.tm,
      tme: lead.tme,
      bm: lead.bm,
      rsm: lead.rsm,
      zsm: lead.zsm,
      external_payout_percentage: lead.external_payout_percentage,
      external_payout_amt: lead.external_payout_amt,
      net_margin: lead.net_margin,
    
      net_amount: lead.net_amount,
      remarks1: lead.remarks1,
      connector_1: lead.connector_1,
      connector_2: lead.connector_2,
      external_payout_percentage: lead.external_payout_percentage,
      external_payout_amt: lead.external_payout_amt,
      status: lead.status,
      
      tl: lead.tl,
     
      remarks2: lead.remarks2,
      pdd_otc_status: lead.pdd_otc_status,
      advance_paid: lead.advance_paid,
      connector1_perc: lead.connector1_perc,
      connector2_perc: lead.connector2_perc,
      invoice_status: lead.invoice_status,



    }));

    const response = await api.post('/api/update-multiple-leads/', {
      leads: updatedLeads
    });

    if (response.data.success) {
      setSuccess('All changes saved successfully');
      setEditedLeads(new Set());
    
      
      setTimeout(() => {
        setSuccess(''); 
        handleSearch(currentPage);
      }, 2000);
    } else {
      setError('Failed to update some leads');
    }
    
  } catch (error) {
    setError('An error occurred while updating leads');
  } finally {
    setLoading(false);
  }
};
  const handleSubmit = async (leadId, index) => {
    const lead = editableData[index];
    try {
      const response = await api.put(`/api/update-lead/${leadId}/`, lead);
      if (response.data.success) {
        setSuccess('Lead updated successfully');
        setError('');
      } else {
        setError('Failed to update lead');
      }
    } catch (error) {
      setError('An error occurred while updating lead');
    }
  };




  useEffect(() => {
    const fetchData = async () => {
      try {
        const billingMonthsResponse = await api.get('/api/billing-months/');
        setBillingMonths(billingMonthsResponse.data);

        const lendersResponse = await api.get('/api/lenders/');
        setLenders(lendersResponse.data);

        const productsResponse = await api.get('/api/products/');
        setProducts(productsResponse.data);

        const locationResponse = await api.get('/api/locations/');
        setLocation(locationResponse.data);

        const ConnectorResponse = await api.get('/api/connectors/');
        setConnectors(ConnectorResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const renderPagination = () => {
    const totalPages = Math.ceil(totalRecords / pageSize);
    
    return (
      <Row className="mt-3 align-items-center">
        <Col md={2}>
          <Select
            options={pageSizeOptions}
            value={pageSizeOptions.find(opt => opt.value === pageSize)}
            onChange={(option) => {
              setPageSize(option.value);
              handleSearch(1);
            }}
            isDisabled={loading}
          />
        </Col>
        <Col md={8}>
          <Pagination className="justify-content-center">
            <Pagination.First onClick={() => handleSearch(1)} disabled={currentPage === 1 || loading} />
            <Pagination.Prev onClick={() => handleSearch(currentPage - 1)} disabled={currentPage === 1 || loading} />
            
            {[...Array(totalPages)].map((_, idx) => (
              <Pagination.Item
                key={idx + 1}
                active={idx + 1 === currentPage}
                onClick={() => handleSearch(idx + 1)}
                disabled={loading}
              >
                {idx + 1}
              </Pagination.Item>
            ))}
            
            <Pagination.Next onClick={() => handleSearch(currentPage + 1)} disabled={currentPage === totalPages || loading} />
            <Pagination.Last onClick={() => handleSearch(totalPages)} disabled={currentPage === totalPages || loading} />
          </Pagination>
        </Col>
        <Col md={2} className="text-end">
          <span>Total: {totalRecords}</span>
        </Col>
      </Row>
    );
  };

  return (
    <Container fluid>
      <h2 className="mb-4">Payout Mapping</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}

      <Form className="mb-4">
  <Row className="align-items-start">
    {/* First Row of Filters */}
    <Col md={3}>
  <Form.Group>
    <Form.Label>Billing Month</Form.Label>
    <Form.Control
      as="select"
      value={selectedMonth}
      onChange={(e) => setSelectedMonth(e.target.value)}
    >
      <option value="">Select Billing Month</option>
      <option value="all">Select All</option>
      {billingMonths.map(month => (
        <option key={month.id} value={month.id}>{`${month.month} ${month.year}`}</option>
      ))}
    </Form.Control>
  </Form.Group>
</Col>

<Col md={3}> 
  <Form.Group>
    <Form.Label>State</Form.Label>
    <Select
      options={stateOptions}
      value={stateOptions.find(opt => opt.value === searchParams.state)}
      onChange={(option) => handleInputChangeforFilter('state', option?.value || '')}
      isDisabled={!selectedMonth}
      isClearable
      placeholder="Search or select State..."
      noOptionsMessage={() => "No State found"}
    />
  </Form.Group>
</Col>


    <Col md={3}>
      <Form.Group>
        <Form.Label>Location</Form.Label>
        <Select
          options={locationOptions}
          value={locationOptions.find(opt => opt.value === searchParams.location)}
          onChange={handleLocationChange}
          isDisabled={!selectedMonth}
          isClearable
          placeholder="Search or select location..."
          noOptionsMessage={() => "No locations found"}
        />
      </Form.Group>
    </Col>
    <Col md={3}>
      <Form.Group>
        <Form.Label>FPR</Form.Label>
        <Form.Control
          type="text"
          name="fpr"
          placeholder="FPR"
          value={searchParams.fpr}
          onChange={handleInputChangeforFilter}
          disabled={!selectedMonth}
        />
      </Form.Group>
    </Col>
    <Col md={3}>
      <Form.Group>
        <Form.Label>Customer Name</Form.Label>
        <Form.Control
          type="text"
          name="customer_name"
          placeholder="Customer Name"
          value={searchParams.customer_name}
          onChange={handleInputChangeforFilter}
          disabled={!selectedMonth}
        />
      </Form.Group>
    </Col>
    <Col md={3}>
      <Form.Group>
        <Form.Label>LOS No</Form.Label>
        <Form.Control
          type="text"
          name="los_no"
          placeholder="LOS No"
          value={searchParams.los_no}
          onChange={handleInputChangeforFilter}
          disabled={!selectedMonth}
        />
      </Form.Group>
    </Col>
    <Col md={3}>
  <Form.Group>
    <Form.Label>Select Source</Form.Label>
    <Form.Select
      name="source"
      value={searchParams.source}
      placeholder="Select Source"
      disabled={!selectedMonth}
      onChange={handleInputChangeforFilter}
    >
      <option value="">Select Source</option>
      <option value="Internal">Internal</option>
      <option value="External">External</option>
      <option value="Digital">Digital</option>
    </Form.Select>
  </Form.Group>
</Col>
<Col md={3}>
  <Form.Group>
    <Form.Label>Payout Sharing</Form.Label>
    <Form.Select
      name="payout_sharing"
      value={searchParams.payout_sharing}
      placeholder="Select Payout Sharing"
      disabled={!selectedMonth}
      onChange={handleInputChangeforFilter}
    >
      <option value="">Select Payout Sharing</option>
      <option value="yes">Yes</option>
      <option value="no">No</option>

    </Form.Select>
  </Form.Group>
</Col>
  </Row>

  <Row className="mt-3 align-items-start">
    {/* Second Row of Filters */}
    <Col md={3}>
  <Form.Group>
    <Form.Label>Select Category</Form.Label>
    <Form.Select
      name="product_category"
      value={searchParams.product_category}
      placeholder="Select Category"
      disabled={!selectedMonth}
      onChange={handleInputChangeforFilter}
    >
      <option value="">Select Category</option>
      <option value="Unsecured">Unsecured</option>
      <option value="Secured">Secured</option>
      <option value="Credit Card">Credit Card</option>
    </Form.Select>
  </Form.Group>
</Col>

    <Col md={3}>
      <Form.Group>
        <Form.Label>Product</Form.Label>
        <Form.Control
          as="select"
          name="product"
          value={searchParams.product}
          onChange={handleInputChangeforFilter}
          disabled={!selectedMonth}
        >
          <option value="">Select Product</option>
          {products.map(product => (
            <option key={product.id} value={product.product_code}>{product.product_code}</option>
          ))}
        </Form.Control>
      </Form.Group>
    </Col>
    <Col md={3}>
    <Form.Group>
    <Form.Label>Lender</Form.Label>
    <Form.Control
      as="select"
      name="lender"
      value={searchParams.lender}
      onChange={handleInputChangeforFilter}
      disabled={!selectedMonth}
    >
     
      
        <option value="">Select Lender</option>
        {lenders.map(lender => (
          <option value={lender.name}>{lender.name}</option>

        ))}
     
    </Form.Control>
  </Form.Group>
    </Col>
    <Col md={3}>
      <Form.Group>
        <Form.Label>Select Connector</Form.Label>
        <Form.Control
          as="select"
          name="connector"
          value={searchParams.connector}
          onChange={handleInputChangeforFilter}
          disabled={!selectedMonth}
        >
           <option value="">Select Connector 1</option>
  {connectors.map(connector => (
    <option key={connector.id} value={connector.name}>{connector.name}</option>
  ))}
        </Form.Control>
      </Form.Group>
    </Col>
    <Col md={3}>
      <Button 
        variant="primary" 
        onClick={handleSearch} 
        disabled={!selectedMonth}
        className="w-100 mt-4"
      >
        <FaSearch className="me-2" />
        Search
      </Button>
    </Col>
  </Row>
</Form>

        <div className="d-flex justify-content-between mb-3">
        <div>
          {editedLeads.size > 0 && (
            <span className="text-muted">
              {editedLeads.size} record(s) modified
            </span>
          )}
        </div>
        <Button
          variant="primary"
          onClick={handleBatchSubmit}
          disabled={editedLeads.size === 0 || loading}
        >
          <FaSave className="me-2" />
          Save All Changes
        </Button>
      </div>
        <Table responsive>
        <thead>
        <tr>
           
            <th>Bank</th>
            <th>Product</th>
            <th>Customer Name</th>
            <th>Net Loan</th>
            <th>LOS No</th>
            <th>Total PO Received %</th>
            <th>Base PO Received %</th>
            <th>Additional PO % </th>
            <th>Contest PO % </th>
            <th>Subvention%</th>
            <th>Source</th>
            <th>Connector 1</th>
            <th>Connector 2</th>
            <th>External payout %</th>
            <th>External Payout Amount</th>
            <th>Net Margin</th>
            <th>Net Amount</th>
            <th>Status</th>
      
            <th>TME</th>
            <th>TL</th>
            <th>TM</th>
            <th>Base PO Received Amount</th>
            <th>Additional PO Amount</th>
            <th>Contest PO Amount</th>
            <th>Remark 1</th>
            <th>Remark 2</th>
            <th>Connector 1 %</th>
            <th>Connector 2 %</th>
            <th>BM</th>
            <th>RSM</th>
            <th>ZSM</th>
            <th>PDD/OTC Status</th>
            <th>Advance paid</th>
            <th>Invoice Status</th>
          
           
          </tr>
        </thead>
        <tbody>
          {editableData.map((lead, index) => (
            <tr key={lead.sr_no} className={editedLeads.has(lead.id) ? 'table-warning' : ''}>
            
              <td>{lead.bank}</td>
              <td>{lead.product_type}</td>
              <td>{lead.customer_name}</td>
              <td>{lead.net_loan}</td>
              <td>{lead.loan_no}</td>
              <td>{(lead.total_po_received_percentage * 100).toFixed(2)}%</td>
              <td>{(lead.base_po_received_percentage * 100).toFixed(2)}%</td>
              <td>{(lead.addnl_payout_percentage * 100).toFixed(2)}%</td>
              <td>{(lead.contest_po_percentage * 100).toFixed(2)}%</td>
              <td>{lead.subvention_percentage}</td>
              <td>
  <Form.Control
    as="select"
    name="source"
    value={lead.source || "External"}  
    onChange={(e) => handleInputChange(e, index)}
    disabled={isDisabled(lead)}
    style={{ width: '80px' }}
  >
    <option value="">Select Source</option>
    <option value="Internal">Internal</option>
    <option value="External">External</option>
    <option value="Digital">Digital</option>
  </Form.Control>
</td>

              <td>
            <select
              name="connector_1"
              value={lead.connector_1 || ''}
              onChange={(e) => handleInputChangeforConnector(e, index)}
              disabled={isDisabled(lead)  || lead.source == 'Digital' || (lead.source == 'Internal')}
              style={{ width: '90px'}} 
            >
              <option value="">Select Connector 1</option>
              {connectors.map((connector) => (
                <option key={connector.id} value={connector.name}>
                  {connector.name}
                </option>
              ))}
            </select>
          </td>
          <td>
            <select
              name="connector_2"
              value={lead.connector_2 || ''}
              onChange={(e) => handleInputChangeforConnector(e, index)}
              disabled={isDisabled(lead)  || lead.source == 'Digital' || (lead.source == 'Internal')}
              style={{ width: '90px'}} 
            >
              <option value="">Select Connector 2</option>
              {connectors.map((connector) => (
                <option key={connector.id} value={connector.name}>
                  {connector.name}
                </option>
              ))}
            </select>
          </td>
          <td>
          <div className="d-flex align-items-center">
  <Form.Control
    type="number"
    min="0"
    max="10"
    step="0.01"
    placeholder="0.00"
    name="external_payout_percentage"
    value={lead.external_payout_percentage === '0' ? "" : lead.external_payout_percentage}
    onChange={(e) => handleInputChange(e, index)}
    disabled={isDisabled(lead)}
    style={{ width: '90px', textAlign: 'right' }}
  />
  <span>%</span>
</div>


</td>


              <td>
                <Form.Control
                  type="number"
                  min="0"
                  name="external_payout_amt"
                  value={(parseFloat(lead.external_payout_amt).toFixed(0))}

                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead)}
                  style={{ width: '90px'}} 
                />
              </td>
           
              {/* <td>{(lead.net_margin  * 100).toFixed(2)}%</td> */}
              {/* <td>{(lead.net_margin)}%</td> */}
              
              <td>
  {lead.net_margin.length > 4  
    ? (parseFloat(lead.net_margin) * 100).toFixed(2) 
    : parseFloat(lead.net_margin).toFixed(2)  
  }%
</td>










           
              <td>{(parseFloat(lead.net_amount) || 0).toFixed(0)}</td>

            
              <td>
                <Form.Control
                  type="text"
                  name="status"
                  value={lead.status}
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead)}
                  style={{ width: '90px'}} 
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  name="tme"
                  value={lead.tme}
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead) || lead.source !== 'Internal'}
                  style={{ width: '90px'}} 
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  name="tl"
                  value={lead.tl}
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead) || lead.source !== 'Internal'}
                  style={{ width: '90px'}} 
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  name="tm"
                  value={lead.tm}
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead) || lead.source !== 'Internal'}
                  style={{ width: '90px'}} 
                />
              </td>
            
              
              <td>{(parseFloat(lead.base_po_received_amt) || 0).toFixed(0)}</td>
<td>{(parseFloat(lead.addnl_payout_amt) || 0).toFixed(0)}</td>
<td>{(parseFloat(lead.contest_po_amt) || 0).toFixed(0)}</td>

              <td>
                <Form.Control
                  type="text"
                  name="remarks1"
                  value={lead.remarks1}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                 
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  name="remarks2"
                  value={lead.remarks2}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                 
                />
              </td>
              <td>
  <div className="d-flex align-items-center">
    <Form.Control
      type="number"
      min="0"
      max="100"
      step="0.01"
      name="connector1_perc"
      value={lead.connector1_perc || 0}  
      onChange={(e) => handleInputChange(e, index)}
      disabled={isDisabled(lead) || lead.source !== 'Internal'}
      style={{ width: '50px', textAlign: 'right' }}
    />
    <span>%</span>
  </div>
</td>
<td>
  <div className="d-flex align-items-center">
    <Form.Control
      type="number"
      min="0"
      max="100"
      step="0.01"
      name="connector2_perc"
      value={lead.connector2_perc || 0}  
      onChange={(e) => handleInputChange(e, index)}
      disabled={isDisabled(lead) || lead.source !== 'Internal'}
      style={{ width: '50px', textAlign: 'right' }}
    />
    <span>%</span>
  </div>
</td>
              <td>
                <Form.Control
                  type="text"
                  name="bm"
                  value={lead.bm}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead) || lead.source !== 'Internal'}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  name="rsm"
                  value={lead.rsm}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead) || lead.source !== 'Internal'}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  name="zsm"
                  value={lead.zsm}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead) || lead.source !== 'Internal'}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  name="pdd_otc_status"
                  value={lead.pdd_otc_status}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  name="advance_paid"
                  value={lead.advance_paid}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead)}
                />
              </td>
              <td>
                <Form.Control
                  type="text"
                  name="invoice_status"
                  value={lead.invoice_status}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {renderPagination()}
        </Container>
    );
};

export default MultipleLeadMap;

// src/components/Sidebar.js
import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FaHome, FaUpload, FaCheck,   FaDownload, FaCalendarCheck } from 'react-icons/fa';
import '../App.css'; 
import { FaRegPenToSquare } from "react-icons/fa6";

const Sidebar = () => {
  return (
    <Nav className="flex-column sidebar-nav">
      <Nav.Item>
        <NavLink to="/" className="nav-link custom-nav-link">
          <FaHome className="nav-icon" />
          Home
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/file-upload" className="nav-link custom-nav-link">
          <FaUpload className="nav-icon" />
          File Upload
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/verify-file" className="nav-link custom-nav-link">
          <FaCheck className="nav-icon" />
          Verify File
        </NavLink>
      </Nav.Item>
      {/* <Nav.Item>
        <NavLink to="/payout-mapping" className="nav-link custom-nav-link">
          <FaRegPenToSquare className="nav-icon" />
          Payout Mapping
        </NavLink>
      </Nav.Item> */}
      <Nav.Item>
        <NavLink to="/bulkmapping" className="nav-link custom-nav-link">
          <FaRegPenToSquare className="nav-icon" />
          Payout Bulk Mapping 
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/multiple-lead-map" className="nav-link custom-nav-link">
          <FaRegPenToSquare className="nav-icon" />
          Multiple Lead Map
        </NavLink>
      </Nav.Item>
      <Nav.Item>
        <NavLink to="/submit-mappingfile" className="nav-link custom-nav-link">
          <FaRegPenToSquare className="nav-icon" />
          Payout Mapping Submit
        </NavLink>
      </Nav.Item>
    
      <Nav.Item>
        <NavLink to="/download-payout" className="nav-link custom-nav-link">
          <FaDownload className="nav-icon" />
          Download Payout Details
        </NavLink>
      </Nav.Item>
      
{/* <Nav.Item> # commented on 08-03-2025
  <NavLink to="/add-connector" className="nav-link custom-nav-link">
    <FaRegPenToSquare className="nav-icon" />
    Add Connector
  </NavLink>
</Nav.Item> */}
{/* new add on 08-03-2025 */}
<Nav.Item> 
  <NavLink to="/ConnectorRegistration" className="nav-link custom-nav-link">
    <FaRegPenToSquare className="nav-icon" />
    ConnectorRegistration
  </NavLink>
</Nav.Item>
{/* new add on 08-03-2025 */}
      <Nav.Item>
        <NavLink to="/close-month" className="nav-link custom-nav-link">
          <FaCalendarCheck className="nav-icon" />
          Close Month
        </NavLink>
      </Nav.Item>
    </Nav>
  );
};

export default Sidebar;

import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './Component/Navbar';
import Layout from './Component/Layout'; // Assuming Layout includes the Sidebar
import Login from './Component/Login';
import Dashboard from './Component/Dashboard';
import FileUpload from './Component/FileUpload';
import VerifyFile from './Component/VerifyFile';
import PayoutMapping from './Component/PayoutMapping';
import WorkInProgress from './Component/WorkInProgress';
import DownloadExcel from './Component/DownloadExcel';
import LeadMappingSubmit from './Component/LeadMappingSubmit';
import BulkMappingForm from './Component/BulkMappingForm';
import MultipleLeadMap from './Component/MultipleLeadMap';
import AddConnector from './Component/AddConnector';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ConnectorRegistration from './Component/ConnectorRegistration'; // new add on 08-03-2025


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
    setIsAuthenticated(false);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };
  const userData = JSON.parse(localStorage.getItem('user')) || {}; 
  const userEmail = userData.email || ""; 

  return (
    <>
      {/* Render Navbar only when authenticated */}
      {isAuthenticated && (
        <Navbar 
          setIsAuthenticated={setIsAuthenticated} 
          isAuthenticated={isAuthenticated} 
          userEmail={userEmail} 
          onLogout={handleLogout} 
        />
      )}

      {/* Conditionally render Layout based on authentication */}
      {isAuthenticated ? (
        <Layout isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/file-upload" element={<FileUpload />} />
            <Route path="/verify-file" element={<VerifyFile />} />
            {/* <Route path="/payout-mapping" element={<PayoutMapping />} /> */}
            <Route path="/bulkmapping" element={<BulkMappingForm />} />
            <Route path="/submit-mappingfile" element={<LeadMappingSubmit />} />
            <Route path="/multiple-lead-map" element={<MultipleLeadMap />} />
            <Route path="/download-payout" element={<DownloadExcel />} />
            <Route path="/add-connector" element={< AddConnector/>} />
            <Route path="/ConnectorRegistration" element={<ConnectorRegistration/>}/> {/*added on 08-03-2025 */}
            <Route path="/close-month" element={<WorkInProgress />} />
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </Layout>
      ) : (
        <Routes>
          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/" element={<Navigate to="/login" replace />} />
        </Routes>
      )}
    </>
  );
}

export default App;



// ***********************before session timeout fn************
// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import Navbar from './Component/Navbar';
// import Login from './Component/Login';
// import Layout from './Component/Layout';
// import Dashboard from './Component/Dashboard';
// import FileUpload from './Component/FileUpload';
// import VerifyFile from './Component/VerifyFile';
// import PayoutMapping from './Component/PayoutMapping';
// import WorkInProgress from './Component/WorkInProgress';
// import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import DownloadExcel from './Component/DownloadExcel';
// import LeadMappingSubmit from './Component/LeadMappingSubmit';
// import BulkMappingForm from './Component/BulkMappingForm';

// function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       setIsAuthenticated(true);
//     }
//   }, []);

//   const ProtectedRoute = ({ children }) => {
//     if (!isAuthenticated) {
//       return <Navigate to="/login" replace />;
//     }
//     return <Layout>{children}</Layout>;
//   };
//   const userData = JSON.parse(localStorage.getItem('user')) || {}; // Retrieve user data
//   const userEmail = userData.email || ""; // Get user's email
//   return (
//     <Router>
//       {/* Ensure Navbar is inside Router */}
//       <Navbar 
//       setIsAuthenticated={setIsAuthenticated} 
//       isAuthenticated={isAuthenticated} 
//       userEmail={userEmail} 
//     />

//       <Routes>
//         <Route path="/login" element={
//           isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login setIsAuthenticated={setIsAuthenticated} />
//         } />
//         <Route path="/dashboard" element={
//           <ProtectedRoute>
//             <Dashboard />
//           </ProtectedRoute>
//         } />
//         <Route path="/file-upload" element={
//           <ProtectedRoute>
//             <FileUpload />
//           </ProtectedRoute>
//         } />
//         <Route path="/verify-file" element={
//           <ProtectedRoute>
//             <VerifyFile />
//           </ProtectedRoute>
//         } />
//         <Route path="/payout-mapping" element={
//           <ProtectedRoute>
//             <PayoutMapping />
//           </ProtectedRoute>
//         } />
//         <Route path="/bulkmapping" element={
//           <ProtectedRoute>
//             <BulkMappingForm />
//           </ProtectedRoute>
//         } />
//         <Route path="/submit-mappingfile" element={
//           <ProtectedRoute>
//             <LeadMappingSubmit />
//           </ProtectedRoute>
//         } />
//         <Route path="/download-payout" element={
//           <ProtectedRoute>
//             <DownloadExcel />
//           </ProtectedRoute>
//         } />
//         <Route path="/close-month" element={
//           <ProtectedRoute>
//             <WorkInProgress />
//           </ProtectedRoute>
//         } />
//         <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} replace />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;



// *********************before logout*********
// import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import Login from './Component/Login';
// import Layout from './Component/Layout';
// import Home from './Component/Home';
// import Dashboard from './Component/Dashboard';
// import FileUpload from './Component/FileUpload';
// import VerifyFile from './Component/VerifyFile';
// import PayoutMapping from './Component/PayoutMapping';
// import WorkInProgress from './Component/WorkInProgress';
// import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import DownloadExcel from './Component/DownloadExcel';
// import LeadMappingSubmit from './Component/LeadMappingSubmit';
// import BulkMappingForm from './Component/BulkMappingForm';

// function App() {
//   // for demo purposes removed login make it false for enable login
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       setIsAuthenticated(true);
//     }
//   }, []);

//   const ProtectedRoute = ({ children }) => {
//     // remove this isAuthention for alloeing login with email id and pass
//     if (!isAuthenticated) {
//       return <Navigate to="/login" replace />;
//     }
//     return <Layout>{children}</Layout>;
//   };

//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={
//           isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login setIsAuthenticated={setIsAuthenticated} />
//         } />
//         <Route path="/dashboard" element={
//           <ProtectedRoute>
//             <Dashboard />
//           </ProtectedRoute>
//         } />
//         <Route path="/file-upload" element={
//           <ProtectedRoute>
//             <FileUpload />
//           </ProtectedRoute>
//         } />
//         <Route path="/verify-file" element={
//           <ProtectedRoute>
//             <VerifyFile />
//           </ProtectedRoute>
//         } />
//         <Route path="/payout-mapping" element={
//           <ProtectedRoute>
//             <PayoutMapping />
//           </ProtectedRoute>
//         } />
//         <Route path="/bulkmapping" element={
//           <ProtectedRoute>
//             <BulkMappingForm />
//           </ProtectedRoute>
//         } />
//         <Route path="/submit-mappingfile" element={
//           <ProtectedRoute>
//             <LeadMappingSubmit />
//           </ProtectedRoute>
//         } />
//         <Route path="/download-payout" element={
//           <ProtectedRoute>
//             <DownloadExcel />
//           </ProtectedRoute>
//         } />
//         <Route path="/close-month" element={
//           <ProtectedRoute>
//             <WorkInProgress />
//           </ProtectedRoute>
//         } />
//         <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} replace />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;









// import {React, useState,useEffect} from 'react';
// import { Container } from 'react-bootstrap';
// import Navbar from './Component/Navbar';
// import Footer from './Component/Footer';
// import DeclareMonth from './Component/DeclareMonth';
// import FileUpload from './Component/FileUpload';
// import VerifyFile from './Component/VerifyFile';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css';
// // import { BrowserRouter, Route, Switch, Redirect as Router } from 'react-router-dom';
// import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
// import Home from './Component/Home';
// import Payout from './Component/PayoutMapping';
// import MappingModal from './Component/MappingForm';
// import Login from './Component/Layout'
// import Dashboard from './Component/Dashboard';
// import ProtectedRoute from './Component/ProtectedRoute';
// import Layout from './Component/Layout';

// function App() {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);

//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       setIsAuthenticated(true);
//     }
//   }, []);

//   return (

    
  
//     <Routes>
//       <Route
//         path="/login"
//         element={
//           isAuthenticated ? (
//             <Navigate to="/dashboard" replace />
//           ) : (
//             <Login setIsAuthenticated={setIsAuthenticated} />
//           )
//         }
//       />
//       <Route
//         path="/dashboard/*"
//         element={
//           isAuthenticated ? (
//             <Layout>
//               <Home />
//             </Layout>
//           ) : (
//             <Navigate to="/login" replace />
//           )
//         }
//       />
//       <Route
//         path="/"
//         element={<Navigate to={isAuthenticated ? "/dashboard" : "/login"} replace />}
//       />
//     </Routes>


//   //   <Router>
    
//   //   <Home />
//   //   {/* <Payout/> */}
//   //   {/* <MappingModal/> */}
//   // </Router>
  







//     // <div className="d-flex flex-column min-vh-100">
//     //   <Navbar />
//     //   <Container className="flex-grow-1 py-4">
//     //     {/* <h1 className="text-center mb-4">Finwizz Payout Mapping Application</h1> */}
//     //     {/* <DeclareMonth /> */}
//     //     {/* <FileUpload /> */}
//     //     {/* <VerifyFile /> */}
//     //   </Container>
//     //   <Footer />
//     // </div>
//   );
// }

// export default App;
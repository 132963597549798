import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

const Layout = ({ children ,isSidebarOpen, toggleSidebar}) => {
  return (
    <div className="d-flex flex-column min-vh-100">
      {/* <Navbar /> */}
      <Container fluid className="flex-grow-1">
        <Row>
        {isSidebarOpen && (
            <Col md={3} lg={2} className="sidebar">
              <Sidebar />
            </Col>
          )}
        <Col md={isSidebarOpen ? 9 : 12} lg={isSidebarOpen ? 10 : 12} className="main-content">
            <button className="toggle-btn" onClick={toggleSidebar}>
              {isSidebarOpen ? 'Hide Sidebar' : 'Show Sidebar'}
            </button>
            <h1 className="text-center mb-4 heading">Finwizz Payout Mapping Application</h1>
            {children}
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default Layout;









// src/components/Layout.js
// import React from 'react';
// import { Container, Row, Col } from 'react-bootstrap';
// import Navbar from './Navbar';
// import Sidebar from './Sidebar';
// import Footer from './Footer';

// const Layout = ({ children }) => {
//   return (
//     <div className="d-flex flex-column min-vh-100">
//       <Navbar />
//           <h1 className="text-center mb-4 heading">Finwizz Payout Mapping Application</h1> 
//       <Container fluid className="flex-grow-1">
//         <Row>
//           <Col md={3} lg={2} className="sidebar">
//             <Sidebar />
//           </Col>
//           <Col md={9} lg={10} className="main-content">
//             {children}
//           </Col>
//         </Row>
//       </Container>
//       <Footer />
//     </div>
//   );
// };

// export default Layout;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Alert, Card, Table, Modal, Spinner } from 'react-bootstrap';
import { API_URL } from '../config';
import api from '../services/api';

const FileUpload = () => {
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState([]);
    const [billingMonths, setBillingMonths] = useState([]);
    const [selectedBillingMonth, setSelectedBillingMonth] = useState('');
    const [lenders, setLenders] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedLender, setSelectedLender] = useState('');
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [rejectionWarning, setRejectionWarning] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showDeclareMonth, setShowDeclareMonth] = useState(false);
    const [newMonth, setNewMonth] = useState('');
    const [newYear, setNewYear] = useState('');
    

    useEffect(() => {
        // Fetch billing months, lenders, and products
        const fetchData = async () => {
            try {
                const billingMonthsResponse = await api.get('/api/billing-months/');
                setBillingMonths(billingMonthsResponse.data);
    
                const lendersResponse = await api.get('/api/lenders/');
                setLenders(lendersResponse.data);
    
                const productsResponse = await api.get('/api/products/');
                setProducts(productsResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);
    

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        return Array.from({length: 6}, (_, i) => currentYear - 5 + i);
    };
    
    const [yearOptions, setYearOptions] = useState(generateYearOptions());

    const onFileChange = (event) => setFile(event.target.files[0]);

    const onFileUpload = () => {
        if (!selectedBillingMonth) {
            setErrors([{ error: 'Please select or declare a billing month first.' }]);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('billing_month', selectedBillingMonth);
        formData.append('lender', selectedLender);
        formData.append('category', selectedCategory);
        formData.append('product', selectedProduct);

        uploadFile(formData);
    };

    // const uploadFile = (formData) => {
    //     setLoading(true);
    //     const headers = { 'Content-Type': 'multipart/form-data' };
    //     axios.post(`${API_URL}api/upload/`, formData, { headers })
    //         .then(response => {
    //             setLoading(false);
    //             if (response.data.warning) {
    //                 setRejectionWarning(response.data.details);
    //                 setShowConfirmation(true);
    //             } else {
    //                 handleSuccessfulUpload(response.data);
    //             }
    //         })
    //         .catch(error => {
    //             setLoading(false);
    //             handleUploadError(error);
    //         });
    // };
    

    // **********with bear token and auth****
    const uploadFile = (formData) => {
        setLoading(true);
    
        api.post('/api/upload/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            setLoading(false);
            if (response.data.warning) {
                setRejectionWarning(response.data.details);
                setShowConfirmation(true);
            } else {
                handleSuccessfulUpload(response.data);
            }
        })
        .catch(error => {
            setLoading(false);
            if (error.response && error.response.status === 403) {
                // alert("You don't have access to this module.");
            } else {
                handleUploadError(error);
            }
        });
    };
    
    
    
    const handleSuccessfulUpload = (data) => {
        setMessage(`${data.message} File Number: ${data.file_number}, Status: ${data.status}`);
        setErrors([]);
        setRejectionWarning(null);
        setShowConfirmation(false);
    };
    const handleUploadError = (error) => {
        if (error.response && error.response.data) {
            const errorData = error.response.data;
            if (errorData.error_type === "structure") {
                setErrors([{ error: `Structure Error: ${errorData.errors}` }]);
            } else if (errorData.error_type === "mandatory") {
                setErrors(errorData.errors.map((err) => ({
                    "Sr no": err["Sr no"],
                    "CUSTOMER NAME": err["CUSTOMER NAME"],
                    "field": err["field"],
                    "error": err["error"]
                })));
            } else {
                setErrors([{ error: errorData.error || 'Failed to upload file.' }]);
            }
        } else {
            setErrors([{ error: 'Failed to upload file.' }]);
        }
        setMessage('');
    };
    

    const confirmUpload = () => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('billing_month', selectedBillingMonth);
        formData.append('lender', selectedLender);
        formData.append('category', selectedCategory);
        formData.append('product', selectedProduct);
        formData.append('confirm', 'true');

        uploadFile(formData);
    };
    
    const handleDeclareMonth = () => {
        api.post('/api/declare-month/', { month: newMonth, year: newYear })
            .then(response => {
                const newBillingMonth = {
                    id: response.data.id,
                    month: newMonth,
                    year: newYear
                };
                setBillingMonths([...billingMonths, newBillingMonth]);
                setSelectedBillingMonth(response.data.id);
                setShowDeclareMonth(false);
                setMessage('New billing month declared successfully');
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    // alert("You don't have access to this module.");
                } else {
                    setErrors([{ error: 'Failed to declare new billing month.' }]);
                }
            });
    };
    

  
    useEffect(() => {
        const interval = setInterval(() => {
            const newYearOptions = generateYearOptions();
            if (newYearOptions[newYearOptions.length - 1] !== yearOptions[yearOptions.length - 1]) {
                setYearOptions(newYearOptions);
            }
        }, 1000 * 60 * 60); // Check every hour
    
        return () => clearInterval(interval);
    }, [yearOptions]);
   

    return (
        <Card className="m-4 p-4">
        <Card.Body>
            <h3 className="mb-4">Upload Excel File</h3>
            <Form.Group controlId="formBillingMonth">
                <Form.Label>Select Billing Month</Form.Label>
                <Form.Control
                    as="select"
                    value={selectedBillingMonth}
                    onChange={(e) => setSelectedBillingMonth(e.target.value)}
                >
                    <option value="">Select Billing Month</option>
                    {billingMonths.map(bm => (
                        <option key={bm.id} value={bm.id}>{`${bm.month} ${bm.year}`}</option>
                    ))}
                </Form.Control>
            </Form.Group>

            <Button
                variant="outline-primary"
                onClick={() => setShowDeclareMonth(true)}
                className="mt-2 mb-3"
            >
                Declare New Billing Month
            </Button>

            <Modal show={showDeclareMonth} onHide={() => setShowDeclareMonth(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Declare New Billing Month</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formNewMonth">
                        <Form.Label>Month</Form.Label>
                        <Form.Control
                            as="select"
                            value={newMonth}
                            onChange={(e) => setNewMonth(e.target.value)}
                        >
                            <option value="">Select Month</option>
                            {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map(m => (
                                <option key={m} value={m}>{m}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formNewYear">
    <Form.Label>Year</Form.Label>
    <Form.Control
        as="select"
        value={newYear}
        onChange={(e) => setNewYear(e.target.value)}
    >
        <option value="">Select Year</option>
        {yearOptions.map(year => (
            <option key={year} value={year}>{year}</option>
        ))}
    </Form.Control>
</Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeclareMonth(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleDeclareMonth}>
                        Declare
                    </Button>
                </Modal.Footer>
            </Modal>
                        <Form.Group controlId="formCategory">
                            <Form.Label>Select Category</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedCategory}
                                onChange={(e) => setSelectedCategory(e.target.value)}
                            >
                                <option value="">Select Category</option>
                                <option value='Unsecured'>Unsecured</option>
                                <option value='Secured'>Secured</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formLender">
                            <Form.Label>Select Lender</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedLender}
                                onChange={(e) => setSelectedLender(e.target.value)}
                            >
                                <option value="">Select Lender</option>
                                {lenders.map(lender => (
                                    <option key={lender.id} value={lender.id}>{lender.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formProduct">
                            <Form.Label>Select Product</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedProduct}
                                onChange={(e) => setSelectedProduct(e.target.value)}
                            >
                                <option value="">Select Product</option>
                                {products.map(product => (
                                    <option key={product.id} value={product.id}>{product.product_code}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formFileUpload">
                            <Form.Label>Upload File</Form.Label>
                            <Form.Control type="file" onChange={onFileChange} />
                        </Form.Group>
                        <Button
                    variant="primary"
                    onClick={onFileUpload}
                    disabled={!file || loading || !selectedBillingMonth}
                    className="mt-3"
                >
                    {loading ? <Spinner animation="border" size="sm" /> : 'Upload'}
                </Button>

                        {showConfirmation && (
                            <Modal show={showConfirmation} onHide={() => setShowConfirmation(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Warning</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>A file with these details was rejected earlier.</p>
                                    <p>Rejected on: {new Date(rejectionWarning.rejected_date).toLocaleString()}</p>
                                    <p>Rejected by: {rejectionWarning.rejected_by}</p>
                                    <p>Reason: {rejectionWarning.reason}</p>
                                    <p>Are you sure you want to upload this file?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="danger" onClick={confirmUpload}>Yes, Upload</Button>
                                    <Button variant="secondary" onClick={() => setShowConfirmation(false)}>Cancel</Button>
                                </Modal.Footer>
                            </Modal>
                        )}

{message && <Alert variant="success" className="mt-4">{message}</Alert>}
                {errors.length > 0 && (
                    <Alert variant="danger" className="mt-4">
                        <h4>Errors</h4>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Sr no</th>
                                    <th>Customer Name</th>
                                    <th>Field</th>
                                    <th>Error</th>
                                </tr>
                            </thead>
                            <tbody>
                                {errors.map((error, index) => (
                                    <tr key={index}>
                                        <td>{error['Sr no']}</td>
                                        <td>{error['CUSTOMER NAME']}</td>
                                        <td>{error['field']}</td>
                                        <td>{error['error']}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Alert>
                )}
            </Card.Body>
        </Card>
    );
};

export default FileUpload;

import axios from 'axios';

// const API_URL = 'http://192.168.0.246:8000';    //local serveer
export const API_URL = "https://ffsplsys.com"; //prod
  
// const API_URL = 'http://192.168.0.129:8000';  //dev server

const api = axios.create({
  baseURL: API_URL,
});

// Request interceptor
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    if (error.response?.status === 403) {
      alert("You don't have access to this particular module.");
      return Promise.reject(error);
    }

    // if(error.response?.status === 400 || error.response?.status === 205){
    //   logoutUser("Your session has expired. Please log in again.");

    // }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refresh_token');

      if (refreshToken) {
        try {
          const refreshResponse = await axios.post(`${API_URL}api/token/refresh/`, {
            refresh: refreshToken,
          });

          localStorage.setItem('token', refreshResponse.data.access);
          originalRequest.headers['Authorization'] = `Bearer ${refreshResponse.data.access}`;
          return api(originalRequest);
        } catch (refreshError) {
          console.log('Refresh token expired or invalid.');
          logoutUser("Your session has expired. Please log in again.");
        }
      } else {
        logoutUser("Your session has expired. Please log in again.");
      }
    }

    return Promise.reject(error);
  }
);

function logoutUser(message) {
  alert(message);
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user');
  window.location.href = '/login';
}

export default api;
import React from 'react';

const Dashboard = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <div>
      <h2>Dashboard</h2>
      {user ? (
        <p>Welcome, {user.email}!</p>
      ) : (
        <p>No user information available</p>
      )}
      {/* Add more dashboard content here */}
    </div>
  );
};

export default Dashboard;
import React from 'react';
import { Navbar as BootstrapNavbar, Nav, Container, Button } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../Component/logo-finwizz.png';
import { API_URL } from '../config';
import { FaUserCircle } from 'react-icons/fa';
import api from '../services/api';

const Navbar = ({ setIsAuthenticated, isAuthenticated , userEmail,onLogout   }) => {
  const navigate = useNavigate();

  // const handleLogout = async () => {
  //   try {
  //     const refreshToken = localStorage.getItem('refresh_token'); // Ensure it's the refresh token
  //     const response = await fetch(`${API_URL}api/logout/`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${localStorage.getItem('token')}`, // Send access token for auth
  //       },
  //       body: JSON.stringify({
  //         token: refreshToken, // Use refresh token here
  //       }),
  //     });
  
  //     if (response.ok) {
  //       localStorage.removeItem('token');
  //       localStorage.removeItem('refresh_token');
  //       localStorage.removeItem('user');
  //       setIsAuthenticated(false); // Update the authentication state
  //       navigate('/login'); // Use navigate for redirection
  //     } else {
  //       console.log('Logout failed');
  //     }
  //   } catch (error) {
  //     console.error('Error during logout:', error);
  //   }
  // };

  const handleLogout = async () => {
    try {
        const refreshToken = localStorage.getItem('refresh_token'); 

        
        const response = await api.post('/api/logout/', {
            token: refreshToken  
        });

        if (response.status === 205 || response.status === 200) {
            // Remove tokens and user data from local storage
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('user');
            setIsAuthenticated(false); 
            navigate('/login'); 
        } else {
            console.log('Logout failed');
        }
    } catch (error) {
        console.error('Error during logout:', error);
    }
};



  return (
    <BootstrapNavbar bg="light" variant="light" expand="lg">
      <Container>
        <BootstrapNavbar.Brand as={NavLink} to="/dashboard">
          <img
            src={logo}
            width="100"
            height="35"
            className="d-inline-block align-top mr-2"
            alt="Finwizz Logo"
          />
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {isAuthenticated && (
              <>
                <span className="me-2 d-flex align-items-center">
                  <FaUserCircle size={24} /> 
                  <span className="ms-1">{userEmail}</span> 
                </span>
                <Button variant="outline-danger" onClick={handleLogout}>
                  Logout
                </Button>
              </>
            )}
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;


// *****************before logout ********

// import React from 'react';
// import { Navbar as BootstrapNavbar, Nav, Container } from 'react-bootstrap';
// // import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import logo from '../Component/logo-finwizz.png';
// import { NavLink } from 'react-router-dom'
// import FileUpload from './FileUpload';
// import VerifyFile from './VerifyFile';

// const Navbar = () => {
//   return (
//     <BootstrapNavbar bg="light" variant="light" expand="lg">
//       <Container>
//         <BootstrapNavbar.Brand as={NavLink} to="/">
//           <img
//             src={logo}
//             width="100"
//               height="35"
//             className="d-inline-block align-top mr-2"
//             alt="Finwizz Logo"
//           />
         
//         </BootstrapNavbar.Brand>
//         <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
//         <BootstrapNavbar.Collapse id="basic-navbar-nav">
//           {/* <Nav className="ms-auto">
//             <Nav.Link as={NavLink} to="/">Home</Nav.Link>
           
//           </Nav> */}
//         </BootstrapNavbar.Collapse>
//       </Container>
      
//     </BootstrapNavbar>
    
//   );
// };

// export default Navbar;

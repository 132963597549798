


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MappingModal from './MappingForm';
import { Container, Row, Col, Form, Button, Table, Alert } from 'react-bootstrap';
import { FaSearch,FaEdit, FaMapMarkerAlt } from 'react-icons/fa';
import { API_URL } from '../config';
import api from '../services/api';

const PayoutMapping = () => {
    const [billingMonths, setBillingMonths] = useState([]);
    const [lenders, setLenders] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [searchParams, setSearchParams] = useState({
        customer_name: '',
        los_no: '',
        loan_amount: '',
        product: '',
        lender: ''
    });
   
    const [searchResults, setSearchResults] = useState([]);
    const [error, setError] = useState('');
    const [mappedLeads, setMappedLeads] = useState([]);
    const [success, setSuccess] = useState('');
    const [selectedLead, setSelectedLead] = useState(null);
    const [showMappingModal, setShowMappingModal] = useState(false);
    const [editableMappedLeads, setEditableMappedLeads] = useState([]);
    useEffect(() => {
        console.log('showMappingModal:', showMappingModal);
    }, [showMappingModal]);
    
    useEffect(() => {
        // Fetch billing months, lenders, and products
        const fetchData = async () => {
            try {
                const billingMonthsResponse = await api.get('/api/billing-months/');
                setBillingMonths(billingMonthsResponse.data);
    
                const lendersResponse = await api.get('/api/lenders/');
                setLenders(lendersResponse.data);
    
                const productsResponse = await api.get('/api/products/');
                setProducts(productsResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);

    const handleSearch = async () => {
        if (!selectedMonth || !searchParams.customer_name) {
            setError('Please select a billing month and enter a customer name');
            return;
        }
    
        try {
            setError('');  // Reset error message
    
            const response = await api.get('/api/search-leads/', {
                params: { billing_month: selectedMonth, ...searchParams }
            });
    
            if (response.data.message && response.data.mapped_leads) {
                setEditableMappedLeads(response.data.mapped_leads);  
                setMappedLeads(response.data.submitted_leads || []);
                setSearchResults([]);
                setSuccess(response.data.message);
                setError('');
            } else if (response.data.error) {
                setMappedLeads([]);
                setEditableMappedLeads([]);
                setSearchResults([]);
                setError(response.data.error);
            } else {
                setSearchResults(response.data);
                setMappedLeads([]);
                setEditableMappedLeads([]);
                setError('');
            }
        } catch (error) {
            console.error('Error:', error);
            
            if (error.response) {
                if (error.response.status === 403 && error.response.data.error) {
                    setError(error.response.data.error);  
                } else {
                    setError(error.response.data.error || 'An error occurred while searching leads');
                }
            } else {
                setError('Network error. Please try again.');
            }
        }
    };
    
    // const handleSearch = async () => {
    //     if (!selectedMonth || !searchParams.customer_name) {
    //         setError('Please select a billing month and enter a customer name');
    //         return;
    //     }
    
    //     try {
    //         setError('');  // Reset error message
    
    //         // Retrieve the token from local storage
    //         const token = localStorage.getItem('token');
    
    //         const headers = {
    //             'Authorization': `Bearer ${token}`,  
    //         };
    
    //         const response = await axios.get(`${API_URL}api/search-leads/`, {  // Use backticks here too
    //             params: { billing_month: selectedMonth, ...searchParams },
    //             headers: headers  
    //         });
    
    //         if (response.data.message && response.data.mapped_leads) {
    //             // The backend should handle role-based logic, so frontend just processes the response
    //             setEditableMappedLeads(response.data.mapped_leads);  
    //             setMappedLeads(response.data.submitted_leads || []);
    //             setSearchResults([]);
    //             setSuccess(response.data.message);
    //             setError('');
    //         } else if (response.data.error) {
    //             // Handle specific errors returned from the server
    //             setMappedLeads([]);
    //             setEditableMappedLeads([]);
    //             setSearchResults([]);
    //             setError(response.data.error);
    //         } else {
    //             // Fallback for valid data responses without errors
    //             setSearchResults(response.data);
    //             setMappedLeads([]);
    //             setEditableMappedLeads([]);
    //             setError('');
    //         }
    //     } catch (error) {
    //         console.error('Error:', error);
    
    //         if (error.response) {
    //             // Check for 403 error and handle it
    //             if (error.response.status === 403 && error.response.data.error) {
    //                 setError(error.response.data.error);  
    //             } else {
    //                 setError(error.response.data.error || 'An error occurred while searching leads');
    //             }
    //         } else {
    //             setError('Network error. Please try again.');
    //         }
    //     }
    // };
    
  
    const handleMappingSubmit = async (mappedData) => {
        try {
            setError('');  // Reset error message
    
            const response = await api.post('/api/map-lead/', {
                lead_id: selectedLead.id || selectedLead.lead_id,
                ...mappedData
            });
    
            setSuccess('Lead mapped successfully');
            setShowMappingModal(false);
            handleSearch(); // Refresh the search results
        } catch (error) {
            if (error.response && error.response.data.error) {
                setError(error.response.data.error);
            } else {
                setError('Error mapping lead: ' + (error.message || 'Unknown error'));
            }
        }
    };
    const handleInputChange = (e) => {
        setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
    };
    const handleMap = (lead) => {
        setSelectedLead(lead);
        setShowMappingModal(true);
    };

    const handleEdit = (lead) => {
        setSelectedLead({
            ...lead,
            id: lead.lead_id 
        });
        setShowMappingModal(true);
    };

    return (
        <Container fluid>
            <h2 className="mb-4">Payout Mapping</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}

            <Form className="mb-4">
                <Row className="align-items-end">
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Billing Month</Form.Label>
                            <Form.Control
                                as="select"
                                value={selectedMonth}
                                onChange={(e) => setSelectedMonth(e.target.value)}
                            >
                                <option value="">Select Billing Month</option>
                                {billingMonths.map(month => (
                                    <option key={month.id} value={month.id}>{`${month.month} ${month.year}`}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Customer Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="customer_name"
                                placeholder="Customer Name (required)"
                                value={searchParams.customer_name}
                                onChange={handleInputChange}
                                disabled={!selectedMonth}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group>
                            <Form.Label>LOS No</Form.Label>
                            <Form.Control
                                type="text"
                                name="los_no"
                                placeholder="LOS No"
                                value={searchParams.los_no}
                                onChange={handleInputChange}
                                disabled={!selectedMonth}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group>
                            <Form.Label>Loan Amount</Form.Label>
                            <Form.Control
                                type="text"
                                name="loan_amount"
                                placeholder="Loan Amount"
                                value={searchParams.loan_amount}
                                onChange={handleInputChange}
                                disabled={!selectedMonth}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Button 
                            variant="primary" 
                            onClick={handleSearch} 
                            disabled={!selectedMonth || !searchParams.customer_name}
                            className="w-100"
                        >
                            <FaSearch className="me-2" />
                            Search
                        </Button>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Product</Form.Label>
                            <Form.Control
                                as="select"
                                name="product"
                                value={searchParams.product}
                                onChange={handleInputChange}
                                disabled={!selectedMonth}
                            >
                                <option value="">Select Product</option>
                                {products.map(product => (
                                    <option key={product.id} value={product.id}>{product.product_code}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={3}>
                        <Form.Group>
                            <Form.Label>Lender</Form.Label>
                            <Form.Control
                                as="select"
                                name="lender"
                                value={searchParams.lender}
                                onChange={handleInputChange}
                                disabled={!selectedMonth}
                            >
                                <option value="">Select Lender</option>
                                {lenders.map(lender => (
                                    <option key={lender.id} value={lender.id}>{lender.name}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            <Container className="mt-4">
            {mappedLeads.length > 0 && (
                <div className="mapped-leads-table mt-4">
                    <h3 className="mb-3">These leads have been mapped and submitted:</h3>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Lead ID</th>
                                <th>Mapped By</th>
                                <th>Connector 1</th>
                                <th>Connector 2</th>
                                <th>Mapped At</th>
                                <th>Source</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mappedLeads.map((lead) => (
                                <tr key={lead.lead_id}>
                                    <td>{lead.lead_id}</td>
                                    <td>{lead.mapped_by}</td>
                                    <td>{lead.connector_1}</td>
                                    <td>{lead.connector_2}</td>
                                    <td>{new Date(lead.mapped_at).toLocaleString()}</td>
                                    <td>{lead.source}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
    </Container>
    {editableMappedLeads.length > 0 && (
                <div className="editable-mapped-leads-table mt-4">
                    <h3 className="mb-3">These leads have been mapped but can be edited:</h3>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Lead ID</th>
                                <th>Mapped By</th>
                                <th>Connector 1</th>
                                <th>Connector 2</th>
                                <th>Mapped At</th>
                                <th>Source</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {editableMappedLeads.map((lead) => (
                                <tr key={lead.lead_id}>
                                    <td>{lead.lead_id}</td>
                                    <td>{lead.mapped_by}</td>
                                    <td>{lead.connector_1}</td>
                                    <td>{lead.connector_2}</td>
                                    <td>{new Date(lead.mapped_at).toLocaleString()}</td>
                                    <td>{lead.source}</td>
                                    <td>
                                        <Button variant="outline-primary" size="sm" onClick={() => handleEdit(lead)}>
                                            <FaEdit className="me-1" />
                                            Edit
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            )}
        {searchResults.length > 0 && (
                <Table striped bordered hover responsive className="mt-4">
                    <thead>
                        <tr>
                            <th>Customer Name</th>
                            <th>LOS No</th>
                            <th>Gross Loan</th>
                            <th>Net Loan</th>
                            <th>Product Type</th>
                            <th>Bank</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults.map(lead => (
                            <tr key={lead.id}>
                                <td>{lead.customer_name}</td>
                                <td>{lead.loan_no}</td>
                                <td>{lead.gross_loan}</td>
                                <td>{lead.net_loan}</td>
                                <td>{lead.product_type}</td>
                                <td>{lead.bank}</td>
                                <td>
                                    <Button variant="outline-primary" size="sm" onClick={() => handleMap(lead)}>
                                        <FaEdit className="me-1" />
                                        Map
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}

{showMappingModal && selectedLead && (
                <MappingModal
                    lead={selectedLead}
                    onMap={handleMappingSubmit}
                    onClose={() => setShowMappingModal(false)}
                    show={showMappingModal}
                    isEdit={editableMappedLeads.some(lead => lead.lead_id === selectedLead.id || lead.lead_id === selectedLead.lead_id)}
                />
            )}
        </Container>
    );
};

export default PayoutMapping;

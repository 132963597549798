// src/components/WorkInProgress.js
import React from 'react';
import { Alert } from 'react-bootstrap';

const WorkInProgress = () => {
  return (
    <Alert variant="info">
      Work in progress. This feature is coming soon!
    </Alert>
  );
};

export default WorkInProgress;
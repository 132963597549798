import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Form, Button, Card, Table, Alert } from 'react-bootstrap';
import { API_URL } from '../config';
import api from '../services/api';

const LeadMappingSubmit = () => {
    const [billingMonths, setBillingMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [lenders, setLenders] = useState([]);
    const [selectedLender, setSelectedLender] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [connectors, setConnectors] = useState([]); // changes done by sumedh
    const [selectedConnector, setSelectedConnector] = useState(''); // changes done by sumedh
    const [searchParams, setSearchParams] = useState({ connector: '' });
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [summary, setSummary] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        // Fetch billing months, lenders, and products
        const fetchData = async () => {
            try {
                const billingMonthsResponse = await api.get('/api/billing-months/');
                setBillingMonths(billingMonthsResponse.data);
    
                const lendersResponse = await api.get('/api/lenders/');
                setLenders(lendersResponse.data);

                const ConnectorResponse = await api.get('/api/connectors/');
                setConnectors(ConnectorResponse.data);
    
                const productsResponse = await api.get('/api/products/');
                setProducts(productsResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);

    // const handleInputChangeforFilter = (e) => {
    //     setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
    // };

    // const handleSubmit = () => {
    //     setErrorMessage('');
    
    //     api.get('/api/files-to-submit/', {
    //         params: {
    //             billing_month: selectedMonth,
    //             // lender: selectedLender,
    //             connector: selectedConnector,
    //             // product: selectedProduct
                
    //         }
    //     })
    //     .then(response => setFiles(response.data))
    //     .catch(error => {
    //         if (error.response?.data?.error) {
    //             setErrorMessage(error.response.data.error);
    //         } else {
    //             console.error('Error fetching files:', error);
    //         }
    //     });
    // };
        //     .then(response => {
        //         setFiles(response.data);
        //         if (response.data.length > 0) {
        //             api.get(`/api/file-summary-for-submission/`)
        //             .then(summaryResponse => setSummary(summaryResponse.data))
        //             .catch(error => console.error('Error fetching file summary:', error));
        //         }
        //     })
        //     .catch(error => {
        //         if (error.response?.data?.error) {
        //             setErrorMessage(error.response.data.error);
        //         } else {
        //             console.error('Error fetching files:', error);
        //         }
        //     });
        // };
            // const handleFileSelect = (fileId) => {
            //     setSelectedFile(fileId);
            
            //     api.get(`/api/file-summary-for-submission/${fileId}/`)
            //     .then(response => setSummary(response.data))
            //     .catch(error => console.error('Error fetching file summary:', error));
            // };
    
    const handleSubmitMapping = () => {
        api.post(`/api/submit-mapped-leads/${selectedConnector}/`)
        // console.log(selectedConnector)
        .then(response => {
            alert('Mapped leads submitted successfully');
            resetUI();
        })
        .catch(error => {
            if (error.response?.data?.error) {
                setErrorMessage(error.response.data.error);
            } else {
                console.error('Error submitting mapped leads:', error);
            }
        });
    };
    
    
    const resetUI = () => {
        setFiles([]);
        setSelectedFile(null);
        setSummary(null);
        setErrorMessage('');
    };

    return (
        <Card>
            <Card.Body>
                <Card.Title>Submit Payout Mapping File</Card.Title>
                <Form>
                    <Form.Group className='mt-4'>
                    <Form.Label><span style={{ color: 'red',fontSize: '1.2em' }}>*</span> Select Billing Month </Form.Label> {/* changes done by sumedh*/}
                        <Form.Control as="select" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                            <option value="">Select Billing Month</option>
                            <option value="all">Select All</option>
                            {/* {billingMonths.map(month => (
                                <option key={month.id} value={month.id}>{`${month.month} ${month.year}`}</option>
                            ))} */}
                            {billingMonths
                            .sort((a, b) => {
                                // Define financial year sorting logic
                                const getMonthValue = (month) => {
                                // Adjust month values: April (4) becomes 1, March (3) becomes 12
                                const monthOrder = {
                                    January: 10,
                                    February: 11,
                                    March: 12,
                                    April: 1,
                                    May: 2,
                                    June: 3,
                                    July: 4,
                                    August: 5,
                                    September: 6,
                                    October: 7,
                                    November: 8,
                                    December: 9,
                                };
                                return monthOrder[month];
                                };

                                const aMonthValue = getMonthValue(a.month);
                                const bMonthValue = getMonthValue(b.month);

                                if (a.year === b.year) {
                                return aMonthValue - bMonthValue;
                                }
                                return a.year - b.year;
                            })
                            .map(month => (
                                <option key={month.id} value={month.id}>{`${month.month} ${month.year}`}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                    <Form.Label> Select Lender </Form.Label> {/* changes done by sumedh*/}
                        <Form.Control as="select" value={selectedLender} onChange={(e) => setSelectedLender(e.target.value)}>
                            <option value="">Select Lender</option>
                            {lenders.map(lender => (
                                <option key={lender.id} value={lender.id}>{lender.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='mt-2'> 
                        <Form.Label>
                            <span style={{ color: 'red', fontSize: '1.2em' }}>*</span> Select Connector 
                        </Form.Label>
                        <Form.Control as="select" value={selectedConnector} onChange={(e) => setSelectedConnector(e.target.value)}>
                            <option value="">Select Connector</option>
                            {connectors.map(connector => (
                                <option key={connector.name} value={connector.name}>{connector.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                    <Form.Label>Select Product</Form.Label> {/* changes done by sumedh*/}
                        <Form.Control as="select" value={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value)}>
                            <option value="">Select Product</option>
                            {products.map(product => (
                                <option key={product.id} value={product.id}>{product.product_name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Button className='mt-4' variant="primary" onClick={handleSubmitMapping}>Submit</Button>
                </Form>

                {errorMessage && (
                    <Alert variant="danger" className="mt-4">
                        {errorMessage}
                    </Alert>
                )}

                {/* {files.length > 0 && (
                    <Form.Group className="mt-3">
                        <Form.Label>Select File to Submit</Form.Label>
                        <Form.Control as="select" onChange={(e) => handleFileSelect(e.target.value)}>
                            <option value="">Select File</option>
                            {files.map(file => (
                                <option key={file.id} value={file.id}>{file.file_name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                )} */}

                {summary && (
                    <Card className="mt-3">
                        <Card.Body>
                            <Card.Title>Summary</Card.Title>
                            <Table striped bordered hover>
                                <tbody>
                                    <tr>
                                        <th>No. of Records in File</th>
                                        <td>{summary.record_count}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Gross Loan Amount</th>
                                        <td>{summary.total_gross_loan}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Net Loan Amount</th>
                                        <td>{summary.total_net_loan}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Commission Received</th>
                                        <td>{summary.total_commission_received}</td>
                                    </tr>
                                    <tr>
                                        <th>Number of Leads Mapped</th>
                                        <td>{summary.mapped_leads_count}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Net Amount for Mapped Leads</th>
                                        <td>{summary.mapped_leads_net_amount}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Commission to be Paid</th>
                                        <td>{summary.mapped_leads_commission}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <Button variant="success" onClick={handleSubmitMapping} className="mr-2">Submit Mapped Leads</Button>
                        </Card.Body>
                    </Card>
                )}
            </Card.Body>
        </Card>
    );
}

export default LeadMappingSubmit
import React, { useState, useEffect } from 'react';
//  import axios from 'axios';
import { Container, Row, Col, Form, Button, Table,Pagination , Alert } from 'react-bootstrap';
import { FaSearch, FaEdit, FaMapMarkerAlt ,FaSave } from 'react-icons/fa';
import { API_URL } from '../config';
import api from '../services/api';
import Select from 'react-select';
import * as XLSX from 'xlsx';

import '..//App.css'; // Import the CSS file for table styling


const MultipleLeadMap = ({ searchData }) => {
  const [billingMonths, setBillingMonths] = useState([]);
  const [lenders, setLenders] = useState([]);
  const [selectedLenders, setSelectedLenders] = useState([]); // For selected lenders
  const [data, setData] = useState([]); // To store fetched data
  const [products, setProducts] = useState([]);
  const [state, setState] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState("");
  const [showNegativeMargin, setShowNegativeMargin] = useState(false);
  const [category, setCategory] = useState("");
  const [selectedCategory, setSelectedcategory] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(""); // For selected customer
  const [customers, setCustomerNames] = useState([]); // For customer data
  const [leads, setLeads] = useState([]);
  const [creditCardLeads, setCreditCardLeads] = useState([]); // Data for Credit Cards
  
  
  const [searchParams, setSearchParams] = useState({
    customer_name: '',
    los_no: '',
    loan_amount: '',
    product:[],  //change done by PG for Multiselect product
    lender: [],  //change done by PG for Multiselect Lender
    fpr:'',
    state: '', 
    product_category:"",
    source:"",
    payout_sharing:"",
    location: '',
    
  });
  const [tempPercentage, setTempPercentage] = useState("0.00");
  const [location, setLocation] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [editableData, setEditableData] = useState([]);
  const [editedLeads, setEditedLeads] = useState(new Set()); 
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [connectors, setConnectors] = useState([]);
  const [connectorError, setConnectorError] = useState('');
  const locationOptions = location.map(loc => ({
    value: loc.id,
    label: loc.name,
  }));
  const [connector1, setConnector1] = useState(null);
  const [connector2, setConnector2] = useState(null);

  
  const pageSizeOptions = [
    { value: 10, label: '10' },
    { value: 50, label: '50' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
    { value: 1000, label: '1000' },
    { value: 2000, label: '2000' }
   
    
  ];
  
  const formatPercentageForDisplay = (value) => {
    return value ? (value * 100).toFixed(2) : '';
  };

  // original Code
  // const handleLocationChange = (selectedOption) => {
  //   setSelectedLocations(selectedOption.name);  // Update selected location
  //   setSearchParams({
  //      ...searchParams,
  //     // location: selectedOption ? selectedOption.label : '',
  //     location: selectedOption.name,  // Update searchParams with location
      
  //   });
    
  // };
  // original Code
    
  //changes done by Pratiksha for Select all locations
  const handleLocationChange = (selectedOption) => {
    if (!selectedOption) {
        // Handle "Select All" case
        setSelectedLocations(null); // Reset or set to null
        setSearchParams({
            ...searchParams,
            location: '', // Clear location in searchParams
        });
    } else {
        // Handle specific location case
        setSelectedLocations(selectedOption.name); // Update selected location
        setSearchParams({
            ...searchParams,
            location: selectedOption.name, // Update searchParams with location
        });
    }
};

  // Changes done by PG for state wise Location 
  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);  // Update state selection
    setSearchParams({
       ...searchParams,
      // state: selectedOption ? selectedOption.label : '',
      state: selectedOption,  // Update searchParams with selected state
      
    });
    handleStateLocationChange(selectedOption);  // Fetch locations for the selected state
  };

  // Original Code by Bharat
  // const handleStateChange = (e) => {
  //   const value = e.target.value;
  //   console.log("Selected State:", value);  // Check if state is being selected
  //   setSearchParams((prev) => ({
  //     ...prev,
  //     state: value,  // Update state in searchParams
  //   }));
  // };

  const handleTempChange = (e) => {
    // Limit input to a number with max two decimal places
    let input = e.target.value;
    const regex = /^\d*\.?\d{0,2}$/; // Allows up to 2 decimal places

    if (regex.test(input)) {
        setTempPercentage(input);
    }
};

const handleCheckboxFilter = (e, key) => {
  setSearchParams({
    ...searchParams,
    [key]: e.target.checked ? e.target.value : "",
    
  });
};

// const handleCheckboxFilter = (e, key) => {
//   setSearchParams((prevParams) => ({
//     ...prevParams,
//     [key]: e.target.checked ? e.target.value : "",  // Add/remove filter
//   }));
// };


// Changes for Category wise product in dropdown Pratiksha
const handleCategoryChange = async (category) => {
  setSelectedProducts([]);   // Reset products on change
  setSelectedcategory(category);

  if (category) {
    try {
      console.log("Selected Category:", category);

      // Fetch products from the backend
      const response = await api.get(`/api/products-by-category/${category}/`);

      // Check response status
      if (response.status === 200) {
        console.log("Fetched products:", response.data);
        setProducts(response.data);  // Update product list
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching products:", error.response?.data || error.message);
    }
  } else {
    setProducts([]);  // Clear products if no category selected
  }
};


// Changes done by Pratiksha for state wise Location in dropdown
const handleStateLocationChange = async (state) => {
  setSelectedLocations("");  // Reset locations on state change
  setSelectedState(state);
  

  if (state) {
    try {
      
      // Fetch locations from the backend
      const response = await api.get(`/api/states-by-location/${state}/`);
      console.log("API Response:", response.data);  // Debug API response

      // Check response status
      if (response.status === 200 && Array.isArray(response.data)) {
        console.log("Fetched Locations:", response.data);
        setLocations(response.data);  // Update locations list
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
        
      }
    } catch (error) {
      console.error("Error fetching products:", error.response?.data || error.message);
      setLocations([]);  // Clear locations on error
    }
  } else {
    setLocations([]);  // Clear products if no state selected
  }
};


const handleBlur = (index) => {
    // Update lead's external_payout_percentage only after input is finalized
    const value = parseFloat(tempPercentage) / 100;
    const updatedLeads = [...editableData];
    updatedLeads[index].external_payout_percentage = value;
    setEditableData(updatedLeads);
    setTempPercentage(value * 100); // Reset temp to formatted display
};
  const handleInputChangeforConnector = (e, index) => {
    const { name, value } = e.target;
    const updatedData = [...editableData];
    updatedData[index][name] = value;
  
    // Check if connectors are the same
    const { connector_1, connector_2 } = updatedData[index];
  
    if (connector_1 && connector_2 && connector_1 === connector_2) {
      // If connectors are the same, set an error and clear connector_2
      setConnectorError(`Connector 1 and Connector 2 cannot be the same for Customer Name: ${updatedData[index].customer_name}`);
      alert(`Connector 1 and Connector 2 cannot be the same for Customer Name: ${updatedData[index].customer_name}`);
  
      // Clear connector_2
      updatedData[index].connector_2 = '';
    } else {
      // Clear the error message if connectors are not the same
      setConnectorError('');
    }
  
    // Update the state with the modified data
    setEditableData(updatedData);
    const leadId = updatedData[index].sr_no;
  setEditedLeads(prev => new Set(prev).add(leadId));
  };
  
  const handleSearch = async (page = 1) => {
    if (!selectedMonth) {
      setError('Please select a billing month');
      return;
    }
  
    try {
      setLoading(true);
      setError('');
      console.log("Sending Params:", searchParams);
      const response = await api.get('/api/multiple-lead-map/', {
        params: { 
          billing_month: selectedMonth, 
          page: page,
          page_size: pageSize,
          lender: searchParams.lender, //added lender by PG
          state: searchParams.state,   //added state by PG
          location: searchParams.location,  // added location by PG
          // state: selectedState,
          // FPR: searchParams.fpr,
          ...searchParams,
        },
      });
      console.log("API Response:", response.data); 
      if (response.data.status === 'success') {
        setEditableData(response.data.data || []);
        setTotalRecords(response.data.total_records);
        setCurrentPage(page);
        setSuccess(`Found ${response.data.total_records} leads`);
      } else {
        setEditableData([]);
        setError('No leads found');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while searching leads');
    } finally {
      setLoading(false);
    }
  };
 

  const handleInputChangeforFilter = (e) => {
    const target = e.target || e;
    const value = target.value;
  
    setSearchParams((prevParams) => ({
      ...prevParams,
      [target.name]: value,
      // product: [], 
      state: target.name === 'state' ? value : prevParams.state, // Preserve state value
      location: target.name === 'location' ? value : prevParams.location,
      product: target.name === 'product_category' ? [] : prevParams.product, // Reset products only if category changes
      // lender: target.name === 'lender' ? [] : prevParams.lender, 
      // state: state,  // Update state in search params
      // location: '',  // Reset location when state changes
      // FPR: FPR,
      // FPR: '',
    }));

  };

  // console.log("Entered State :", state);
  // const fetchProducts = async (category) => {
  //   try {
  //     const response = await axios.get(`/api/products/?product_category=${category}`);
  //     setProducts(response.data);
  //   } catch (error) {
  //     console.error('Error fetching products:', error);
  //   }
  // };

  // const handleInputChangeforFilter = (key, value) => {
  //   setSearchParams((prevParams) => ({
  //     ...prevParams,
  //     [key]: value, 
  //   }));
  // };
  
  const formatToFourDecimals = (e, index) => {
    const value = e.target.value;
  
    // Check if the value is numeric
    if (!isNaN(value) && value.trim() !== "") {
      // Format to 4 decimal places
      const formattedValue = parseFloat(value).toFixed(4);
  
      // Update the value in your state
      const updatedLeads = [...leads]; // Assuming `leads` is your state
      updatedLeads[index].external_payout_percentage = formattedValue;
      setLeads(updatedLeads); // Update state
    }
  };
  
  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  
  //   // Allow only numbers, decimal point, and empty input
  //   if (/^\d*\.?\d*$/.test(value)) {
  //     const updatedLeads = [...leads];
  //     updatedLeads[index][name] = value;
  //     setLeads(updatedLeads);
  //   }
  // };
  
const handleInputChange = (e, index) => {   //Changes done by Pratiksha
  const { name, value } = e.target;
  const updatedData = [...editableData];
  updatedData[index][name] = value;


  // Update the state with the new value
  setEditableData((prevData) =>
    prevData.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    )
  );

  // Allow only numbers, decimal point, and empty input
  // if (/^\d*\.?\d*$/.test(value)) {
  //   const updatedData = [...editableData];
  //   updatedData[index][name] = value;

  // Get net loan and total_po_received_percentage for calculations
  // const netLoan = updatedData[index].net_loan;
  // let totalPOReceivedPercentage = parseFloat(updatedData[index].total_po_received_percentage) * 100;

  //changes done by Pratiksha formula for Total_po_Received_percentage
    const netLoan = parseFloat(updatedData[index].net_loan) || 0; // Default to 0 if undefined
    let totalPOReceivedPercentage = 
      (parseFloat(updatedData[index].base_po_received_percentage || 0) +
       parseFloat(updatedData[index].addnl_payout_percentage || 0) +
       parseFloat(updatedData[index].contest_po_percentage || 0) -
       parseFloat(updatedData[index].subvention_percentage || 0)) * 100;


  // Update total_po_received_percentage in the current record
  updatedData[index].total_po_received_percentage = totalPOReceivedPercentage.toFixed(2); //Pratiksha

  // Calculate net_margin based on totalPOReceivedPercentage and external_payout_percentage  changes done by Pratiksha
  const externalPayoutPercentage = parseFloat(updatedData[index].external_payout_percentage || 0);
  updatedData[index].net_margin = (totalPOReceivedPercentage - externalPayoutPercentage).toFixed(2);


  // Calculate External Payout Amount
  // if (name === 'external_payout_percentage') {
  //   const externalPayoutPercentage = parseFloat(value);
  //   const externalPayoutAmount = (netLoan * (externalPayoutPercentage / 100)).toFixed(2);
  //   updatedData[index].external_payout_amt = externalPayoutAmount;
  
  //   totalPOReceivedPercentage = parseFloat(totalPOReceivedPercentage).toFixed(2);
  //   console.log('total', totalPOReceivedPercentage);
  //   console.log('exter', externalPayoutPercentage);
    
  //   const netMargin = (totalPOReceivedPercentage - externalPayoutPercentage).toFixed(2);
  //   console.log('net', netMargin);
  
  //   updatedData[index].net_margin = netMargin;
  // }

  //Calculate External Payout Amount Comma Seperater
  if (name === 'external_payout_percentage') {     // changes done by sumedh for externalpayout amount with comma start
    const externalPayoutPercentage = parseFloat(value);
    const externalPayoutAmount = (netLoan * (externalPayoutPercentage / 100)).toFixed(0);
    

    const formatIndianNumber = (number) => {
      const numberStr = number.toString(); // Convert the number to a string
      const [integerPart, decimalPart] = numberStr.split('.'); // Split into integer and decimal parts
      const lastThreeDigits = integerPart.slice(-3); // Get the last three digits
      const otherDigits = integerPart.slice(0, -3); // Get the remaining digits
  
      const formattedInteger = otherDigits
          ? otherDigits.replace(/\B(?=(\d{0})+(?!\d))/g, ',') + ',' + lastThreeDigits
          : lastThreeDigits;
  
      // Use backticks for template literals
      return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  };
  
  // Example usage
  const formattedPayoutAmount = formatIndianNumber(externalPayoutAmount);
  
  console.log("Formatted Value:", formattedPayoutAmount);
  
  // Update your data
  updatedData[index].external_payout_amt = formattedPayoutAmount;
  console.log(updatedData); 


  totalPOReceivedPercentage = parseFloat(totalPOReceivedPercentage).toFixed(2);
    console.log('total', totalPOReceivedPercentage);
    //console.log('exter', externalPayoutPercentage);
    
    const netMargin = (totalPOReceivedPercentage - externalPayoutPercentage).toFixed(2);
   // console.log('net', netMargin);
  
    updatedData[index].net_margin = netMargin;
  } 

// changes done by sumedh for externalpayout amount with comma seperation end 
  

  

  // Allow manual editing of External Payout Amount
  if (name === 'external_payout_amt') {
    updatedData[index].external_payout_amt = value;
  }
  
  const product_category = updatedData[index].product_category;
if (name === 'external_payout_amt' && product_category === 'Credit Card') {
  
  const externalPayoutAmount = parseFloat(value);
  
  let totalPOReceivedAmount = updatedData[index].base_po_received_amt ? parseFloat(updatedData[index].base_po_received_amt).toFixed(2) : '';
  const netAmount = (totalPOReceivedAmount - externalPayoutAmount).toFixed(0)
  updatedData[index].net_amount = netAmount;
  

}

  // Update Source field and related fields
  if (name === 'source') {
    const source = value;
    updatedData[index].connector_1 = source === 'Internal' ? '' : updatedData[index].connector_1;
    updatedData[index].connector_2 = source === 'Internal' ? '' : updatedData[index].connector_2;
    updatedData[index].tme = source === 'Internal' ? '' : updatedData[index].tme;
    updatedData[index].tl = source === 'Internal' ? '' : updatedData[index].tl;
    updatedData[index].tm = source === 'Internal' ? '' : updatedData[index].tm;
    updatedData[index].bm = source === 'Internal' ? '' : updatedData[index].bm;
    updatedData[index].rsm = source === 'Internal' ? '' : updatedData[index].rsm;
    updatedData[index].zsm = source === 'Internal' ? '' : updatedData[index].zsm;
  }

  setEditableData(updatedData);

  // Track which lead was edited
  const leadId = updatedData[index].sr_no;
  setEditedLeads(prev => new Set(prev).add(leadId));

};
const stateOptions = [
    { value: 'Delhi', label: 'Delhi' },
    // { value: 'Mumbai', label: 'Mumbai' },
    { value: 'Punjab', label: 'Punjab' },
    { value: 'Karnataka', label: 'Karnataka' },
    { value: 'Kerala', label: 'Kerala' },
    // { value: 'Chattisgarh', label: 'Chattisgarh' },
    { value: 'Maharashtra', label: 'Maharashtra' },
    { value: 'Telangana', label: 'Telangana' },
    { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
    { value: 'Assam', label: 'Assam' },
    { value: 'Tamil Nadu', label: 'Tamil Nadu' },
    { value: 'Gujarat', label: 'Gujarat' },
    { value: 'Goa', label: 'Goa' },
    { value: 'Rajasthan', label: 'Rajasthan' },
    { value: 'Haryana', label: 'Haryana' },
    { value: 'Jharkhand', label: 'Jharkhand' },
    { value: 'West Bengal', label: 'West Bengal' },
    { value: 'Chandigarh', label: 'Chandigarh' },
    { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
    { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
    { value: 'Bihar', label: 'Bihar' },
    { value: 'Puducherry', label: 'Puducherry' },
    { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
    { value: 'Uttarakhand', label: 'Uttarakhand' },
    { value: 'Orissa', label: 'Orissa' },
    { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'Dadra and Nagar Haveli and Daman and Diu' },
    { value: 'Chhattisgarh', label: 'Chhattisgarh' }, 
    { value: 'Sikkim', label: 'Sikkim' },
    { value: 'Odisha', label: 'Odisha' },
    
  ];

  // Sort the states by the label property
const sortedStateOptions = stateOptions.sort((a, b) =>
  a.label.localeCompare(b.label)
);

// console.log(sortedStateOptions);

const userData = JSON.parse(localStorage.getItem('user')) || {};
// const userType = parseInt(localStorage.getItem('user_type'));
const userType = parseInt(userData.user_type || ""); 
// console.log(userType);


const isDisabled = (lead) => lead.is_mapped && lead.is_submitted;
const handleBatchSubmit = async () => {
  if (editedLeads.size === 0) {
    setError('No changes to submit');
    return;
  }

  try {
    setLoading(true);
    
    // Filter only the edited leads
    const updatedLeads = editableData.filter(lead => 
      editedLeads.has(lead.sr_no)
    ).map(lead => ({
      id: lead.id,
      // tl: lead.tl,
      tm: lead.tm,
      // tme: lead.tme,
      bm: lead.bm,
      rsm: lead.rsm,
      zsm: lead.zsm,
      external_payout_percentage: lead.external_payout_percentage,
      external_payout_amt: lead.external_payout_amt,
      net_margin: lead.net_margin,
    
      // net_amount: lead.net_amount,
      remarks1: lead.remarks1,
      connector_1_tme: lead.connector_1_tme,
      connector_2_tl: lead.connector_2_tl,
      external_payout_percentage: lead.external_payout_percentage,
      external_payout_amt: lead.external_payout_amt,
      status: lead.status,
      source:lead.source,
      fpr: lead.fpr,//added fpr by Pratiksha
      // tl: lead.tl,
      po_processed_by: lead.po_processed_by, //added Pratiksha
      po_processed_date: lead.po_processed_date,//added by Pratiksha
      remarks2: lead.remarks2,
      pdd_status: lead.pdd_status,
      otc_status: lead.otc_status,
      advance_paid: lead.advance_paid,
      connector_1_percentage: lead.connector_1_percentage,
      connector_2_percentage: lead.connector_2_percentage,
      invoice_status: lead.invoice_status,

     

    }));
    console.log("lead:", updatedLeads);
    const response = await api.post('/api/update-multiple-leads/', {
      leads: updatedLeads
    });
     console.log("API:", response);
    if (response.data.success) {
      setSuccess('All changes saved successfully');
      setEditedLeads(new Set());
      console.log("Set", new Set());
      
      setTimeout(() => {
        setSuccess(''); 
        handleSearch(currentPage);
      }, 2000);
    } else {
      setError('Failed to update some leads');
    }
    
  } catch (error) {
    setError('An error occurred while updating leads');
  } finally {
    setLoading(false);
  }
};
  const handleSubmit = async (leadId, index) => {
    const lead = editableData[index];
    try {
      const response = await api.put(`/api/update-lead/${leadId}/`, lead);
      if (response.data.success) {
        setSuccess('Lead updated successfully');
        setError('');
      } else {
        setError('Failed to update lead');
      }
    } catch (error) {
      setError('An error occurred while updating lead');
    }
  };
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const billingMonthsResponse = await api.get('/api/billing-months/');
        setBillingMonths(billingMonthsResponse.data);

        const lendersResponse = await api.get('/api/lenders/');
        setLenders(lendersResponse.data);

        const productsResponse = await api.get('/api/products/');
        setProducts(productsResponse.data);

        const locationResponse = await api.get('/api/locations/');
        setLocation(locationResponse.data);

        const ConnectorResponse = await api.get('/api/connectors/');
        setConnectors(ConnectorResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  
  // Check for negative net_margin in editableData changes by Pratiksha
  useEffect(() => {
    const hasNegativeMargin = editableData.some(
      (lead) => parseFloat(lead.net_margin) < 0
    );

    if (hasNegativeMargin ) {
      setError('Net Margin should not be in Negative');
    } else {
      setError('');
    }
  }, [editableData]); // Runs whenever `editableData` changes

  const filteredData = searchParams.negative_margin === "yes" 
  ? editableData.filter((lead) => parseFloat(lead.net_margin) < 0) 
  : editableData;


  const renderPagination = () => {
    const totalPages = Math.ceil(totalRecords / pageSize);

      
    return (
      <Row className="mt-3 align-items-center">
        <Col md={2}>
          <Select
            options={pageSizeOptions}
            value={pageSizeOptions.find(opt => opt.value === pageSize)}
            onChange={(option) => {
              setPageSize(option.value);
              handleSearch(1);
            }}
            isDisabled={loading}
          />
        </Col>
        <Col md={8}>
        
        <div className="pagination-container" style={{ overflowX: 'auto' }}></div>
          {/* <Pagination className="justify-content-center"> */}
          <Pagination className="justify-content-center flex-wrap">
            <Pagination.First onClick={() => handleSearch(1)} disabled={currentPage === 1 || loading} />
            <Pagination.Prev onClick={() => handleSearch(currentPage - 1)} disabled={currentPage === 1 || loading} />
            
            {[...Array(totalPages)].map((_, idx) => (
              <Pagination.Item
                key={idx + 1}
                active={idx + 1 === currentPage}
                onClick={() => handleSearch(idx + 1)}
                disabled={loading}
              >
                {idx + 1}
              </Pagination.Item>
            ))}
            
            <Pagination.Next onClick={() => handleSearch(currentPage + 1)} disabled={currentPage === totalPages || loading} />
            <Pagination.Last onClick={() => handleSearch(totalPages)} disabled={currentPage === totalPages || loading} />
          </Pagination>
          
        </Col>
        <Col md={2} className="text-end">
          <span>Total: {totalRecords}</span>
        </Col>
      </Row>
    );
  };

  // Download Excel for Searched Data
  const downloadExcel = () => {
    if (!editableData || editableData.length === 0 ) {
      alert('No data available for download.');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(editableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Search Results');
    XLSX.writeFile(workbook, 'Payout Mapping.xlsx');
  };
  
 
  return (
    <Container fluid>
      <h2 className="mb-4">Payout Mapping</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}

      <Form className="mb-4">
  <Row className="align-items-start">
    {/* First Row of Filters */}
    <Col md={3}>
     <Form.Group>
      <Form.Label><span style={{ color: 'red', fontsize: '1.2em'}}>*</span> Billing Month</Form.Label>
      <Form.Control
      as="select"
      value={selectedMonth}
      onChange={(e) => setSelectedMonth(e.target.value)}
    >
      <option value="">Select Billing Month</option>
      <option value="all">Select All</option>
      {billingMonths 
        // .sort((a, b) => {
        //   // Sort by year first, then by month
        //   if (a.year === b.year) {
        //     return a.month.localeCompare(b.month); // Assuming months are strings (e.g., 'January')
        //   }
        //   return a.year - b.year;
        // })

        .sort((a, b) => {
          // Define financial year sorting logic
          const getMonthValue = (month) => {
            // Adjust month values: April (4) becomes 1, March (3) becomes 12
            const monthOrder = {
              January: 10,
              February: 11,
              March: 12,
              April: 1,
              May: 2,
              June: 3,
              July: 4,
              August: 5,
              September: 6,
              October: 7,
              November: 8,
              December: 9,
            };
            return monthOrder[month];
          };
    
          const aMonthValue = getMonthValue(a.month);
          const bMonthValue = getMonthValue(b.month);
    
          if (a.year === b.year) {
            return aMonthValue - bMonthValue;
          }
          return a.year - b.year;
        })
      .map(month => (
        <option key={month.id} value={month.id}>{`${month.month} ${month.year}`}</option>
      ))}
    </Form.Control>
  </Form.Group>
</Col>

{/* main state code */}

{/* <Col md={3}>
  <Form.Group>
    <Form.Label>State</Form.Label>
    <Select
      options={stateOptions}
      value={stateOptions.find(opt => opt.value === searchParams.state)}  
      onChange={handleStateChange}
      isDisabled={!selectedMonth}
      isClearable
      placeholder="Search or select State..."
      noOptionsMessage={() => "No State found"}
    />
  </Form.Group>
</Col> */}
{/* main state code */}

<Col md={3}>
      <Form.Group>
        <Form.Label>Select State</Form.Label>
        <Form.Select
          name="State"
          value={selectedState || ""}
          
          placeholder="Select State"
          disabled={!selectedMonth}
          onChange={(e) => handleStateChange(e.target.value)}
          
        >
          <option value="">Select All </option>
          {stateOptions.map((state) => (
            <option key={state.value} value={state.value}>
              {state.label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      </Col>

 

      <Col md={3}>
      <Form.Group>
        <Form.Label>Select Location</Form.Label>
        <Form.Select
          name="Location"
          value={selectedLocations || ""}
          disabled={!selectedMonth}
          // disabled={!selectedState || selectedLocations.length === 0}  // Disable until state and locations exist
          // onChange={(e) => handleLocationChange(e.target.value)}
          onChange={(e) => {
            const selectedLocation = locations.find(loc => loc.name === e.target.value);  // Find the full object
            handleLocationChange(selectedLocation);  // Pass full location object
          }}
        >
          <option value="">Select All </option>
          {locations
             .filter((location) => location.state === selectedState) // Filter by selected state
             .sort((a, b) => a.name.localeCompare(b.name)) // Sort by name in ascending order
          .map((location) => (
            <option key={location.id} value={location.name}>
              {location.name}
            </option>
          ))}
        </Form.Select>        
      </Form.Group>
    </Col>


    
    {/* <Col md={3}>
      <Form.Group>
        <Form.Label>Location</Form.Label>
        <Select
          options={locationOptions}
          value={locationOptions.find(opt => opt.value === searchParams.location)}
          onChange={handleLocationChange}
          isDisabled={!selectedMonth}
          isClearable
          placeholder="Search or select location..."
          noOptionsMessage={() => "No locations found"}
        />
      </Form.Group>
    </Col> */}


    <Col md={3}>
      <Form.Group>
        <Form.Label>FPR</Form.Label>
        <Form.Select
          // type="text"
          name="fpr"
          placeholder="FPR"
          value={searchParams.fpr || ""}
          disabled={!selectedMonth}

    
          onChange={handleInputChangeforFilter}
       >
          <option value="">Select All</option>
          <option value="ANIL">ANIL</option>
          <option value="RAJEEV">RAJEEV</option>
          <option value="SYED">SYED</option>
          {/* disabled={!selectedMonth} */}
          </Form.Select>
      </Form.Group>
    </Col>
    

    <Col md={3}>
      <Form.Group>
        <Form.Label>Customer Name</Form.Label>
        <Form.Control
          type="text"
          name="customer_name"
          placeholder="Customer Name"
          value={searchParams.customer_name}
          onChange={handleInputChangeforFilter}
          disabled={!selectedMonth}
        />
      </Form.Group>
    </Col>


    <Col md={3}>
      <Form.Group>
        <Form.Label>LOS No</Form.Label>
        <Form.Control
          type="text"
          name="los_no"
          placeholder="LOS No"
          value={searchParams.los_no}
          onChange={handleInputChangeforFilter}
          disabled={!selectedMonth}
        />
      </Form.Group>
    </Col>
    <Col md={3}>
  <Form.Group>
    <Form.Label>Select Source</Form.Label>
    <Form.Select
      name="source"
      value={searchParams.source}
      placeholder="Select Source"
      disabled={!selectedMonth}
      onChange={handleInputChangeforFilter}
    >
      <option value="">Select All </option>
      <option value="Internal">Internal</option>
      <option value="External">External</option>
      <option value="Digital">Digital</option>
    </Form.Select>
  </Form.Group>
</Col>
<Col md={3}>
  <Form.Group>
    <Form.Label>Payout Sharing</Form.Label>
    <Form.Select
      name="payout_sharing"
      value={searchParams.payout_sharing}
      placeholder="Select Payout Sharing"
      disabled={!selectedMonth}
      onChange={handleInputChangeforFilter}
    >
      <option value="">Select All </option>
      <option value="yes">Yes</option>
      <option value="no">No</option>

    </Form.Select>
  </Form.Group>
</Col>
  </Row>

  <Row className="mt-3 align-items-start">
    {/* Second Row of Filters */}
    <Col md={3}>
  <Form.Group>
    <Form.Label>Select Category</Form.Label>
    <Form.Select
      name="product_category"
      value={searchParams.product_category}
      placeholder="Select Category"
      disabled={!selectedMonth}
      onChange={(e) => {
        const selectedCategory = e.target.value;

        // Update filter state
        handleInputChangeforFilter(e);

        // Fetch products based on selected category
         handleCategoryChange(selectedCategory);
        
      }}
    >    
      <option value="">Select All </option>
      <option value="Unsecured">Unsecured</option>
      <option value="Secured">Secured</option>
      <option value="Credit Card">Credit Card</option> 
    </Form.Select>
  </Form.Group>
</Col>

    {/* <Col md={3}>
      <Form.Group>s
        <Form.Label>Product</Form.Label>
        <Form.Control
          as="select"
          name="product"
          value={searchParams.product}
          onChange={handleInputChangeforFilter}
          disabled={!selectedMonth}
        >
          <option value="">Select Product</option>
          {products.map(product => (
            <option key={product.id} value={product.product_code}>{product.product_code}</option>
          ))}
        </Form.Control>
      </Form.Group>
    </Col> */}



    {/* <Col md={3}>
    <Form.Group>
    <Form.Label>Lender</Form.Label>
    <Form.Control
      as="select"
      name="lender"
      value={searchParams.lender}
      onChange={handleInputChangeforFilter}
      disabled={!selectedMonth}
    >   
        <option value="">Select Lender</option>
        {lenders.map(lender => (
          <option value={lender.name}>{lender.name}</option>

        ))}
     
    </Form.Control>
  </Form.Group>
    </Col> */}


{/* Multi Select option for Product*/}
  <Col md={3}>
  <Form.Group>
  <Form.Label>Product</Form.Label>
  <Select
    isMulti
    name="product"
    value={searchParams.product?.map((productCode) => ({
      value: productCode,
      label: productCode,
    })) || []}
    options={products
      .sort((a, b) => a.product_code.localeCompare(b.product_code)) // Sort by product code
      .map((product) => ({
        value: product.product_code,
        label: product.product_code,
      }))}
    onChange={(selectedOptions) => {
      const selectedValues = selectedOptions?.map((option) => option.value) || [];
      setSearchParams((prevParams) => ({
        ...prevParams,
        product: selectedValues,
      }));
    }}
    isDisabled={!selectedMonth || !searchParams.product_category}
    placeholder="Select Products"
  />
</Form.Group>

</Col>

{/* Multi Select option for Lender */}
<Col md={3}>
  <Form.Group>
    <Form.Label>Lender</Form.Label>
    <Select
      isMulti
      name="lender"
      value={searchParams.lender.map((selectedName) => ({
        value: selectedName,
        label: selectedName,
      }))}
      options={lenders.map((lender) => ({
        value: lender.name,
        label: lender.name,
      }))}
      onChange={(selectedOptions) => {
        const selectedValues = selectedOptions?.map((option) => option.value) || [];
        setSearchParams((prevParams) => ({
          ...prevParams,
          lender: selectedValues,
        }));
      }}
      
      isDisabled={!selectedMonth}
      placeholder="Select Lenders"
      
    />
  </Form.Group>
</Col>


    <Col md={3}>
      <Form.Group>
        <Form.Label>Select Connector</Form.Label>
        <Form.Control
          as="select"
          name="connector"
          value={searchParams.connector}
          onChange={handleInputChangeforFilter}
          disabled={!selectedMonth}
        >
           <option value="">Select All </option>
  {connectors.map(connector => (
    <option key={connector.id} value={connector.name}>{connector.name}</option>
  ))}
        </Form.Control>
      </Form.Group>
    </Col>

  <Row className="mt-3 align-items-start">
    {/* PDD/OTC Status */}
  <Col md={3}>
  <Form.Group>
  <Form.Label>PDD Pending Status</Form.Label>
  <div>
    <Form.Check
      type="checkbox"
      label="Pending"    
      name="pdd_status_pending"
      value="Pending"
      checked={searchParams.pdd_status === "Pending"}
      disabled={!selectedMonth}
      onChange={(e) => handleCheckboxFilter(e, "pdd_status", "pending")}
    />
    

    </div>
</Form.Group>

  </Col>

  {/* <Row className="mt-3 align-items-start"> */}
    {/* PDD/OTC Status */}
  <Col md={3}>
  <Form.Group>
  <Form.Label>OTC Pending Status</Form.Label>
  <div>
    <Form.Check
      type="checkbox"
      label="Pending"    
      name="otc_status_pending"
      value="Pending"
      checked={searchParams.otc_status === "Pending"}
      disabled={!selectedMonth}
      onChange={(e) => handleCheckboxFilter(e, "otc_status", "pending")}
    />
    </div>
</Form.Group>

  </Col>

{/* Invoice Raised Status */}
<Col md={3}>
    <Form.Group>
      <Form.Label>Invoice Raised Pending Status</Form.Label>
      <Form.Check
        type="checkbox"
        label="No"
        name="invoice_raised_status"
        value="No"
        checked={searchParams.invoice_raised_status === "No"}
        disabled={!selectedMonth}
        onChange={(e) => handleCheckboxFilter(e, "invoice_raised_status")}
      />
    </Form.Group>
  </Col>

<Row className="mt-3 align-items-start"></Row>
  {/* Negative Margin */}
  <Col md={3}>
    <Form.Group>
      <Form.Label>Negative Margin</Form.Label>
      <Form.Check
        type="checkbox"
        label="Yes"
        name="negative_margin"
        value="yes"
        checked={searchParams.negative_margin === "yes"}
        disabled={!selectedMonth}
        onChange={(e) => handleCheckboxFilter(e, "negative_margin")}
      />
    </Form.Group>
  </Col>


    <Col md={3}>
    <Form.Group>
      <Form.Label>Submitted Leads</Form.Label>
      <Form.Check
        type="checkbox"
        label="True"
        name="is_submitted"
        value="True"
        checked={searchParams.is_submitted === "True"}
        disabled={!selectedMonth}
        onChange={(e) => handleCheckboxFilter(e, "is_submitted")}
      />
    </Form.Group>
    </Col>
    </Row>

    <Col md={3}>
      <Button 
        variant="primary" 
        onClick={handleSearch} 
        disabled={!selectedMonth}
        className="w-100 mt-4"
      >
        <FaSearch className="me-2" />
        Search
      </Button>
    </Col>
  </Row>
</Form>

{/* Other UI elements */}
<div className="d-flex justify-content-end mb-3">
        <Button variant="success" onClick={downloadExcel}>
          Download as Excel
        </Button>
      </div>

        <div className="d-flex justify-content-between mb-3">
        <div>
          {editedLeads.size > 0 && (
            <span className="text-muted">
              {editedLeads.size} record(s) modified
            </span>
          )}
        </div>
        <Button
          variant="primary"
          onClick={handleBatchSubmit}
          disabled={editedLeads.size === 0 || loading}
        >
          <FaSave className="me-2" />
          Save All Changes
        </Button>
      </div>

      {/* Error Message */}
    {error && (
      <Alert variant="danger" className="mb-3">
        {error}
      </Alert>
    )}

      
         <h5>Loan Leads</h5> 
        
        {/* <Table responsive>
        <thead style={{ backgroundColor: '#f8f9fa' }}>
        <tr> */}
       <div style={{ maxHeight: "600px", overflowY: "auto", border: "1px solid #ddd" }}>
       <table style={{ width: "100%", borderCollapse: "collapse" }}> 
      {/* <Table responsive bordered> */}
        <thead className="sticky-header"> 
          <tr>
           
             <th style={{ border: '1px solid black', padding: '15px', color: 'White', backgroundColor: 'green'}}>Bank</th>
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Product</th>
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Cust Name</th>
              
              { selectedCategory !== "Credit Card" &&  <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Net Loan</th>}
              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>acing Month</th>}
  
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>LOS No</th>
              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>disb_date</th>}
              { selectedCategory !== "Credit Card" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Total PO Rec %</th>}
              { selectedCategory !== "Credit Card" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Base PO Rece %</th>}
              { selectedCategory !== "Credit Card" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Addi PO % </th>}
              { selectedCategory !== "Credit Card" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Contest PO % </th>}
              { selectedCategory !== "Credit Card" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Subv%</th>}
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Source</th>
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Con 1 tme</th>
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Con 2 tl</th>
              { selectedCategory !== "Credit Card" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Ext payout %</th>}
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Ext Payout Amount</th>
              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>case subvention amt</th>}
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Net Margin</th>
              
              {/* <th>Net Payout Diff%</th>  */}
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Status</th>
              { selectedCategory !== "Credit Card" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>PDD Status</th>}
              { selectedCategory !== "Credit Card" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>OTC Status</th>}
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Advance paid</th>
              {/* <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>po_processed_date</th> */}
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>po_processed_by</th>
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>po_processed_date</th>
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Invoice Status</th>
              
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Base PO Rec Amt</th>
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Add PO Amt</th>
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Contest PO Amt</th>
              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>subvention amt</th>}
              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>other deduction </th>}
              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>net po</th>}
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Remark 1</th>
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Remark 2</th>
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>fpr</th>
              { selectedCategory !== "Credit Card" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Con 1 %</th>}
              { selectedCategory !== "Credit Card" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>Con 2 %</th>}
              {/* <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>TME</th> */}
              {/* <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>TL</th> */}
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>TM</th>
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>BM</th>
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>RSM</th>
              <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>ZSM</th>
              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>joining fees</th>}
              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>annual fees</th>}
              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <th style={{ border: '1px solid black', padding: '10px', color: 'White', backgroundColor: 'green' }}>card active status</th>}
              {/* <th>PDD/OTC Status</th> */}
              {/* <th>Advance paid</th> */}
              {/* <th>Invoice Status</th> */}
              {/* <th>Invoice Status</th> */}
          
          </tr>
        </thead>
        

          {/* Display Order Code */}
         <tbody>
          
          {editableData.map((lead, index) => (
            // <tr key={lead.sr_no} className={editedLeads.has(lead.id)? 'table-warning':''}
          //   <tr
          //   key={lead.sr_no}
          //   className={`${
          //     lead.invoice_status === 'No' ? 'table-danger' : '' &&
          //     lead.pdd_otc_status === 'Pending' ? 'table-danger' : ''
          //   } ${editedLeads.has(lead.id) ? 'table-warning' : ''}`}
          // >
           
           <tr
        //   key={lead.sr_no}
        //   className={`${lead.invoice_status === 'No' || lead.pdd_status === 'Pending' ? 'table-danger' : ''} ${
        //     editedLeads.has(lead.id) ? 'table-warning' : ''
        //   }`}
        // >  
        
         
       key={lead.sr_no}
        className={`${lead.invoice_status === 'No' || lead.pdd_status === 'Pending' || lead.otc_status === 'Pending' ? 'table-danger' : ''} 
              ${editedLeads.has(lead.id) ? 'table-warning' : ''}`}
          > 

              <td style={{ border: '1px solid black', padding: '10px' }}>{lead.bank}</td>
              <td style={{ border: '1px solid black', padding: '10px' }}>{lead.product_type}</td>
              <td style={{ border: '1px solid black', padding: '10px' }}>{lead.customer_name}</td>
              
              { selectedCategory !== "Credit Card" && <td style={{ border: '1px solid black', padding: '10px' }}>{lead.net_loan}</td>}
              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <td style={{ border: '1px solid black', padding: '10px' }}>{lead.acing_month}</td>}
              <td style={{ border: '1px solid black', padding: '10px' }}>{lead.loan_no}</td>
              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <td style={{ border: '1px solid black', padding: '10px' }}>{lead.disb_date}</td>}
              {/* <td style={{ border: '1px solid black', padding: '10px' }}>{(lead.total_po_received_percentage * 100).toFixed(2)}%</td> */}
              { selectedCategory !== "Credit Card" && <td style={{ border: '1px solid black', padding: '10px' }}>
    {(
      (lead.base_po_received_percentage +
        lead.addnl_payout_percentage +
        lead.contest_po_percentage -
        lead.subvention_percentage) *
      100
    ).toFixed(2)}% {/* Calculated value */}
  </td>}
  { selectedCategory !== "Credit Card" && <td style={{ border: '1px solid black', padding: '10px' }}>{(lead.base_po_received_percentage * 100).toFixed(2)}%</td>}
  { selectedCategory !== "Credit Card" && <td style={{ border: '1px solid black', padding: '10px' }}>{(lead.addnl_payout_percentage * 100).toFixed(3)}%</td>}
  { selectedCategory !== "Credit Card" && <td style={{ border: '1px solid black', padding: '10px' }}>{(lead.contest_po_percentage * 100).toFixed(3)}%</td>}
  { selectedCategory !== "Credit Card" && <td style={{ border: '1px solid black', padding: '10px' }}>{(lead.subvention_percentage * 100).toFixed(3)}%</td>}
              <td style={{ border: '1px solid black', padding: '10px' }}>
  <Form.Control
    as="select"
    name="source"
    value={lead.source || "External"}  
    onChange={(e) => handleInputChange(e, index)}
    disabled={isDisabled(lead)}
    style={{ width: '80px' }}
  >
    <option value="">Select Source</option>
    <option value="Internal">Internal</option>
    <option value="External">External</option>
    <option value="Digital">Digital</option>
  </Form.Control>
</td>

              <td style={{ border: '1px solid black', padding: '10px' }}>
            <select
              name="connector_1_tme"
              value={lead.connector_1_tme || ''}
              onChange={(e) => handleInputChangeforConnector(e, index)}
              disabled={isDisabled(lead)  || lead.source == 'Digital' || (lead.source == 'Internal')}
              style={{ width: '90px'}} 
            >
              <option value="">Select Connector 1</option>
              {connectors.map((connector) => (
                <option key={connector.id} value={connector.name}>
                  {connector.name}
                </option>
              ))}
            </select>
          </td>
          <td style={{ border: '1px solid black', padding: '10px' }}>
            <select
              name="connector_2_tl"
              value={lead.connector_2_tl || ''}
              onChange={(e) => handleInputChangeforConnector(e, index)}
              disabled={isDisabled(lead)  || lead.source == 'Digital' || (lead.source == 'Internal')}
              style={{ width: '90px'}} 
            >
              <option value="">Select Connector 2</option>
              {connectors.map((connector) => (
                <option key={connector.id} value={connector.name}>
                  {connector.name}
                </option>
              ))}
            </select>
          </td>
          { selectedCategory !== "Credit Card" && <td style={{ border: '1px solid black', padding: '10px' }}>
          <div className="d-flex align-items-center">

{/* Original Code     */}
  {/* <Form.Control
    type="text"  //Change by PG Number to text
    // min="0"
    // max="10"
    // step="0.01"
     placeholder="0.0000"
    name="external_payout_percentage"
    // value={lead.external_payout_percentage === '0' ? "" : lead.external_payout_percentage}
    // value={editableData[index]?.external_payout_percentage || ""}
    onChange={(e) => handleInputChange(e, index)}
    disabled={isDisabled(lead)}
    
    style={{ width: '90px', textAlign: 'right' }}
  />
  <span>%</span> */}

  {/* Original Code     */}


  {/* Changes done by PG for Decimal Padding  */}
<Form.Control
  type="text"              //Change by PG Number to text
  placeholder="0.0000"
  name="external_payout_percentage"
  value={editableData[index]?.external_payout_percentage || ""}
  onChange={(e) => handleInputChange(e, index)}
  onBlur={(e) => {
    // Format the value to four decimal places on blur
    const value = parseFloat(e.target.value || 0).toFixed(4);
    handleInputChange({ target: { name: "external_payout_percentage", value } }, index);
  }}
  disabled={isDisabled(lead)}
  style={{ width: '90px', textAlign: 'right' }}
/>
<span>%</span>

</div>
</td>}

    {/* Original Code     */}
              {/* <td>
                <Form.Control
                  type="text" //Change by PG Number to text
                  min="0"
                  name="external_payout_amt"
                  value={(parseFloat(lead.external_payout_amt).toFixed(0))}

                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead)}
                  style={{ width: '90px'}} 
                />
              </td> */}
    {/* Original Code     */}

              {/* changes done by sumedh for externalpayout amount with comma start */}
              <td style={{ border: '1px solid black', padding: '10px' }}> 
                <Form.Control
                  type="text" // Allows both letters and numbers
                  name="external_payout_amt"
                  value={lead.external_payout_amt || ''} // Ensure value is always a string
                  onChange={(e) => {
                    const alphanumericValue = e.target.value.replace(/[^a-zA-Z0-9 ]/g, ''); // Remove special characters if needed
                    handleInputChange({ target: { name: e.target.name, value: alphanumericValue } }, index); // Pass sanitized input
                  }}
                  disabled={isDisabled(lead)} // Handle input disabling logic
                  style={{ width: '90px', textAlign: 'left' }} // Adjust styling as needed
                />
              </td>
              {/* changes done by sumedh for externalpayout amount with comma end */}

              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <td style={{ border: '1px solid black', padding: '10px' }}>{lead.subvention_amt}</td>} 
{/*               
              <td>
  {lead.net_margin.length > 4  
    ? (parseFloat(lead.net_margin) * 100).toFixed(2) 
    : parseFloat(lead.net_margin).toFixed(2)  
  }%
  
</td> */}
{/* Render net_margin with conditional formatting */}
      <td 
        style={{ border: '1px solid black', padding: '10px',
          color: parseFloat(lead.net_margin) < 0 ? 'red' : 'inherit', // Red for negative values
          fontWeight: parseFloat(lead.net_margin) < 0 ? 'bold' : 'normal',
        }}
      >
        
           
      {selectedCategory === "Credit Card"
    ? `₹${parseFloat(editableData[index]?.net_margin || 0).toFixed(2)}` // Display as amount
    : `${(parseFloat(editableData[index]?.net_margin || 0) % 100).toFixed(2)}%` // Display as percentage
  }
      </td>

                        
            
              <td style={{ border: '1px solid black', padding: '10px' }}>
                <Form.Control
                  type="text"
                  name="status"
                  value={lead.status}
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead)}
                  style={{ width: '90px'}} 
                />
              </td>

              {/* <tr
  style={{
    backgroundColor: lead.pdd_otc_status === "Pending" ? "red" : "white",
    color: lead.pdd_otc_status === "Pending" ? "white" : "black", // Ensure text is visible on red background
  }}
> */}
              { selectedCategory !== "Credit Card" && <td style={{ border: '1px solid black', padding: '10px' }}>
                <Form.Select
                  type="Select"
                  name="pdd_status"
                  //  value={lead.pdd_otc_status || "Cleared"}
                  // value={lead.pdd_otc_status === null || lead.pdd_otc_status === undefined ? 'Pending' : lead.pdd_otc_status}
                   value={lead.pdd_status || (lead.pdd_status === null || lead.pdd_status === undefined ? 'Cleared' : lead.pdd_status)}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead)}
                 >               
                 <option value="">Select</option>
                 <option value="Pending">Pending</option>
                 <option value="Cleared">Cleared</option>
                 </Form.Select>
              </td>}
              
              { selectedCategory !== "Credit Card" && <td style={{ border: '1px solid black', padding: '10px' }}>
                <Form.Select
                  type="Select"
                  name="otc_status"
                  value={lead.otc_status || (lead.otc_status === null || lead.otc_status === undefined ? 'Cleared' : lead.otc_status)}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead)}
                 >               
                 <option value="">Select</option>
                 <option value="Pending">Pending</option>
                 <option value="Cleared">Cleared</option>
                 </Form.Select>
              </td>}

              <td style={{ border: '1px solid black', padding: '10px' }}>
                <Form.Control
                  type="text"
                  name="advance_paid"
                  value={lead.advance_paid}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead)}
                  
                />
              </td>
              {/* <td style={{ border: '1px solid black', padding: '10px' }}>{lead.po_processed_by}</td> */}

              <td style={{ border: '1px solid black', padding: '10px' }}>
                <Form.Control
                  type="text"
                  name="po_processed_by"
                  value={lead.po_processed_by}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                 
                />
              </td>

              {/* <td style={{ border: '1px solid black', padding: '10px' }}>{lead.po_processed_date}</td> */}
              {/* <td style={{ border: '1px solid black', padding: '10px' }}>
              <input
               type="date"
    value={lead.po_processed_date || ''}
    onChange={(e) => setLeads({ ...lead, po_processed_date: e.target.value })}
    style={{ padding: '5px', border: '1px solid black' }}
  />
</td> */}
              <td style={{ border: '1px solid black', padding: '10px' }}>
                <Form.Control
                  type="date"
                  name="po_processed_date"
                  value={lead.po_processed_date}
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead)}
                  style={{ width: '150px'}} 
                />
              </td>
              <td style={{ border: '1px solid black', padding: '10px' }}>
              <Form.Control
              as="Select"
              name="invoice_status"
              value={lead.invoice_status === null || lead.invoice_status === undefined ? 'No' : lead.invoice_status}
              style={{ width: '90px' }}
              onChange={(e) => handleInputChange(e, index)}
              disabled={isDisabled(lead)}
             >
              <option value="">Select</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
             </Form.Control>
            </td>

            

              
              <td style={{ border: '1px solid black', padding: '10px' }}>
                {(parseFloat(lead.base_po_received_amt) || 0).toFixed(0)}</td>

              <td style={{ border: '1px solid black', padding: '10px' }}>
                {(parseFloat(lead.addnl_payout_amt) || 0).toFixed(0)}</td>

              <td style={{ border: '1px solid black', padding: '10px' }}>
                 {(parseFloat(lead.contest_po_amt) || 0).toFixed(0)}</td>

                 {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <td style={{ border: '1px solid black', padding: '10px' }}>{lead.subvention_amt}</td>}  
                 {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <td style={{ border: '1px solid black', padding: '10px' }}>{lead.other_deduction}</td>}
                 {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <td style={{ border: '1px solid black', padding: '10px' }}>{
             (lead.base_po_received_amt +
             lead.addnl_payout_amt  +
               lead.contest_po_amt -
             lead.subvention_amt *
                100
             ).toFixed(2)} 
             </td>}

              <td style={{ border: '1px solid black', padding: '10px' }}>
                <Form.Control
                  type="text"
                  name="remarks1"
                  value={lead.remarks1}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                 
                />
              </td>
              <td style={{ border: '1px solid black', padding: '10px' }}>
                <Form.Control
                  type="text"
                  name="remarks2"
                  value={lead.remarks2}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                 
                />
              </td>
              <td style={{ border: '1px solid black', padding: '10px' }}>
                <Form.Control
                  type="text"
                  name="fpr"
                  value={lead.fpr}
                  onChange={(e) => handleInputChange(e, index)}
                  disabled={isDisabled(lead)}
                  style={{ width: '90px'}} 
                />
              </td>
              { selectedCategory !== "Credit Card" && <td style={{ border: '1px solid black', padding: '10px' }}>
  <div className="d-flex align-items-center">
    <Form.Control
      type="text"
      min="0"
      max="100"
      step="0.01"
      name="connector1_perc"
      value={lead.connector1_perc || 0}  
      onChange={(e) => handleInputChange(e, index)}
      // disabled={isDisabled(lead) || lead.source !== 'Internal'}
      disabled={isDisabled(lead)  || lead.source !== 'Digital' & (lead.source !== 'Internal')}
      style={{ width: '50px', textAlign: 'right' }}
    />
    <span>%</span>
  </div>
</td>}
{ selectedCategory !== "Credit Card" && <td style={{ border: '1px solid black', padding: '10px' }}>
  <div className="d-flex align-items-center">
    <Form.Control
      type="text"
      min="0"
      max="100"
      step="0.01"
      name="connector2_perc"
      value={lead.connector2_perc || 0}  
      onChange={(e) => handleInputChange(e, index)}
      // disabled={isDisabled(lead) || lead.source !== 'Internal'}
      disabled={isDisabled(lead)  || lead.source !== 'Digital' & (lead.source !== 'Internal')}
      style={{ width: '50px', textAlign: 'right' }}
    />
    <span>%</span>
  </div>
</td>}


              
              <td style={{ border: '1px solid black', padding: '10px' }}>
                <Form.Control
                  type="text"
                  name="tm"
                  value={lead.tm}
                  onChange={(e) => handleInputChange(e, index)}
                  // disabled={isDisabled(lead) || lead.source !== 'Internal'}
                  disabled={isDisabled(lead)  || lead.source !== 'Digital' & (lead.source !== 'Internal')}
                  style={{ width: '90px'}} 
                />
              </td>
            

              <td style={{ border: '1px solid black', padding: '10px' }}>
                <Form.Control
                  type="text"
                  name="bm"
                  value={lead.bm}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                  // disabled={isDisabled(lead) || lead.source !== 'Internal'}
                  disabled={isDisabled(lead)  || lead.source !== 'Digital' & (lead.source !== 'Internal')}
                />
              </td>
              <td style={{ border: '1px solid black', padding: '10px' }}>
                <Form.Control
                  type="text"
                  name="rsm"
                  value={lead.rsm}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                  // disabled={isDisabled(lead) || lead.source !== 'Internal'}
                  disabled={isDisabled(lead)  || lead.source !== 'Digital' & (lead.source !== 'Internal')}
                />
              </td>
              <td style={{ border: '1px solid black', padding: '10px' }}>
                <Form.Control
                  type="text"
                  name="zsm"
                  value={lead.zsm}
                  style={{ width: '90px'}} 
                  onChange={(e) => handleInputChange(e, index)}
                  // disabled={isDisabled(lead) || lead.source !== 'Internal'}
                  disabled={isDisabled(lead)  || lead.source !== 'Digital' & (lead.source !== 'Internal')}
                />
              </td >

              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <td style={{ border: '1px solid black', padding: '10px' }}>{lead.joining_fees}</td>}
              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <td style={{ border: '1px solid black', padding: '10px' }}>{lead.annual_fees}</td>}
              {selectedCategory !== "Unsecured" && selectedCategory !== "Secured" && <td style={{ border: '1px solid black', padding: '10px' }}>{lead.card_active_status}</td>}
              
            </tr>
          ))}
        </tbody>
      </table >
      </div>
    
      {renderPagination()} {/* Pagination for Loan Table */}
    {/* </>
  )} */}
</Container> 
    );
};
export default MultipleLeadMap;

import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import api from '../services/api';

const AddConnector = () => {
  const [name, setName] = useState('');
  const [pan, setPan] = useState('');
  const [location, setLocation] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic client-side validations
    if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(pan)) {
      setError('Invalid PAN format');
      return;
    }

    if (mobileNo && !/^\d{10}$/.test(mobileNo)) {
      setError('Mobile number must be 10 digits');
      return;
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      setError('Invalid email format');
      return;
    }

    setError('');

    try {
      const response = await api.post('/api/add-connector/', {
        name,
        pan,
        location,
        mobile_no: mobileNo,
        email,
      });
      setMessage(`Connector added successfully with code: ${response.data.code}`);
      setName('');
      setPan('');
      setLocation('');
      setMobileNo('');
      setEmail('');
      setError(''); // Clear any previous error
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setMessage("You don't have permission to add a connector.");
      } else if (error.response && error.response.data) {
        // Show specific error messages from the API response
        const errorMessages = Object.entries(error.response.data)
          .map(([field, messages]) => `${field}: ${messages.join(', ')}`)
          .join(' ');
        setError(errorMessages);
      } else {
        setError('Failed to add connector.');
        console.error(error);
      }
    }
  };

  return (
    <div className="add-connector-form">
      <h3>Add New Connector</h3>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Connector Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>PAN</Form.Label>
          <Form.Control
            type="text"
            value={pan}
            onChange={(e) => setPan(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Location</Form.Label>
          <Form.Control
            type="text"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Mobile Number</Form.Label>
          <Form.Control
            type="text"
            value={mobileNo}
            onChange={(e) => setMobileNo(e.target.value)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Add Connector
        </Button>
      </Form>
      {error && <Alert variant="danger">{error}</Alert>}
      {message && <Alert variant="success">{message}</Alert>}
    </div>
  );
};

export default AddConnector;

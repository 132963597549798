
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import { API_URL } from '../config';
import api from '../services/api';


const MappingModal = ({ lead, onMap, onClose, show, isEdit }) => {
    const [mappingData, setMappingData] = useState({
        lead_id: lead?.id || lead?.lead_id || '',
        source: lead?.source || '',
       // payout_sharing: lead?.payout_sharing || '',
        subvention_amt: lead?.subvention_amt || '',
        subvention_percentage: lead?.subvention_percentage || '',
        connector_1_tme: lead?.connector_1_tme || '',
        // connector_2: lead?.connector_2 || '',
        external_payout_percentage: lead?.external_payout_percentage || '',
        //external_payout_amt: lead?.external_payout_amt || '',
        status: lead?.status || '',
        remarks1: lead?.remarks1 || '',
        remarks2: lead?.remarks2 || '',
        remarks3: lead?.remarks3 || '',
        remarks4: lead?.remarks4 || '',
        remarks5: lead?.remarks5 || '',
        remarks6: lead?.remarks6 || '',
        remarks7: lead?.remarks7 || '',
    });
    // console.log("Data:",lead_id)

    const [connectors, setConnectors] = useState([]);
    const [error, setError] = useState('');
    const [connectorError, setConnectorError] = useState('');
    useEffect(() => {
        const fetchConnectors = async () => {
            try {
                const response = await api.get('/api/connectors/');
                setConnectors(response.data);
            } catch (error) {
                console.error('Error fetching connectors:', error);
            }
        };
    
        fetchConnectors();
    }, []);
    

    useEffect(() => {
        if (isEdit) {
            setMappingData({
                source: lead.source || '',
                //payout_sharing: lead.payout_sharing || '', // changes done by sumedh
                subvention_amt: lead.subvention_amt || '',
                subvention_percentage: lead.subvention_percentage || '',
                connector_1_tme: lead.connector_1_tme || '',
                connector_2_tl: lead.connector_2_tl || '',    
                external_payout_percentage: lead.external_payout_percentage || '', // changes done by sumedh
               // external_payout_amt: lead.external_payout_amt || '', // changes done by sumedh
                status: lead.status || '',
                remarks1: lead.remarks1 || '',
                remarks2: lead.remarks2 || '',
                remarks3: lead.remarks3 || '',
                remarks4: lead.remarks4 || '',
                remarks5: lead.remarks5 || '',
                remarks6: lead.remarks6 || '',
                remarks7: lead.remarks7 || '',
            });
        }
    }, [lead, isEdit]);


    // *******without calulating external payout percentage working fine**
    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        if (name === 'connector_1_tme' || name === 'connector_2') {
            const selectedConnector = connectors.find(connector => connector.id === parseInt(value));
            setMappingData(prevData => ({
                ...prevData,
                [name]: selectedConnector ? selectedConnector.name : ""
                // [name]: selectedConnector ? selectedConnector.id : ""
            }));
            setConnectorError('');
        } else {
            setMappingData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };
    
    console.log(connectors)

    const calculateExternalPayoutAmt = (percentage) => {
        if (!lead.net_loan || isNaN(percentage)) return '';
        return (lead.net_loan * (percentage / 100)).toFixed(2);
    };

    // ***trying to calcualte external percentage amount but something is going wrong , make sure handlemap in payout should have netloan set in selectedlead usestate**
    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;

    //     if (name === 'external_payout_percentage' || name === 'subvention_percentage') {
    //         // Validate to allow only numbers and decimal point
    //         if (!/^\d*\.?\d*$/.test(value)) return;

    //         const numValue = parseFloat(value);
    //         if (numValue < 0 || numValue > 100) return;

    //         if (name === 'external_payout_percentage') {
    //             setMappingData(prevData => ({
    //                 ...prevData,
    //                 [name]: value,
    //                 external_payout_amt: calculateExternalPayoutAmt(numValue)
    //             }));
    //         } else {
    //             setMappingData(prevData => ({
    //                 ...prevData,
    //                 [name]: value
    //             }));
    //         }
    //     } else if (name === 'external_payout_amt') {
    //         // Allow manual editing of the amount, but recalculate percentage
    //         if (!/^\d*\.?\d*$/.test(value)) return;
    //         const numValue = parseFloat(value);
    //         const newPercentage = lead.net_loan ? ((numValue / lead.net_loan) * 100).toFixed(2) : '';
    //         setMappingData(prevData => ({
    //             ...prevData,
    //             [name]: value,
    //             external_payout_percentage: newPercentage
    //         }));
    //     } else {
    //         // Handle other inputs as before
    //         setMappingData(prevData => ({
    //             ...prevData,
    //             [name]: value
    //         }));
    //     }

    //     // Clear connector error when either connector is changed
    //     if (name === 'connector_1' || name === 'connector_2') {
    //         const selectedConnector = connectors.find(connector => connector.id === parseInt(value));
    //            // Update the state with the connector's name
    //            setMappingData(prevData => ({
    //             ...prevData,
    //             [name]: selectedConnector ? selectedConnector.name : ""
    //         }));
    //         setConnectorError('');
    //     }
    // };
    const validateConnectors = () => {
        if (mappingData.connector_1_tme && mappingData.connector_2 && 
            mappingData.connector_1_tme=== mappingData.connector_2) {
            setConnectorError('Connector 1 and Connector 2 cannot be the same.');
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateConnectors()) {
            return;
        }

        // const requiredFields = [ 'source', 'payout_sharing', 'subvention_amt', 'subvention_percentage', 'connector_1', 'external_payout_percentage', 'external_payout_amt', 'status'];
        const requiredFields = [ 'source', 'connector_1_tme','status']; // changes done by sumedh
        const missingFields = requiredFields.filter(field => !mappingData[field]);

        if (missingFields.length > 0) {
            setError(`Missing required fields: ${missingFields.join(', ')}`);
            return;
        }

        setError('');
        onMap(mappingData);
    };

    return (
        <Modal show={show} onHide={onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit Mapped Lead' : 'Map Lead'}: {lead?.customer_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>
                        <Form.Group className="mb-3">
    <Form.Label><span style={{ color: 'red',fontSize: '1.2em' }}>*</span> SOURCE</Form.Label>
    <Form.Select
        name="source"
        value={mappingData.source}
        onChange={handleInputChange}
        required
    >
        <option value="">Select Source</option> 
        <option value="Internal">Internal</option>
        <option value="External">External</option>
    </Form.Select>
</Form.Group>
                        </Col>
                        {/* <Col md={6}> // changes done by sumedh
                            <Form.Group className="mb-3">
                                <Form.Label>PAYOUT SHARING</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="payout_sharing"
                                    value={mappingData.payout_sharing}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group> // changes done by sumedh
                        </Col> */}
                        {/* <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>SUBVENTION AMOUNT</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="subvention_amt"
                                    value={mappingData.subvention_amt}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>SUBVENTION PERCENTAGE</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="subvention_percentage"
                                    value={mappingData.subvention_percentage}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col> */}
                        <Col md={6}>
    <Form.Group className="mb-3">
        <Form.Label><span style={{ color: 'red',fontSize: '1.2em' }}>*</span> CONNECTOR 1</Form.Label>
        <Form.Control
            as="select"
            name="connector_1_tme"
            value={connectors.find(connector => connector.name === mappingData.connector_1_tme)?.id || ""}  // Use ID here
            onChange={handleInputChange}
            required
        >
            <option value="">Select Connector 1</option>
            {connectors.map(connector => (
                // <option key={connector.id} value={connector.id}>
                //     {connector.code} - {connector.name}
                // </option>
            <option key={connector.id} value={connector.id}>
                {connector.name}
            </option>
            ))}
        </Form.Control>
    </Form.Group>
</Col>


{/* <Col md={6}> */}
    {/* <Form.Group className="mb-3">
        <Form.Label>CONNECTOR 2</Form.Label>
        <Form.Control
            as="select"
            name="connector_2"
            value={connectors.find(connector => connector.name === mappingData.connector_2)?.id || ""}  // Use ID here
            onChange={handleInputChange}
        >
            <option value="">Select Connector 2</option>
            {connectors.map(connector => (
                <option key={connector.id} value={connector.id}>
                    {connector.code} - {connector.name}
                </option>
            ))}
        </Form.Control>
    </Form.Group>  */}
{/* </Col> */}                         

{/* chaged by govil above */}
                        {connectorError && (
                            <Col md={12}>
                                <Alert variant="danger">{connectorError}</Alert>
                            </Col>
                        )}
                        
                        
                         <Col md={6}> 
                            <Form.Group className="mb-3">
                                <Form.Label>EXTERNAL PAYOUT PERCENTAGE</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="external_payout_percentage"
                                    value={mappingData.external_payout_percentage}
                                    // value={3.00} // Set the value to 3
                                    // readOnly // Make the field read-only to prevent user changes
                         
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col> 



                        {/* <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>EXTERNAL PAYOUT AMOUNT</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="external_payout_amt"
                                    value={mappingData.external_payout_amt}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
  changes done by sumedh </Col> */} 
                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label><span style={{ color: 'red',fontSize: '1.2em' }}>*</span> STATUS</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="status"
                                    value={mappingData.status}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        {['remarks1', 'remarks2', 'remarks3', 'remarks4', 'remarks5', 'remarks6', 'remarks7'].map((field, index) => (
                            <Col md={6} key={field}>
                                <Form.Group className="mb-3">
                                    <Form.Label> {`REMARKS ${index + 1}`}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name={field}
                                        value={mappingData[field]}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Col>
                        ))}
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    {isEdit ? 'Update Mapping' : 'Submit Mapping'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MappingModal;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Card, Table, Alert,Pagination } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { API_URL } from '../config';
import api from '../services/api';


const DownloadExcel = () => {
    const [billingMonths, setBillingMonths] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [lenders, setLenders] = useState([]);
    const [selectedLender, setSelectedLender] = useState('');
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [payouts, setPayouts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Current page
    const [recordsPerPage, setRecordsPerPage] = useState(5); // Fixed records per page
    // const [files, setFiles] = useState([]);
    // const [selectedFile, setSelectedFile] = useState(null);
    // const [summary, setSummary] = useState(null);
    // const [rejectionReason, setRejectionReason] = useState('');

    useEffect(() => {
        // Fetch billing months, lenders, and products
        const fetchData = async () => {
            try {
                const billingMonthsResponse = await api.get('/api/billing-months/');
                setBillingMonths(billingMonthsResponse.data);
    
                const lendersResponse = await api.get('/api/lenders/');
                setLenders(lendersResponse.data);
    
                const productsResponse = await api.get('/api/products/');
                setProducts(productsResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, []);

    const handleSubmit = () => {
        setErrorMessage('');  // Reset error message
    
        // Send the selected filters as query parameters to the backend
        api.get('/api/payout-details/', {
            params: {
                billing_month: selectedMonth,
                lender: selectedLender,
                product: selectedProduct
            }
        })
        .then(response => {
            setPayouts(response.data);
            if (response.data.length === 0) {
                setErrorMessage("No data found for the selected filters.");
            }
        })
        .catch(error => {
            if (error.response?.data?.error) {
                setErrorMessage(error.response.data.error);
            } else {
                console.error('Error fetching payouts:', error);
            }
        });
    };
    

    // ******************without authentication ***********

    // const handleSubmit = () => {
    //     setErrorMessage('');  // Reset error message

    //     // Send the selected filters as query parameters to the backend
    //     axios.get(`${API_URL}api/payout-details/`, {
    //         params: {
    //             billing_month: selectedMonth,
    //             lender: selectedLender,
    //             product: selectedProduct
    //         }
    //     })
    //     .then(response => {
    //         setPayouts(response.data);
    //         if (response.data.length === 0) {
    //             setErrorMessage("No data found for the selected filters.");
    //         }
    //     })
    //     .catch(error => {
    //         if (error.response && error.response.data.error) {
    //             setErrorMessage(error.response.data.error);
    //         } else {
    //             console.error('Error fetching payouts:', error);
    //         }
    //     });
    // };


    const downloadExcel = () => {
        // Define columns to round off and the number of decimal places
        const columnsToRound = {
            addnl_payout_amt: 2,
            base_po_received_percentage: 0,
            net_of_tds_percentage: 2,
        };
    
        const columnsWithPercent = [
            'addnl_payout_amt',
            'net_of_tds_percentage'
        ];

        // Function to format acing_month as Mar-24
        const formatAcingMonth = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', { month: 'short', year: '2-digit' }).replace(',', '-');
      };
    
        // Filter out unwanted columns and round specific columns
        const filteredPayouts = payouts.map(payout => {
            const { id, file_upload, ...rest } = payout; // Remove 'id', 'file_upload' and any other columns you don't want
    
            const roundedRest = Object.keys(rest).reduce((acc, key) => {
                if (columnsToRound[key] !== undefined) {
                    // Round off specified columns
                    acc[key] = parseFloat(rest[key]).toFixed(columnsToRound[key]);
                } else if (columnsWithPercent.includes(key)) {
                    // Convert to percentage format

                    const value = parseFloat(rest[key]) * 100;
                 acc[key] = `${value.toFixed(2)}%`; // Multiply by 100 to convert to percentage
                }else if (key === 'acing_month') {
                    // Format acing_month as Mar-24
                    acc[key] = formatAcingMonth(rest[key]);
                 } else {
                    // Keep other columns as they are
                    acc[key] = rest[key];
                }
                return acc;
            }, {});
    
            return roundedRest;
        });
    
        const worksheet = XLSX.utils.json_to_sheet(filteredPayouts); // Use filtered and rounded data
    
        // Apply percentage formatting to specific columns
        const worksheetWithFormats = worksheet;
        const range = XLSX.utils.decode_range(worksheetWithFormats['!ref']);
    
        // Apply percentage formatting
        Object.keys(filteredPayouts[0] || {}).forEach((col, index) => {
            if (columnsWithPercent.includes(col)) {
                for (let row = range.s.r + 1; row <= range.e.r; row++) { // Start from row 1 to avoid header
                    const cellAddress = XLSX.utils.encode_cell({ r: row, c: index });
                    const cell = worksheetWithFormats[cellAddress];
                    if (cell && cell.v !== undefined) {
                        cell.z = '0.00%'; // Format as percentage with 2 decimal places
                    }
                }
            }
        });
    
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheetWithFormats, "Payouts");
        XLSX.writeFile(workbook, "PayoutFile.xlsx");
    };
    
    // Calculate pagination
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = payouts.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(payouts.length / recordsPerPage);
    
    // const prePage = () => {
    //     if (currentPage > 1) {
    //         setCurrentPage(currentPage - 1);
    //     }
    // };

    // const nextPage = () => {
    //     if (currentPage < totalPages) {
    //         setCurrentPage(currentPage + 1);
    //     }
    // };
    // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Handle records per page change
  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to page 1 whenever the records per page changes
  };

   // React Bootstrap Pagination component
   const renderPagination = () => {
    let items = [];

    items.push(
        <Pagination.Item key="first" onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>
            First
        </Pagination.Item>
    );
    items.push(
        <Pagination.Item key="prev" onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
            </Pagination.Item>
    );

    for (let number = 1; number <= totalPages; number++) {
        items.push(
            <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
                {number}
            </Pagination.Item>
        );
    }

    items.push(
        <Pagination.Item key="next" onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
        </Pagination.Item>
    );
    
    items.push(
        <Pagination.Item key="last" onClick={handleLastPage} disabled={currentPage === totalPages}>
            Last
        </Pagination.Item>
    );

    return <Pagination>{items}</Pagination>;
};

// Previous Page
const handlePreviousPage = () => {
    if (currentPage > 1) {
        setCurrentPage(currentPage - 1);
    }
};

// Next Page
const handleNextPage = () => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
    }
};

// Last Page
const handleLastPage = () => {
    setCurrentPage(totalPages);
};
    
    return (
        <Card>
            <Card.Body>
                <Card.Title>Payout File</Card.Title>
                <Form>
                    <Form.Group className='mt-4'>
                        <Form.Control as="select" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                            <option value="">Select Billing Month</option>
                            {billingMonths.map(month => (
                                <option key={month.id} value={month.id}>{`${month.month} ${month.year}`}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Control as="select" value={selectedLender} onChange={(e) => setSelectedLender(e.target.value)}>
                            <option value="">Select Lender</option>
                            {lenders.map(lender => (
                                <option key={lender.id} value={lender.id}>{lender.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Control as="select" value={selectedProduct} onChange={(e) => setSelectedProduct(e.target.value)}>
                            <option value="">Select Product</option>
                            {products.map(product => (
                                <option key={product.id} value={product.id}>{product.product_name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Button className='mt-4' variant="primary" onClick={handleSubmit}>Submit</Button>
                </Form>

                {errorMessage && (
                    <Alert variant="danger" className="mt-4">
                        {errorMessage}
                    </Alert>
                )}
                {/* <button onClick={downloadExcel}>Download as Excel</button> */}
                <Button 
                  variant="success" 
                  className="mt-4" 
                  onClick={downloadExcel}
>
                    <i className="fas fa-file-excel"></i> Download as Excel
                 </Button>    

                {payouts.length > 0 && (
                    
                    
                //    <table border="1" cellPadding="10" cellSpacing="0">
                
                        //    <table border="1" cellPadding="10" cellSpacing="0">
                        <table striped bordered hover ={true}>
                            <thead>
                                <tr>
                                    {/* <th style={{ border: '1px solid black', padding: '10px' }}>ID</th> */}
                                    <th style={{ border: '1px solid black', padding: '10px' }}>sr_no</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>State</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>fpr</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>hub</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>Branch_location</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>Bank</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>product_category</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>product_type</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>customer_name</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>gross_loan</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>net_loan</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>tenure</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>acing_month</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>market_type</th>
                                    <th style={{ border: '1px solid black', padding: '40px' }}>loan_no</th>
                                    <th style={{ border: '1px solid black', padding: '20px' }}>disb_date</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>source</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>payout_sharing</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>subvention_amt</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>subvention_percentage</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>connector_1</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>connector_2</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>external_payout_percentage</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>external_payout_amt</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>status</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>pay_date</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>total_po_received_amt</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>total_po_received_percentage</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>base_po_received_amt</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>base_po_received_percentage</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>addnl_payout_amt</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>addnl_payout_percentage</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>contest_po_amt</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>contest_po_percentage</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>total_gross_po_percentage</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>net_of_tds_percentage</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>pf_collected</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>pf_deduction</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>insurance_collected</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>insurance_deduction</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>remarks1</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>remarks2</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>remarks3</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>remarks4</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>remarks5</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>remarks6</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>remarks7</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>po_processed_by</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>date_of_processing</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>tl</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>tm</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>bm</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>rsm</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>zsm</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>is_mapped</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>mapped_by</th>
                                    <th style={{ border: '1px solid black', padding: '10px' }}>mapped_at</th>
                                    {/* <th style={{ border: '1px solid black', padding: '10px' }}>file_upload_id</th> */}


                                </tr>
                            </thead>
                            <tbody> 
                                {currentRecords. map((payout) => (
                                    <tr key={payout.id}>
                                        {/* <td style={{ border: '1px solid black', padding: '10px' }}>{payout.id}</td> */}
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.sr_no}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.state}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.fpr}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.hub}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.Branch_location}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.bank}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.product_category}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.product_type}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.customer_name}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.gross_loan}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.net_loan}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.tenure}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{new Date(payout.acing_month).toLocaleString('en-US', { month: 'short', year: '2-digit' }).replace(',', '-')}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.market_type}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.loan_no}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.disb_date}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.source}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.payout_sharing}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.subvention_amt}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.subvention_percentage}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.connector_1}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.connector_2}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.external_payout_percentage}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.external_payout_amt}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.status}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.pay_date}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.total_po_received_amt}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.total_po_received_percentage}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.base_po_received_amt}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{Math.round(payout.base_po_received_percentage)}</td>
                                        {/* <td style={{ border: '1px solid black', padding: '10px' }}>{parseFloat(payout.addnl_payout_amt).toFixed(2)}%</td> */}
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{(parseFloat(payout.addnl_payout_amt) * 100).toFixed(2)}%</td>


                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.addnl_payout_percentage}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.contest_po_amt}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.contest_po_percentage}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.total_gross_po_percentage}</td>
                                        {/* <td style={{ border: '1px solid black', padding: '10px' }}>{parseFloat(payout.net_of_tds_percentage).toFixed(2)}%</td> */}
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{(parseFloat(payout.addnl_payout_amt) * 100).toFixed(2)}%</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.pf_collected}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.pf_deduction}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.insurance_collected}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.insurance_deduction}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.remarks1}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.remarks2}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.remarks3}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.remarks4}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.remarks5}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.remarks6}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.remarks7}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.po_processed_by}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.date_of_processing}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.tl}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.tm}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.bm}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.rsm}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.zsm}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.is_mapped}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.mapped_by}</td>
                                        <td style={{ border: '1px solid black', padding: '10px' }}>{payout.mapped_at}</td>
                                        {/* <td style={{ border: '1px solid black', padding: '10px' }}>{payout.file_upload_id}</td> */}

                                    </tr>
                                ))}
                            </tbody>
                        </table>       
                )}
                 {/* Records per page dropdown */}
                 <div className="d-flex align-items-center mt-4">
                 <label htmlFor="recordsPerPage" className="me-2">Records per page: </label>
                 <select
                 id="recordsPerPage"
                 className="form-select w-auto blue-select"
                 value={recordsPerPage}
                 onChange={handleRecordsPerPageChange}
                 >
                 <option value={5}>5</option>
                 <option value={10}>10</option>
                 <option value={20}>20</option>
                 <option value={25}>25</option>
                 <option value={50}>50</option>
                </select>
            </div>

                {/* Pagination controls */}
                {payouts.length > 0 && (
                    <div style={{ marginTop: '20px' }}>
                        {renderPagination()}
                    </div>
                )}
      
            </Card.Body>
        </Card>
    );
};
export default DownloadExcel;